import {createFileRoute, useRouter, useSearch} from "@tanstack/react-router";
import {useRoomItems} from "@tarimli-mono/api/src/shared/items-data.ts";
import {useCustomer} from "../../../../../services/auth.tsx";
import {CategoryItems} from "../../../../../components/TopCategory.tsx";
import {IconRoom} from "../../../../../components/icons/IconRoom.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/items/rooms/room')({
  component: RoomItemsScreen,
});

const labels = {
  rooms: "חדרים",
}

export function RoomItemsScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));

  const roomTitle = useSearch({
    from: '/_auth/order/$orderId/_order/items/rooms/room',
    select: (search: { title: string }) => search.title,
  })
  const router = useRouter()
  const roomItems = useRoomItems(roomTitle);
  return (
    <>
      <CategoryItems
        orderId={order.id}
        title={roomTitle}
        topTitle={labels['rooms']}
        icon={<IconRoom sx={{ fontSize: 30, color: '#AD7AFF' }} />}
        itemsData={roomItems}
        selectedItems={order.content.sortedItems}
        onClickBack={() => {
          router.history.back();
        }}
      />
    </>
  );
}
