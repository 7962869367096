import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Face6Icon from '@mui/icons-material/Face6';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {createFileRoute, useRouter} from '@tanstack/react-router'
import Box from "@mui/material/Box";
import {PartnerRating} from "../../components/partner-rating.tsx";
import {useCustomer} from "../../services/auth.tsx";
import {TalkToPartner} from "../../components/talk-to-partner.tsx";

export const Route = createFileRoute('/_auth/offer/$offerId')({
  component: OfferScreen
})

const labels = {
  'offer': 'הצעת מחיר',
  'cancel': 'ביטול',
  'goToPayment': 'אישור והמשך לתיאום',
  'nis': '₪',
  'amount': 'סכום',
  back: 'חזרה',
}

export function OfferScreen() {
  const customer = useCustomer();
  const router = useRouter();
  const routeParams = Route.useParams() as { offerId: string };
  const offerId = Number(routeParams.offerId);
  const order = customer.orders.find((order) => order.submittedOffers.find((offer) => offer.id === offerId));
  const offer = order.acceptedOffer?.id === offerId ? order.acceptedOffer : order.submittedOffers.find((offer) => offer.id === offerId);
  const onClickClose = () => {
    router.history.back();
  }
  const onClickNext = async () => {
    router.navigate({
      to: '/offer/$offerId/checkout',
      params: {
        /* @ts-expect-error */
        offerId: routeParams.offerId,
      }
    });
  }
  return (
    <Stack gap={1} sx={{
      padding: 2,
    }}>
      <Stack direction={"row"}
             gap={2}
             alignItems={"center"}
             sx={{
               padding: 1,
               backgroundColor: '#F5EDFF',
               borderRadius: 1,
             }}
      >
        <IconButton onClick={onClickClose} sx={{
          backgroundColor: "#EADCFF",
        }}>
          <ChevronRightIcon/>
        </IconButton>
        <Typography fontSize={18}>{labels['offer']}</Typography>
      </Stack>
      <Stack gap={2} sx={{
        padding: 2,
        backgroundColor: 'white',
        borderRadius: 1,
        clipPath: 'padding-box',
      }}>
        <Stack direction={'row'} gap={2} sx={{
          // alignItems: 'center',
        }}>
          {offer.partner.profilePhotos?.[0] ? (
            <Box sx={{
              width: 100,
              height: 100,
              borderRadius: 1,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <img src={offer.partner.profilePhotos[0]} alt={offer.partner.businessName} style={{maxWidth: '100%', maxHeight: '100%'}}/>
            </Box>
          ) : <Face6Icon sx={{fontSize: 40, opacity: 0.3}}/>}
          <Stack gap={2} sx={{flexGrow: 1}}>
            <Typography variant={'h5'}>{offer.partner.businessName}</Typography>
            <PartnerRating partner={offer.partner}/>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} gap={2} sx={{
        padding: 2,
        backgroundColor: 'white',
        borderRadius: 1,
        alignItems: 'center',
      }}>
        <Typography>{labels['amount']}</Typography>
        <Typography sx={{fontSize: 20, fontWeight: 600}}>{offer.amount}{labels['nis']}</Typography>
      </Stack>
      <Stack direction={"row"} gap={2}
             sx={{
               // position: 'fixed',
               // bottom: 0,
               // left: 0,
               // right: 0,
               // height: '70px',
               borderRadius: 1,
               padding: 2,
               // zIndex: 200,
               backgroundColor: '#F5EDFF',
             }}
      >
        <Button onClick={onClickClose} variant={'outlined'}>
          {labels['back']}
        </Button>
        <TalkToPartner partnerPhone={offer.partner.phone} customerName={customer.firstName} orderId={String(order.id)}/>
        <Button
          onClick={onClickNext}
          sx={{
            flexGrow: 1,
          }}
          variant={'contained'}
        >
          {labels['goToPayment']}
        </Button>
        
      </Stack>
    </Stack>
  );
}
