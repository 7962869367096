import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {CustomerServiceButton} from "./stage-elements.tsx";

const labels = {
  terms: 'תנאי שימוש',
  privacy: 'מדיניות פרטיות',
}
export function Footer() {
  return <Stack sx={{m: 2}} gap={1}>
    <Typography sx={{textAlign: 'center', fontSize: 12}}>
      <Link
        href="https://www.tarimli.com/%D7%AA%D7%A8%D7%99%D7%9D%D7%9C%D7%99-%D7%AA%D7%A0%D7%90%D7%99-%D7%A9%D7%99%D7%9E%D7%95%D7%A9-%D7%95%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA-%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA">{labels.terms}</Link>
      &nbsp;&nbsp;|&nbsp;&nbsp; 
      <Link
        href="https://www.tarimli.com/%D7%AA%D7%A8%D7%99%D7%9D%D7%9C%D7%99-%D7%AA%D7%A0%D7%90%D7%99-%D7%A9%D7%99%D7%9E%D7%95%D7%A9-%D7%95%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA-%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA">{labels.privacy}</Link>
      <br/>
      א.ב.א תריםלי בע"מ | 2024<br/>
      A.B.A TarimLi LTD.<br/>
      YAD HAROTZIM 10, 6770003 TEL-AVIV<br/>
      972-515-207969+<br/>
    </Typography>
    <CustomerServiceButton variant={'small'} sx={{alignSelf: 'center'}}/>
  </Stack>;
}