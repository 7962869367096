import {useMutation, useQueryClient} from '@tanstack/react-query';
import {authPost} from "./api-call.ts";
import {ApiChargeCardResponse, ApiChargeTokenResponse, ApiCheckoutResponse} from "@tarimli-mono/api/src/shared/api-types.ts";

export const useStartCheckoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
                   offerId,
                   firstName,
                   lastName,
                   phone,
                   email,
                   paymentsNum,
                   saveCard,
                 }: {
      offerId: string,
      firstName: string,
      lastName: string,
      phone: string,
      email: string,
      paymentsNum: number,
      saveCard: boolean,
    }) => {
      return authPost<ApiCheckoutResponse>('/checkout', {
        offerId,
        firstName,
        lastName,
        phone,
        paymentsNum,
        email,
        saveCard,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
};

export const useMutationChargeToken = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
                   contractId,
                   firstName,
                   lastName,
                   phone,
                   email,
                   paymentsNum,
                 }: {
      contractId: DbId,
      firstName: string,
      lastName: string,
      phone: string,
      email: string,
      paymentsNum: number,
    }) => {
      return authPost<ApiChargeTokenResponse>('/charge/token', {
        contractId,
        firstName,
        lastName,
        phone,
        email,
        paymentsNum,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
};

export const useMutationChargeCard = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
                   contractId,
                   type,
                   firstName,
                   lastName,
                   phone,
                   email,
                   paymentsNum,
                 }: {
      contractId: DbId,
      type: 'cardCharge' | 'bitCharge',
      firstName: string,
      lastName: string,
      phone: string,
      email: string,
      paymentsNum: number,
    }) => {
      return authPost<ApiChargeCardResponse>('/charge/card', {
        contractId,
        type,
        firstName,
        lastName,
        phone,
        email,
        paymentsNum,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}