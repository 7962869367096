import Box from "@mui/material/Box";

export const TermsContent = () => <div style={{direction: 'rtl'}}>
  <p>
    <strong>ברוכים הבאים לאתר TARIMLI:</strong>
  </p>
  <p>
    יש לקרוא בקפידה את מדיניות הגנת הפרטיות המפורטת של TARIMLI (להלן – מדיניות הפרטיות). הגלישה והשימוש באתר WWW.TARIMLI.COM ו/או ב- APP.TARIMLI.COM (להלן – "האתר") כפופה למדיניות הפרטיות. במידה ולא הבנת ו/או לא הסכמת לאמור במדיניות הפרטיות במלואה וללא סייג, יש להפסיק מיד כל שימוש ו/או חיבור ו/או גלישה באתר ו/או ניתן לפנות לכתובת הדוא"ל INFO@TARIMLI.COM   ו/או באמצעות האתר.
  </p>
  <h4>כללי</h4>
  <ol>
    <li>
      TARIMLI (להלן – החברה) מכבדת את פרטיותו של כל אדם משתמש או מבקר באתר (להלן – "המשתמש"). מדיניות הפרטיות מתייחסת לסוג המידע שהחברה תהא רשאית לאסוף, למסור ולחשוף, כמו גם את הדרך ומטרת עיבוד מידע זה.
    </li>
    <li>
      מדיניות הפרטיות מנוסחת לשם הנוחות בלשון זכר, אך מתייחסת בהתאמה ובאופן שווה גם לנשים כמובן.
    </li>
    <li>
      החברה מתחייבת לאסוף מידע במינימום הנדרש ובאופן המצומצם ביותר על מנת לאפשר מתן שירות ותפעול טובים ותקינים למשתמש וכפי שיפורט בהמשך.
    </li>
    <li>
      מדיניות הפרטיות תנחה בנוסף את המשתמש לזכויותיו וכיצד לשלוט בהיקף ובמידה שברצונו שפרטיו האישיים לא ייאספו, ייחשפו ויימסרו לצד ג' בעת השימוש או הביקור באתר.
    </li>
    <li>
      מדיניות הפרטיות עשויה להשתנות ולהתעדכן מעת לעת, וזאת בהתאם לשיקול דעתה הבלעדי של החברה. שינויים ועדכונים כאמור, יחולו ויחייבו את המשתמש בכל דבר ועניין.
    </li>
  </ol>
  <h4>ניהול המידע ואבטחת מידע</h4>
  <ol start={6}>
    <li>
      על מנת לאפשר חלק מהשירותים ו/או כולם, על המשתמש לבצע הרשמה. ההרשמה מתבצעת לאזור אישי המוגן בסיסמה אישית ובאמצעות ובהתאם לדרישות אבטחת המידע בדין. במסגרת ההרשמה יידרש המשתמש למסור מידע כגון – שם מלא, כתובת, דרכי ההתקשרות (לרבות כתובת דוא"ל) (להלן – "המידע אישי"), והכל על מנת לקבל את השירותים שמציע האתר וקבלת מידע רלוונטי הכרחי.
    </li>
    <li>
      החברה רשאית לנהל מאגר מידע ולעבד מידע אישי ומידע אחר שימסרו במסגרת השימוש באתר ו/או שנמסרו מרצונו החופשי של המשתמש ועל ידו.
    </li>
    <li>
      רכישה/תשלום באתר מתבצעת באמצעות ועל גבי התשתית של חברת "משולם פתרונות תשלום". בטרם ביצוע תשלום, יש לוודא כי בשורת הכתובת (URL) מופיעות בתחילת הכתובת האותיות – HTTPS.
    </li>
    <li>
      בסיום הפעילות באתר על  המשתמש לוודא כי הוא מתנתק מהאזור האישי.
    </li>
    <li>
      האתר מאובטח ומצפין תעבורה עם תעודות SSL בתוקף.
    </li>
    <li>
      מומלץ שלא לבצע רכישה/תשלום כאשר החיבור לאתר מתבצע על גבי רשת ציבורית.
    </li>
    <li>
      בכל שאלה ו/או דיווח על התנהלות חריגה, ניתן לפנות לכתובת הדוא"ל INFO@TARIMLI.COM.
    </li>
  </ol>
  <h4>העברת מידע לצד ג'</h4>
  <ol start={13}>
    <li>
      על מנת לאפשר תפעול תקין ומתן שירותים במסגרת ההיצע שהחברה מציעה, החברה עשויה  להעביר מידע אישי לחברות ו/או באמצעות חברות הקשורות אליה ו/או באמצעות כל גורם אחר רלוונטי המשתף איתה פעולה עסקית (להלן – "צד ג'"),  זאת כאמור, בתנאי שהדבר יהיה הכרחי למטרות המתוארות לעיל.
    </li>
    <li>
      למען הסר ספק, מטרות תפעוליות ומתן השירותים כוללים בין היתר – יצירת קשר עם המשתמש, דיוור ישיר, סליקת תשלום, ניהול חשבונות משתמשים, ולמטרות סטטיסטיות.
    </li>
    <li>
      החברה עשויה להעביר מידע לפי דרישה חוקית או צו שיפוטי, לרבות לרשויות המוסמכות בדין לכך וללא דרישה ו/או הסכמה מראש של המשתמש. במקרה כזה, לחברה לא תהיה חבות כלשהי כלפי המשתמש.
    </li>
    <li>
      האתר יכול להכיל קישורים לאתרי צד ג' שאינם בשליטת החברה ו/או הכפופים להוראותיה בקשר למדיניות הפרטיות הזו. על אף שהחברה עושה מאמץ סביר לוודא כי גם אתרים אלו כפופים למדיניות פרטיות ואבטחת מידע סבירים, החברה אינה אחראית למדיניות הפרטיות, מדיניות השימוש, ואבטחת המידע הנהוגים בפועל באותם אתרים.

    </li>
    <li>
      אתרי צד ג' כאמור הם Google (Ads, Analytics, Cloud Platform), WIX, Meta (Ads, Messaging).
    </li>
  </ol>
  <h4>
    זכויות המשתמש
  </h4>
  <ol start={18}>
    <li>
      ככל ומשתמש אינו מעוניין שמידע אישי אודותיו ייאסף, יימסר ו/או ייחשף באופן כלשהו כמתואר לעיל, עליו לחדול משימוש באתר, להימנע מהעברת מידע אישי לחברה, ולהסיר עצמו מכל דיוור ישיר ו/או קבלת שירות מאת החברה.

    </li>
    <li>
      כל משתמש רשאי לממש זכויותיו שבדין, לרבות פניה לבקשת חדילה והסרתו כאמור, באמצעות כתובת הדוא"ל:INFO@TARIMLI.COM  או באתר האינטרנט WWW.TARIMLI.COM.

    </li>
    <li>
      החברה עושה מאמצים ופועלת להגנת המידע והפרטיות של כל משתמש. החברה עומדת לרשות כל משתמש בכל עת ותקדם בעדיפות גבוהה את הטיפול בכל פניה ודיווח הקשורים במדיניות הפרטיות, ובכלל.

    </li>
  </ol>
  <h4>איסוף אוטומטי של נתוני Cookies ומידע אחר</h4>
  <ol start={21}>
    <li>
      במקרים מסוימים, ייתכן איסוף אוטומטי של סוג מידע טכני מסוים, לרבות מידע שלא כרוך ברישום המשתמש, שאינו מהווה מידע מזהה או מידע אישי, בעת שהמשתמש גולש או מחובר לאתר (להלן – "מידע אחר").

    </li>
    <li>
      דוגמאות למידע אחר הוא מידע מסוג "מטא-דאטה" הכולל בין היתר את סוג הדפדפן ו/או את סוג מערכת הפעלה של המחשב/מכשיר נייד ("סמארטפון") ממנו המשתמש גולש באתר, זמני חיבור לאתר, וכן שם הדומיין של אתר האינטרנט ממנו המשתמש מקושר לאתר.

    </li>
    <li>
      Cookies (להלן – "עוגיות") הם נתונים המוטמעים אוטומטית באמצעות קבצים הנשמרים בדפדפן בו גולש המשתמש ובזיכרון המחשב במהלך הגלישה והחיבור לאתר. מטרות השימוש בעוגיות הן לצורך התפעול השוטף והתקין של האתר, לרבות התאמת העדפות אישיות של המשתמש ,וכן על מנת לאפשר קבלת שירותים שונים, נתונים סטטיסטיים ואבטחת מידע.

    </li>
    <li>
      ברוב הדפדפנים ניתן להגדיר מראש הגדרות שונות לעניין היקפי השמירה ומחיקה של עוגיות. כמו כן, ניתן למחוק בדיעבד. מומלץ ללמוד ולהכיר את השימושים והאפשרויות בנושא בחיפוש חופשי ברשת והן בעמודי העזרה בדפדפנים.

    </li>

  </ol>
  <h4>ברירת דין ומקום שיפוט
  </h4>
  <ol start={25}>
    <li>
      מדיניות הפרטיות כפופה אך ורק לדיני מדינת ישראל. מקום השיפוט הבלעדי לכל עניין הנוגע למדיניות הגנת הפרטיות באתר הינו בבית המשפט בתל אביב.

    </li>
  </ol>
</div>