import pdfIcon from "/pdf-icon.png";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {AddressBox, DateBox, OrderBox} from "./orders-elements.tsx";
import {ClientCustomerOwnOrder} from "../../model/client-order.ts";
import {CustomerServiceButton} from "../stage-elements.tsx";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import midragLogo from "*.png";

const labels = {
  waitingForOffers: 'אנו אוספים הצעות מחיר בשבילך 😎',
}

export function AwaitingOffersScreen({order}: {order: ClientCustomerOwnOrder}) {
  return (
    <OrderBox title={labels['waitingForOffers']}>
      <Stack padding={2} gap={2}>
        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
          <DateBox order={order}/>
        </Stack>
        <Divider sx={{
          margin: 2,
        }}/>
        <Stack sx={{alignItems: 'center'}}>
          <AddressBox order={order}/>
        </Stack>
        <Divider sx={{
          margin: 2,
        }}/>
        <Stack sx={{alignItems: 'center'}}>
          {/*<CustomerServiceButton variant={'large'}/>*/}
          <Button>
          <Stack direction={'row'} gap={2} alignItems={'center'} sx={{
            p: 1,
          }}>
            <img src={pdfIcon} style={{
              width: 30,
            }}/>
            <Link sx={{color: '#ffffff', fontSize: 20}} href={order.content.pdfFile}>פרטי ההזמנה</Link>

          </Stack>
          </Button>
        </Stack>
      </Stack>
    </OrderBox>
  );
}