import Divider from '@mui/material/Divider';
import Stack from "@mui/material/Stack";
import {createFileRoute, notFound, redirect, useNavigate} from "@tanstack/react-router";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useCustomer} from "../../../../services/auth.tsx";
import {AddressBox, DateBox, OrderBox} from "../../../../components/orders/orders-elements.tsx";
import {TalkToPartner} from "../../../../components/talk-to-partner.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/closed')({
  component: ClosedOrderScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'closed') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
});

const labels = {
  goodLuck: 'בהצלחה במעבר',
  goToPayment: 'מעבר לתשלום',
  scheduleOrder: 'תאם את ההובלה',
}

export function ClosedOrderScreen() {
  const navigate = useNavigate();
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const orderId = Number(routeParams.orderId);
  const order = customer.findOrder(orderId);

  const onClickPay = async () => {
    await navigate({
      to: '/order/$orderId/pay',
      params: {
        /* @ts-expect-error False alarm */
        orderId: String(order.id),
      },
    });
  }
  return <OrderBox title={labels['goodLuck']}>
    <Stack padding={2} gap={4}>
      <Stack>
        <Box sx={{alignSelf: 'center'}}>
          <DateBox order={order}/>
        </Box>
        <Divider sx={{
          margin: 2,
        }}/>
        <Box sx={{alignSelf: 'center'}}>
          <AddressBox order={order}/>
        </Box>
      </Stack>
      <Box sx={{alignSelf: 'center'}}>
        <TalkToPartner label={labels.scheduleOrder} partnerPhone={order.acceptedOffer.partner.phone} customerName={customer.firstName} orderId={String(order.id)}/>
      </Box>
      <Button variant={'outlined'} sx={{alignSelf: 'center'}} onClick={onClickPay}>{labels.goToPayment}</Button>
    </Stack>
  </OrderBox>;
}