import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {createFileRoute, Link, useRouter} from '@tanstack/react-router'
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import numbro from 'numbro';
import React from "react";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import {OrderNumber} from "../../../components/order-number.tsx";
import {usePartner} from "../../../services/auth.tsx";
import {ClientPartnerOwnOffer} from "../../../model/client-offer.ts";
import {ProfilePhoto} from "../../../components/partner-profile.tsx";

export const Route = createFileRoute('/_auth/partner/orders')({
  component: PartnerOrdersScreen,
})

const labels = {
  newOrders1: 'הזמנות חדשות',
  newOrders2: 'ממתינות להצעות מחיר',

  acceptedOffers1: '',
  acceptedOffers2: 'הובלות סגורות ומאושרות - לביצוע',
  submittedOffers1: 'הצעות שהגשתי',
  submittedOffers2: 'ממתינות לאישור לקוח',
  finishedContracts1: 'הובלות שהושלמו',
  finishedContracts2: 'הובלות שהושלמו',
  ordersIRejected1: 'הזמנות שדחיתי',
  ordersIMissed: 'הזמנות שפספסתי',
  ordersIMissed2: 'הזמנות שפספסתי',

  pastOrders: 'ארכיון הזמנות | 20 אחרונות',

  noNewOrders: 'אין הזמנות חדשות',
  onDate: 'בתאריך',
  origin: 'מוצא',
  destination: 'יעד',
  submittedAmount: 'סכום',
  nis: '₪',
  myProfile: 'הפרופיל שלי',
}

function PartnerOrdersScreen() {
  const partner = usePartner()

  const router = useRouter();

  const onClickInviteOffer = async (offer: ClientPartnerOwnOffer) => {
    await router.navigate({
      to: `/partner/order/$orderId`,
      params: {
        /* @ts-expect-error False alarm */
        orderId: String(offer.order.id),
      }
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickSubmittedOffer = async (offer: ClientPartnerOwnOffer) => {
    await router.navigate({
      to: `/partner/order/$orderId`,
      params: {
        /* @ts-expect-error False alarm */
        orderId: String(offer.orderId),
      }
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickAcceptedOffer = async (offer: ClientPartnerOwnOffer) => {
    await router.navigate({
      to: `/partner/order/$orderId`,
      params: {
        /* @ts-expect-error False alarm */
        orderId: String(offer.order.id),
      }
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickExpiredOffer = async (offer: ClientPartnerOwnOffer) => {
    await router.navigate({
      to: `/partner/order/$orderId`,
      params: {
        /* @ts-expect-error False alarm */
        orderId: String(offer.order.id),
      }
    });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const section = (title1: string, title2: string, children, sx: any = {}, sx2: any = {}) => {
    return (
      <Stack gap={0} sx={{
        borderRadius: 1,
        backgroundColor: "white",
        clipPath: 'padding-box',
        ...(sx2 || {}),
      }}>
        <Stack direction={"row"} gap={2} sx={{
          padding: 2,
          alignItems: "center",
          backgroundColor: "#F3EBFF",
          ...(sx || {}),
        }}>
          <Stack sx={{
            flexGrow: 1
          }}>
            <Typography sx={{
              fontWeight: 600,
              fontSize: 16,
            }}>{title1}</Typography>
            <Typography sx={{
              fontWeight: 900,
              fontSize: 16,
            }}>{title2}</Typography>
          </Stack>
        </Stack>
        <Stack gap={2} sx={{padding: 2}}>
          {children}
        </Stack>
      </Stack>
    );
  }

  const activeContracts = () => section(labels['acceptedOffers1'], labels['acceptedOffers2'], partner.activeContracts.map(contract => {
    const order = partner.findOrderForContractId(contract.id);
    const offer = partner.findOfferForContractId(contract.id);
    return (
      <Stack
        onClick={() => onClickAcceptedOffer(offer)}
        gap={1}
        direction={'row'}
        sx={{
          cursor: 'pointer',
          border: "1px solid #F3EBFF",
          borderRadius: 1,
          clipPath: 'border-box'
        }} key={contract.id}>
        <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
          <OrderNumber orderId={order.id} sx={{position: 'absolute', top: 5, right: 0}}/>
          <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['onDate']}</Typography>
          <Typography sx={{}}>{order?.content.movingDateFormatted}</Typography>
          <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['origin']}</Typography>
          <Typography sx={{}}>{order.content.strDepartAddress}</Typography>
          <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['destination']}</Typography>
          <Typography sx={{}}>{order.content.strArriveAddress}</Typography>
        </Box>
        <Box sx={{
          padding: 1,
          backgroundColor: "#F3EBFF",
          display: 'flex',
          alignItems: 'center',
        }}>
          <ChevronLeftIcon sx={{color: "#6F4DFF"}}/>
        </Box>
      </Stack>
    );
  }))

  const inviteOffersSection = () => section(labels['newOrders1'], labels['newOrders2'], partner.inviteOffers.map(offer => {
    return (
      <Stack
        onClick={() => onClickInviteOffer(offer)}
        gap={1}
        direction={'row'}
        sx={{
          cursor: 'pointer',
          border: "1px solid #F3EBFF",
          borderRadius: 1,
          clipPath: 'border-box'
        }} key={offer.id}>

        <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
          <OrderNumber orderId={offer.order.id} sx={{position: 'absolute', top: 5, right: 0}}/>

          <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['onDate']}</Typography>
          <Typography sx={{}}>{offer.order.content.movingDateFormatted}</Typography>
          <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['origin']}</Typography>
          <Typography sx={{}}>{offer.order.content.strDepartAddress}</Typography>
          <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['destination']}</Typography>
          <Typography sx={{}}>{offer.order.content.strArriveAddress}</Typography>
        </Box>
        <Box sx={{
          padding: 1,
          backgroundColor: "#F3EBFF",
          display: 'flex',
          alignItems: 'center',
        }}>
          <ChevronLeftIcon sx={{color: "#6F4DFF"}}/>
        </Box>
      </Stack>
    );
  }));

  const submittedOffersSection = () => section(labels['submittedOffers1'], labels['submittedOffers2'], partner.submittedOffers.map(offer => {
    const formattedAmount = offer.amount ? numbro(offer.amount).formatCurrency({
      thousandSeparated: true,
      mantissa: 0,
      currencySymbol: '₪',
    }) : '-';
    return (
      <Stack gap={1} key={offer.id}>
        <Stack
          onClick={() => onClickSubmittedOffer(offer)}
          gap={1}
          direction={'row'}
          sx={{
            cursor: 'pointer',
            border: "1px solid #F3EBFF",
            borderRadius: 1,
            clipPath: 'border-box',
          }} key={offer.id}>
          <Box sx={{padding: 1, flexGrow: 1}}>
            <Stack direction={'row'} gap={2} alignItems={'center'} sx={{
              padding: 1,
              alignSelf: 'center',
              flexGrow: 1,
              borderRadius: 1,
              backgroundColor: "#F3EBFF",
            }}>
              <OrderNumber orderId={offer.order.id}/>
              <Stack>
                <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['submittedAmount']}</Typography>
                <Typography sx={{fontSize: 18}}>{formattedAmount}</Typography>
              </Stack>
            </Stack>
            <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['onDate']}</Typography>
            <Typography sx={{}}>{offer.order.content.movingDateFormatted}</Typography>
            <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['origin']}</Typography>
            <Typography sx={{}}>{offer.order.content.strDepartAddress}</Typography>
            <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['destination']}</Typography>
            <Typography sx={{}}>{offer.order.content.strArriveAddress}</Typography>
          </Box>

          <Box sx={{
            padding: 1,
            backgroundColor: "#F3EBFF",
            display: 'flex',
            alignItems: 'center',
          }}>
            <ChevronLeftIcon sx={{color: "#6F4DFF"}}/>
          </Box>
        </Stack>
      </Stack>
    );
  }));

  const declinedOrdersSection = () => <Stack gap={0} sx={{
    borderRadius: 1,
    backgroundColor: "#ffffff",
    clipPath: 'padding-box',
  }}>
    <Stack direction={"row"} gap={2} sx={{
      padding: 2,
      alignItems: "center",
      backgroundColor: "#ececec",
    }}>
      <Box sx={{
        flexGrow: 1
      }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: 16,
        }}>{labels['ordersIRejected1']}</Typography>
      </Box>
    </Stack>
    <Stack gap={2} sx={{padding: 2}}>
      {
        partner.declinedOffers.map(offer => {
          return <Stack
            onClick={() => onClickInviteOffer(offer)}
            gap={1}
            direction={'row'}
            sx={{
              cursor: 'pointer',
              border: "1px solid #ececec",

              borderRadius: 1,
              clipPath: 'border-box'
            }} key={offer.id}>
            <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
              <OrderNumber orderId={offer.orderId} sx={{position: 'absolute', top: 5, right: 0}}/>
              <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['onDate']}</Typography>
              <Typography sx={{}}>{offer.order.content.movingDateFormatted}</Typography>
              <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['origin']}</Typography>
              <Typography sx={{}}>{offer.order.content.strDepartAddress}</Typography>
              <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['destination']}</Typography>
              <Typography sx={{}}>{offer.order.content.strArriveAddress}</Typography>
            </Box>
            <Box sx={{
              padding: 1,
              backgroundColor: "#ececec",
              display: 'flex',
              alignItems: 'center',
            }}>
              <ChevronLeftIcon sx={{color: "#b1b1b1"}}/>
            </Box>
          </Stack>
        })
      }
    </Stack>
  </Stack>;

  const expiredOffersSection = () => <Stack gap={0} sx={{
    borderRadius: 1,
    backgroundColor: "#ffffff",
    clipPath: 'padding-box',
  }}>
    <Stack direction={"row"} gap={2} sx={{
      padding: 2,
      alignItems: "center",
      backgroundColor: "#ececec",
    }}>
      <Box sx={{
        flexGrow: 1
      }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: 16,
        }}>{labels['ordersIMissed']}</Typography>
      </Box>
    </Stack>
    <Stack gap={2} sx={{padding: 2}}>
      {
        partner.expiredOffers.map(offer => {
          return <Stack
            onClick={() => onClickExpiredOffer(offer)}
            gap={1}
            direction={'row'}
            sx={{
              cursor: 'pointer',
              border: "1px solid #ececec",

              borderRadius: 1,
              clipPath: 'border-box'
            }} key={offer.orderId}>
            <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
              <OrderNumber orderId={offer.orderId} sx={{position: 'absolute', top: 5, right: 0}}/>
              <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['onDate']}</Typography>
              <Typography sx={{}}>{offer.order.content.entries.dateDay.toSummaryDisplay()}</Typography>
              <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['origin']}</Typography>
              <Typography sx={{}}>{offer.order.content.entries.departAddress.toSummaryDisplay()}</Typography>
              <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['destination']}</Typography>
              <Typography sx={{}}>{offer.order.content.entries.arriveAddress.toSummaryDisplay()}</Typography>
            </Box>
            <Box sx={{
              padding: 1,
              backgroundColor: "#ececec",
              display: 'flex',
              alignItems: 'center',
            }}>
              <ChevronLeftIcon sx={{color: "#b1b1b1"}}/>
            </Box>
          </Stack>
        })
      }
    </Stack>
  </Stack>;

  const noOrders = () => (
    <Stack sx={{
      padding: 2,
      borderRadius: 1,
      backgroundColor: "white",
    }} gap={2}>
      <Stack><Typography>{labels['noNewOrders']}</Typography></Stack>
    </Stack>
  );
  
  return (
    <Stack sx={{
      margin: 2,
    }} gap={2}>
      <PartnerMainHeader partner={partner} />
      {partner.hasActiveContracts ? activeContracts() : null}
      {partner.hasInviteOffers ? inviteOffersSection() : noOrders()}
      {partner.hasSubmittedOffers ? submittedOffersSection() : null}
      {partner.hasDeclinedOffers ? declinedOrdersSection() : null}
      {partner.hasExpiredOffers ? expiredOffersSection() : null}
    </Stack>
  );
}

function PartnerMainHeader({partner}: { partner: ReturnType<typeof usePartner> }) {
  return (
    <Stack gap={2} sx={{
      padding: 2,
      backgroundColor: '#F3EBFF',
      borderRadius: 1,
      clipPath: 'padding-box',
    }}>
      <Stack direction={'row'} gap={2} sx={{
        alignItems: 'center',
      }}>
        <ProfilePhoto partner={partner} />
        <Stack>
          <Typography variant={'h5'}>{partner.businessName}</Typography>
          {partner.publicProfile.rating.hasRatings &&
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography>{partner.publicProfile.rating?.avgRating}</Typography>
              <Rating value={partner.publicProfile.rating?.avgRating} precision={0.5} readOnly/>
            </Stack>
          }
        </Stack>
        <Stack sx={{flexGrow: 1, alignItems: 'flex-end'}}>
          {/* @ts-expect-error */}
          <Link to={'/partner/profile'}><Button>{labels.myProfile}</Button></Link>
        </Stack>
      </Stack>
    </Stack>
  );
}