import {OrderItemProps} from "./order-items-types";

export const useItemConfig = (itemTitle: string): ItemDataConfig | null => {
  const item = items[itemTitle];
  return item || null;
}

export const useCategoryItems = (category: string): string[] => {
  const catItems = categories[category];
  const sortedCatItems = catItems.sort((a, b) => a.localeCompare(b));
  return sortedCatItems;
}

export const useRoomItems = (room: string): string[] => {
  const roomItems = rooms[room];
  const sortedRoomItems = roomItems.sort((a, b) => a.localeCompare(b));
  return sortedRoomItems;
}

export const useCategories = () => {
  const sortedCats = Object.fromEntries(Object.entries(categories).sort((a, b) => a[0].localeCompare(b[0])));
  return sortedCats;
}

export const useRooms = () => {
  const sortedRooms = Object.fromEntries(Object.entries(rooms).sort((a, b) => a[0].localeCompare(b[0])));
  return sortedRooms;
}

export const useItems = () => {
  const sortedItems = Object.fromEntries(Object.entries(items).sort((a, b) => a[0].localeCompare(b[0])));
  return sortedItems;
}

export interface ItemDataConfig {
  config?: {
    hideAssembly?: boolean;
    hideDisassembly?: boolean;
    hideThrowAway?: boolean;
    maxCount?: number;
    commentsLabel?: string;
  };
  props?: Pick<OrderItemProps, 'type' | 'config'>[];
}

export const items: { [title: string]: ItemDataConfig } = {
  "קונסולת/שידת טלויזיה": {
    props: [
      {
        type: 'select',
        config: {
          label: 'סוג',
          options: [
            "תלויה",
            "עומדת",
          ]
        }
      },
    ],
  },
  "גוף תאורה": {},
  "לול": {},
  "מזגן": {
    props: [
      {type: 'radio', config: {label: 'סוג', options: ['מזגן קיר', 'מזגן נייד']}},
    ]
  },
  "רדיאטור / תנור חימום": {},
  "פטריית חימום": {},
  "מאוורר": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    }
  },
  "מאוורר תקרה": {},
  "כיסא אוכל": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "הדום": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "ספה": {
    props: [
      {type: 'counter', config: {defaultValue: 1, minValue: 1, label: 'מספר מושבים'}},
      {type: 'checkbox', config: {label: 'נפתחת'}},
      {type: 'checkbox', config: {label: 'כולל שזלונג'}},
    ],
  },
  "מחשב נייח": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "מדפסת / פקס": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "מערכת רמקולים": {},
  "טלויזה": {
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['עד 42"', 'מעל 42"']}},
    ]
  },
  "מיטה": {
    props: [
      {
        type: 'select', config: {
          label: 'גודל בסיס', options: [
            "יחיד",
            "וחצי",
            "זוגי",
            "יהודית",
            "קומותיים",
            "קינג סייז",
            "חשמלית/מתכווננת",
            "מיטת תינוק",
            "מיטת מעבר",
          ]
        }
      },
    ],
  },
  "מזרן": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
    props: [
      {
        type: 'select', config: {
          label: 'גודל מזרן', options: [
            "יחיד",
            "וחצי",
            "זוגי",
            "יהודית",
            "קומותיים",
            "קינג סייז",
            "חשמלית/מתכווננת",
            "מיטת תינוק",
            "מיטת מעבר",
          ]
        }
      }
    ]
  },
  "מיטת יחיד נפתחת": {},
  "מיטת מעבר": {},
  "מיטת תינוק / עריסה": {},
  "פסנתר": {},
  "אורגן": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "גיטרה": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "מערכת תופים": {},
  "אי מטבח": {},
  "ארון": {
    props: [
      {type: 'counter', config: {defaultValue: 2, minValue: 1, label: 'מספר דלתות'}},
      {type: 'radio', config: {label: 'סוג דלתות', options: ['נפתחות', 'הזזה']}},
    ]
  },
  "ארון אמבטיה": {
    props: [
      {type: 'counter', config: {label: 'מספר דלתות', defaultValue: 2, minValue: 1}},
      {type: 'radio', config: {label: 'סוג ארון', options: ['עילי', 'תחתי', 'עומד']}},
    ]
  },
  "ארון ויטרינה": {
    props: [
      {type: 'counter', config: {label: 'מספר דלתות', defaultValue: 2, minValue: 1}},
      {type: 'radio', config: {label: 'סוג ארון', options: ['עילי', 'תחתי', 'עומד']}},
    ],
  },
  "ארון שירות": {
    props: [
      {type: 'counter', config: {label: 'מספר דלתות', defaultValue: 2, minValue: 1}},
    ],
  },
  "יחידת ארון מטבח": {
    props: [
      {type: 'counter', config: {label: 'מספר דלתות', defaultValue: 2, minValue: 1}},
      {type: 'radio', config: {label: 'סוג ארון', options: ['עילי', 'תחתי', 'עומד']}},
    ],
  },
  "שולחן אוכל": {
    props: [
      {type: 'counter', config: {label: 'גודל (על פי מקומות ישיבה)', defaultValue: 4, minValue: 1}},
      {type: 'counter', config: {label: 'כמות כסאות', defaultValue: 0, minValue: 0}},
    ]
  },
  "שולחן קפה / סלון / נוי": {},
  "שולחן משרדי / עבודה": {},
  "שולחן משרדי עם שלוחה / מגירות": {},
  "כסא / משרדי / הדום / מנהלים": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "מולטי טריינר / אופני כושר": {},
  "ספת כושר / מיטת משקולות": {},
  "מכשיר ריצה / הליכון": {},
  "משקולות": {
    config: {
      commentsLabel: 'יש לציין סה״כ משקל'
    },
    props: [
      {
        type: 'select', config: {
          label: 'משקל כולל', options: [
            'עד 50 ק״ג',
            '50 - 100 ק״ג',
            '100 - 200 ק״ג',
            'מעל 200 (נא לציין בהערות)',
          ]
        }
      },
    ]
  },
  "אופניים / אופניים חשמליים / קורקינט": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    }
  },
  "שק איגרוף": {},
  "שולחן פינג-פונג": {},
  "פסל": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "כד נוי": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "ציוד אומנות": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "שולחן ילדים / בית בובות / ארגז משחקים": {},
  "ארגז כלים": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "שטיחים": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "סולם": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "גלשן": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "תמונות": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "פוף": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "ראי": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "כורסה": {
    props: [
      {type: 'select', config: {label: 'סוג כורסה', options: ['יחיד', 'עיסוי', 'טלויזיה']}},
    ]
  },
  "שולחן סלון": {
    props: [
      {type: 'checkbox', config: {label: 'מכיל זכוכית'}},
    ]
  },
  "מקרר": {
    props: [
      {type: 'counter', config: {label: 'מספר דלתות', defaultValue: 1, minValue: 1}},
    ]
  },
  "מקפיא": {
    props: [
      {type: 'counter', config: {label: 'מספר מגירות', defaultValue: 1, minValue: 1}},
    ]
  },
  "טוסטר-אובן / מיקרוגל": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    }
  },
  "מקרר מיני בר / יין": {},
  "מדיח כלים": {
    props: [
      {type: 'radio', config: {label: 'סוג', options: ['רצפתי', 'דלפקי']}},
    ]
  },
  "תנור מטבח": {
    props: [
      {type: 'checkbox', config: {label: 'בילט-אין'}},
    ],
  },
  "מתקן מים": {},
  "מייבש כביסה": {},
  "מכונת כביסה": {},
  "מתלה כביסה": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    }
  },
  "עציץ / אדנית": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "כסא בריכה / מיטת שיזוף": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    }
  },
  "ספסל נדנדה": {
    props: [
      {type: 'counter', config: {label: 'מספר מושבים', defaultValue: 1, minValue: 1}},
    ],
  },
  "טרמפולינה / בריכה": {},
  "כסא גינה": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    }
  },
  "שולחן גינה": {
    props: [
      {type: 'counter', config: {label: 'גודל (על פי מקומות ישיבה)', defaultValue: 4, minValue: 1}},
      {type: 'counter', config: {label: 'כמות כסאות', defaultValue: 0, minValue: 0}},
    ],
  },
  "ספת גינה": {
    props: [
      {type: 'counter', config: {label: 'מספר מושבים', minValue: 1, defaultValue: 1}},
      {type: 'checkbox', config: {label: 'נפתחת'}},
      {type: 'checkbox', config: {label: 'כולל שזלונג'}},
    ],
  },
  "גריל": {},
  "מחסן גינה": {
    config: {
      commentsLabel: 'יש לציין גודל במטרים'
    },
  },
  "כלי עבודה לגינה": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "שמשיית חצר": {},
  "כורסת גינה": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "מכסחת דשא": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "שידת איפור": {},
  "שידת החתלה": {
    props: [
      {type: 'counter', config: {label: 'כמות מגירות', defaultValue: 2, minValue: 4}},
    ],
  },
  "שידת לילה": {},
  "כוננית / כוורת": {
    props: [
      {type: 'counter', config: {label: 'מספר תאים / מדפים', defaultValue: 1, minValue: 1}},
    ],
  },
  "שידה": {
    props: [
      {type: 'counter', config: {label: 'מספר מגירות', defaultValue: 2, minValue: 2}},
    ],
  },
  "כלוב כלב/חתול/ציפורים": {
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "אקווריום": {
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "מדף": {
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "קרש גיהוץ": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "ארון נעליים": {
    props: [
      {type: 'counter', config: {label: 'מספר מדפים', minValue: 2, defaultValue: 1}},
    ],
  },
  "פח אשפה": {
    props: [
      {type: 'radio', config: {label: 'גודל', options: ['קטן', 'בינוני', 'גדול']}},
    ]
  },
  "תנור חימום תלוי": {},
  "אבטיית תינוק / גיגית": {},
  "פרגוד": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
  "שואב אבק": {
    config: {
      hideAssembly: true,
      hideDisassembly: true,
    },
  },
}

const categories = {
  "חימום וקירור": [
    "מזגן",
    "רדיאטור / תנור חימום",
    "תנור חימום תלוי",
    "פטריית חימום",
    "מאוורר",
    "מאוורר תקרה"
  ],
  "מערכות ישיבה": [
    "כורסה",
    "ספה",
    "כיסא אוכל",
    "הדום",
  ],
  "מדיה": [
    "מחשב נייח",
    "מדפסת / פקס",
    "מערכת רמקולים",
    "טלויזה",
  ],
  "מיטות ומזרנים": [
    "מיטה",
    "מזרן",
    "מיטת יחיד נפתחת",
    "מיטת מעבר",
    "מיטת תינוק / עריסה",
  ],
  "כלי נגינה": [
    "פסנתר",
    "אורגן",
    "גיטרה",
    "מערכת תופים",
  ],
  "ארונות": [
    "אי מטבח",
    "ארון",
    "ארון אמבטיה",
    "ארון ויטרינה",
    "ארון שירות",
    "יחידת ארון מטבח",
    "ארון נעליים",
  ],
  "שולחנות וכסאות": [
    "שולחן אוכל",
    "שולחן קפה / סלון / נוי",
    "שולחן משרדי / עבודה",
    "שולחן משרדי עם שלוחה / מגירות",
    "כסא / משרדי / הדום / מנהלים",
  ],
  "שידות ומזנונים": [
    "ארון ויטרינה",
    "מזנון טלויזיה",
    "שידה",
    "שידת לילה",
    "שידת החתלה",
    "כוננית / כוורת",
    "שידת איפור",
  ],
  "ציוד ספורט": [
    "מולטי טריינר / אופני כושר",
    "ספת כושר / מיטת משקולות",
    "מכשיר ריצה / הליכון",
    "משקולות",
    "סטנד משקולות",
    "אופניים / אופניים חשמליים / קורקינט",
    "שק איגרוף",
    "שולחן פינג-פונג",
  ],
  "שונות": [
    "גוף תאורה",
    "פסל",
    "כד נוי",
    "ציוד אומנות",
    "שולחן ילדים / בית בובות / ארגז משחקים",
    "ארגז כלים",
    "שטיחים",
    "סולם",
    "גלשן",
    "תמונות",
    "פוף",
    "ראי",
    "כלוב כלב/חתול/ציפורים",
    "אקווריום",
    "אופניים / אופניים חשמליים / קורקינט",
    "מדף",
    "קרש גיהוץ",
    "פח אשפה",
    "אבטיית תינוק / גיגית",
    "פרגוד",
    "שואב אבק",
  ],
}

const rooms = {
  "סלון": [
    "כורסה",
    "ספה",
    "שולחן סלון",
    "הדום",
    "שידה",
    "קונסולת/שידת טלויזיה",
    "גוף תאורה",
    "שידת החתלה",
    "שטיחים",
    "מדף",
    "פרגוד",
  ],
  "מטבח": [
    "שולחן אוכל",
    "מקרר",
    "מקפיא",
    "טוסטר-אובן / מיקרוגל",
    "מקרר מיני בר / יין",
    "מדיח כלים",
    "תנור מטבח",
    "מתקן מים",
    "מייבש כביסה",
    "מכונת כביסה",
    "מתלה כביסה",
    "גוף תאורה",
    "מדף",
    "פח אשפה",
    "פרגוד",
  ],
  "מרפסת שירות": [
    "מייבש כביסה",
    "מכונת כביסה",
    "מתלה כביסה",
    "ארון שירות",
    "מדף",
    "פח אשפה",
    "פרגוד",
    "שואב אבק",
  ],
  "גינה וגג": [
    "עציץ / אדנית",
    "כסא בריכה / מיטת שיזוף",
    "ספסל נדנדה",
    "טרמפולינה / בריכה",
    "כסא גינה",
    "שולחן גינה",
    "ספת גינה",
    "גריל",
    "מחסן גינה",
    "כלי עבודה לגינה",
    "שמשיית חצר",
    "כורסת גינה",
    "מכסחת דשא",
    "ארון שירות",
    "פוף",
    "מדף",
    "פח אשפה",
    "פרגוד",
  ],
  "חדר שינה": [
    "מיטה",
    "מזרן",
    "מיטת יחיד נפתחת",
    "שידת לילה",
    "שידה",
    "ארון",
    "פוף",
    "שידת איפור",
    "שידת החתלה",
    "גוף תאורה",
    "קונסולת/שידת טלויזיה",
    "שטיחים",
    "מדף",
    "ארון נעליים",
    "פרגוד",
  ],
  "חדר ילדים": [
    "ארון",
    "מיטת יחיד נפתחת",
    "מיטת מעבר",
    "מיטת תינוק / עריסה",
    "שידה",
    "לול",
    "שידת החתלה",
    "כוננית / כוורת",
    "שולחן ילדים / בית בובות / ארגז משחקים",
    "שטיחים",
    "פוף",
    "גוף תאורה",
    "שטיחים",
    "מדף",
    "ארון נעליים",
    "פח אשפה",
    "אבטיית תינוק / גיגית",
    "פרגוד",
  ],
};