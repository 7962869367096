import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {SelectedItem} from "./SelectedItem.tsx";
import {OrderItem} from "@tarimli-mono/api/src/shared/order-items-types.ts";
import {ItemsHeader} from "./ItemsHeader.tsx";

const labels = {
  'i-chose-title': 'פריטים שנבחרו (לחצו על הפריט לשינוי או הסרה):',
  'i-chose': (txt: string) => `בחרתי: ${txt}`,
  'can-add': 'פריטים לבחירה:',
}

export const TopCategory = ({orderId, onClickBack, onClickCategory, categoriesData, title, topTitle, selectedItems, icon}: {
  orderId: number,
  onClickBack: () => void,
  onClickCategory: (catTitle: string) => void,
  categoriesData: Record<string, string[]>,
  title: string,
  topTitle?: string,
  selectedItems: OrderItem[],
  icon?: any,
}) => {
  const categoryNames = Object.keys(categoriesData);
  return (
    <Box sx={{
      padding: 2,
      // outline: '1px solid red',
      backgroundColor: "#F5EDFF",
      borderRadius: 2,
      // position: 'fixed',
      // top: 180,
      // left: 0,
      // right: 0,
      // bottom: 0,
      // overflow: 'auto',
    }}>
      <Box sx={{
        padding: 2,
        // outline: '1px solid blue',
        backgroundColor: '#F5EDFF',
        // position: 'fixed',
        // top: 0,
        // left: 0,
        // right: 0,
        // height: 180,
        // zIndex: 1000,
      }}>
        <ItemsHeader orderId={orderId} onClickBack={onClickBack} showSearch={true} title={title} topTitle={topTitle} icon={icon}/>
      </Box>
      <Stack gap={2}>
        <TopCategoryContainer title={title} topTitle={topTitle} onClickBack={onClickBack}>
          <Stack gap={3}>
            {
              categoryNames.map((cat) => {
                const catItems = categoriesData[cat];
                const selectedFromCategory = (selectedItems || []).filter((item) => catItems.includes(item.title));
                return (
                  <Stack key={cat} sx={{cursor: 'pointer'}} onClick={() => onClickCategory(cat)}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Stack gap={2} flexGrow={1}>
                        <Typography fontSize={20}>{cat}</Typography>
                      </Stack>
                      <ChevronLeftIcon/>
                    </Stack>
                    {selectedFromCategory.length > 0 ?
                      <>
                        <Typography
                          fontSize={12}>{labels['i-chose'](selectedFromCategory.map((item) => item.title).join(", "))}</Typography>
                      </> : null
                    }
                  </Stack>
                );
              })
            }
          </Stack>
        </TopCategoryContainer>
      </Stack>
    </Box>
  );
};

export const CategoryItems = (
  {
    orderId,
    title,
    topTitle,
    itemsData,
    selectedItems,
    onClickBack,
    icon,
  }:
    {
      orderId: number,
      title: string,
      topTitle: string,
      itemsData: string[],
      selectedItems: OrderItem[],
      onClickBack: () => void,
      icon?: any,
    }
) => {
  const selectedFromCategory = (selectedItems || []).filter((item) => itemsData.includes(item.title));
  return (
    <Box sx={{
      padding: 2,
      backgroundColor: "#F5EDFF",
      borderRadius: 2,
    }}>
      <Box sx={{
        padding: 2,
        backgroundColor: '#F5EDFF',
      }}>
        <ItemsHeader orderId={orderId} onClickBack={onClickBack} showSearch={true} title={title} topTitle={topTitle} icon={icon}/>
      </Box>
      <Stack gap={2}>
        <TopCategoryContainer title={title} topTitle={topTitle} onClickBack={onClickBack}>
          <Stack gap={2}>
            {
              selectedFromCategory.length > 0 ?
                <Stack gap={3}>
                  <Typography fontSize={15} fontWeight={600} sx={{textDecoration: 'underline'}}>
                    {labels['i-chose-title']}
                  </Typography>
                  {
                    selectedFromCategory.map((item) => (
                      <SelectedItem
                        key={item.id}
                        orderId={orderId}
                        item={item}
                      />
                    ))
                  }
                  <Divider/>
                </Stack>
                : null
            }
            <Stack gap={3}>
              {selectedFromCategory.length > 0 ? <Typography fontSize={15} fontWeight={600} sx={{textDecoration: 'underline'}}>{labels['can-add']}</Typography> : null}
              {
                itemsData.map((itemTitle) => {
                  return (
                    <SelectedItem
                      key={itemTitle}
                      orderId={orderId}
                      newItemTitle={itemTitle}
                    />
                  );
                })
              }
            </Stack>
          </Stack>
        </TopCategoryContainer>
      </Stack>
    </Box>
  );
};

export const TopCategoryContainer = ({title, children, topTitle, onClickBack}: {
  title: string,
  children: React.ReactNode,
  topTitle?: string
  onClickBack: () => void
}) => {
  return (
    <Box sx={{padding: 2, backgroundColor: "white", borderRadius: 1}}>
      <Stack gap={2}>
        {/*<Stack direction={'row'} sx={{alignItems: 'center'}} gap={1}>*/}
        {/*  <IconButton onClick={onClickBack} sx={{*/}
        {/*    backgroundColor: "#F6F1FE",*/}
        {/*    borderRadius: 1,*/}
        {/*  }}>*/}
        {/*    <ChevronRightIcon />*/}
        {/*  </IconButton>*/}
        {/*  {topTitle ? <>*/}
        {/*    <Typography fontWeight={"400"} fontSize={15}>{topTitle}:</Typography>*/}
        {/*    /!*<ChevronLeftIcon/>*!/*/}
        {/*  </> : null}*/}
        {/*  <Typography fontWeight={"bold"} fontSize={15}>{title}</Typography>*/}
        {/*</Stack>*/}
        <Box>
          {children}
        </Box>
      </Stack>
    </Box>
  );
};


