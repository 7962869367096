import { createFileRoute, notFound, redirect, useParams } from '@tanstack/react-router'
import {useCustomer} from "../../../../services/auth.tsx";
import {CreateNewOrderScreen, RateOrderScreen} from "../../../../components/orders/paid.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/completed')({
  component: CompletedOrderScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'completed') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
})

export function CompletedOrderScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const orderId = Number(routeParams.orderId);
  const order = customer.findOrder(orderId);
  const isRated = !!order.review;

  if (isRated) {
    return <CreateNewOrderScreen />;
  } else {
    return <RateOrderScreen order={order} customer={customer} />;
  }
}