export const sanitizeMessageTextSpaces = (text: string) => {
  return text
    .replace(/\n/g, ' ')
    .replace(/\r/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
}

export function convertToOneLine(input: string, maxLen?: number): string {
  let result = String(input)
    .split(/\r?\n/)
    .map(line => line.trim()) // Remove leading and trailing whitespace
    .filter(line => line.length > 0) // Remove empty lines
    .map((line, index, array) => {
      if (/[.,;:!?()\[\]\{\}\/\\]$/.test(line)) {
        return line;
      }
      if (index === array.length - 1) {
        return line;
      }
      return line + ';';
    })
    .join(' ')
    .replace(/\s+/g, ' '); // Replace multiple spaces with a single space
  if(maxLen > 0) {
    result = result.slice(0, maxLen);
  }
  return result;
}