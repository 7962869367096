import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {createFileRoute, Link} from '@tanstack/react-router'
import {Box} from '@mui/material';
import TarimliLogo from "/tarimli-logo.svg";
import {LoginBox} from "../components/otp.tsx";
import {useAuth} from "../services/auth.tsx";

export const Route = createFileRoute('/partner-login')({
  component: LoginScreen,
})

const labels = {
  'partner-login': 'כניסה לספקים',
  'client-login': 'כניסה לעוברי דירה',
}

export default function LoginScreen() {
  const auth = useAuth();

  const onSuccess = (accessToken: string) => {
    auth.loginUser(accessToken);
    window.location.href = '/partner';
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        paddingTop: 4
      }}
    >
      <Stack spacing={4}>
        <img src={TarimliLogo} height={60}/>
        <Box sx={{
          backgroundColor: '#ffde00',
          borderRadius: 1,
          padding: 2,
          width: 350,
          maxWidth: '90vw',
        }}>
          <Stack gap={2}>
            <Typography sx={{fontSize: 20, fontWeight: 600, textAlign: 'center'}}>{labels['partner-login']}</Typography>
            <LoginBox isPartner={true} onSuccess={onSuccess} />
          </Stack>
        </Box>
        <Box sx={{alignSelf: 'center'}}>
          {/* @ts-expect-error */}
          <Link to="/login">{labels['client-login']}</Link>
        </Box>
      </Stack>
    </Box>
  )
}

