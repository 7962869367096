import * as Sentry from "@sentry/react";
import * as React from 'react'
import {useCallback} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import {ClientCustomer} from "../model/client-customer.ts";
import {ClientPartner} from "../model/client-partner.ts";
import {ClientAdmin} from "../model/client-admin.ts";
import {useAppState} from "./state.ts";
import {useQuerySuspenseCurrentUser} from "./api-user.ts";

export interface AuthContext {
  isAuthenticated?: boolean;
  isCustomer?: boolean;
  isPartner?: boolean;
  isAdmin?: boolean;
  customer?: ClientCustomer;
  partner?: ClientPartner;
  admin?: ClientAdmin;
  logoutUser: () => void;
  loginUser: (token: string) => void;
  loginAdmin: (token: string) => void;
}

const AuthContext = React.createContext<AuthContext | null>(null)

export function AuthProvider({children}: { children: React.ReactNode }) {
  const appState = useAppState();
  const userQuery = useQuerySuspenseCurrentUser(() => {
    appState.clear();
    Sentry.setUser(null);
    window.location.href = '/login';
  });
  const {clientCustomer, clientPartner} = userQuery.data || {};
  const customer = clientCustomer ? new ClientCustomer(clientCustomer) : null;
  const partner = clientPartner ? new ClientPartner(clientPartner) : null;
  const isAdmin = !!appState.adminToken;
  const isAuthenticated = !!appState.userToken && !!(customer || partner);

  const loginUser = useCallback((token: string) => {
    Sentry.setUser({
      customerId: customer?.id,
      partnerId: partner?.id,
      username: customer?.phone || partner?.phone,
      isAdmin,
    });
    appState.setUserToken(token);
  }, []);
  const loginAdmin = useCallback((token: string) => {
    appState.setAdminToken(token);
  }, []);
  const logoutUser = useCallback(() => {
    appState.clear();
    Sentry.setUser(null);
    window.location.href = '/login';
  }, []);

  if (userQuery.error && !userQuery.isFetching) {
    throw userQuery.error;
  }

  if (userQuery.isFetching) {
    return <CircularProgress/>;
  }

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isPartner: !!partner,
      isCustomer: !!customer,
      isAdmin,
      customer,
      partner,
      loginUser,
      logoutUser,
      loginAdmin,
    }}>
      {children}
    </AuthContext.Provider>

  )
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export function useCustomer() {
  const {customer} = useAuth()
  return customer || null;
}

export function usePartner() {
  const {partner} = useAuth()
  return partner || null;
}
