import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export const BoxesDescription = () => {
  const row = (title: string, value: number) => <><Stack direction={'row'} gap={2} sx={{p: 2}}>
    <Typography sx={{flexGrow: 1}}>{title}</Typography>
    <Typography>{value}</Typography>
  </Stack><Divider/></>;
  return <Stack sx={{p: 2}}>
    <Typography sx={{textAlign: 'center', fontWeight: 600}}>בד״כ כמות ארגזים מחושבת ע״פ כמות חדרים:</Typography>
    {row('דירת חדר', 20)}
    {row('שני חדרים', 35)}
    {row('שניים וחצי חדרים', 40)}
    {row('שלושה חדרים', 50)}
    {row('שלושה וחצי חדרים', 60)}
    {row('ארבעה חדרים', 70)}

    {row('ארבעה וחצי חדרים', 80)}
    {row('חמישה חדרים', 100)}
    {row('שישה חדרים', 120)}

  </Stack>
}