import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {useItemConfig} from "@tarimli-mono/api/src/shared/items-data.ts";
import {
  OrderItem,
  OrderItemProps,
  OrderItemPropsCheckboxConfig,
  OrderItemPropsCounterConfig, OrderItemPropsRadioConfig, OrderItemPropsSelectConfig
} from "@tarimli-mono/api/src/shared/order-items-types.ts";
import {EditItem} from "./EditItem.tsx";
import {useMutationDeleteOrderItem} from "../services/api-order.ts";


const labels = {
  'plus-assembly': '+ הרכבה',
  'plus-disassembly': '+ פירוק',
  plusThrowAway: '+ פינוי לפח',
  'comments': 'הערות',
  'add-photo': 'הוספת תמונה (אופציונלי)',
  'save': 'שמירה',
  'delete': 'מחיקה',
  'quantity': 'כמות',
  'please-choose': '[אנא בחרו]',
}

export const SelectedItem = ({orderId, item, newItemTitle}: {
  orderId: number,
  item?: OrderItem,
  newItemTitle?: OrderItem['title'],
}) => {
  const [open, setOpen] = useState(false);

  const itemConfig = useItemConfig(item?.title || newItemTitle);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const deleteOrderItemMutation = useMutationDeleteOrderItem();

  const itemId = item?.id || null;
  const title = item?.title || newItemTitle;
  const quantity = item?.quantity || 1;
  const assembly = item?.assembly || false;
  const disassembly = item?.disassembly || false;
  const throwAway = item?.throwAway || false;
  const itemProps = (item?.itemProps || itemConfig?.props?.map((p) => ({
    type: p.type,
    value: p.config.defaultValue,
    config: p.config,
  })) || [] as OrderItemProps[])
    .filter((p) => !!p);
  const comments = item?.comments || '';

  const onClickDelete = async () => {
    setDeleteSubmitting(true);
    await deleteOrderItemMutation.mutateAsync({
      orderId,
      orderItemId: itemId,
    });
    setDeleteSubmitting(false);
  };
  
  return (
    <>
      <Stack onClick={() => setOpen(true)} gap={1} direction={"row"} sx={{cursor: 'pointer', opacity: deleteSubmitting ? '0.5' : 1, pointerEvents: deleteSubmitting ? 'none' : 'auto'}}>
        {
          typeof item?.quantity === "number" && item?.quantity > 0 ?
            <Box
              sx={{
                alignSelf: "flex-start",
                backgroundColor: "#F5EDFF",
                padding: 0.5,
                borderRadius: 0.5,
                aspectRatio: "1/1",
                height: "2em",
                textAlign: "center"
              }}>
              <Typography fontSize={16}>{item.quantity}</Typography>
            </Box> :
            <Box
              sx={{
                alignSelf: "flex-start",
                border: "1px solid #D6BDFF",
                padding: 0.5,
                borderRadius: 0.5,
                aspectRatio: "1/1",
                height: "2em",
                textAlign: "center"
              }}>

            </Box>
        }
        <Stack gap={0.5} sx={{flexGrow: 1}}>
          <Typography fontSize={16} fontWeight={500}>{title}</Typography>
          {(disassembly || assembly || throwAway) ?
            <Stack direction={"row"} gap={1}>
              {disassembly ? <Typography fontSize={12}>{labels['plus-disassembly']}</Typography> : null}
              {assembly ? <Typography fontSize={12}>{labels['plus-assembly']}</Typography> : null}
              {throwAway ? <Typography fontSize={12}>{labels.plusThrowAway}</Typography> : null}
            </Stack> : null
          }
          {
            item ? (itemProps || []).map(({type, config, value}, itemPropIdx) => {
              switch (type) {
                case 'counter':
                  const {label: counterLabel} = config as OrderItemPropsCounterConfig;
                  return (
                    <Stack key={counterLabel} direction={'row'} gap={1}>
                      <Typography fontSize={12}>{counterLabel}:</Typography>
                      <Typography fontSize={12}>{value}</Typography>
                    </Stack>
                  );
                  break;
                case 'checkbox':
                  if (value) {
                    const {label: checkboxLabel} = config as OrderItemPropsCheckboxConfig;
                    return (
                      <Stack key={checkboxLabel} direction={'row'} gap={1} sx={{alignItems: 'center'}}>
                        <CheckBoxIcon sx={{fontSize: 16, opacity: 0.5}}/>
                        <Typography fontSize={12}>{checkboxLabel}</Typography>
                      </Stack>
                    );
                  } else {
                    return null;
                  }
                  break;
                case 'radio':
                  const {label: radioLabel} = config as OrderItemPropsRadioConfig;
                  return (
                    <Stack key={radioLabel} direction={'row'} gap={1}>
                      <Typography fontSize={12}>{radioLabel}:</Typography>
                      <Typography fontSize={12}>{value}</Typography>
                    </Stack>
                  );
                  break;
                case 'select':
                  const {label: selectLabel} = config as OrderItemPropsSelectConfig;
                  return (
                    <Stack key={selectLabel} direction={'row'} gap={1}>
                      <Typography fontSize={12}>{selectLabel}:</Typography>
                      <Typography fontSize={12}>{value}</Typography>
                    </Stack>
                  );
                  break;
              }
            }) : null
          }
          {comments ? <Typography fontSize={12}>{comments}</Typography> : null}
          {
            item?.media?.length > 0 ?
              <Stack direction={'row'} sx={{flexWrap: 'wrap'}} gap={1}>
                {(item.media || []).map(([id, url]) => {
                  return (
                    <Box key={id} sx={{
                      borderRadius: 0.5, overflow: 'hidden', width: '2em',
                      height: '2em',
                      backgroundColor: '#f4f4f4',
                    }}>
                      <img key={id} src={url} style={{
                        width: '2em',
                        height: '2em',
                        borderRadius: 0.5,
                        objectFit: 'cover',
                      }}/>
                    </Box>
                  );
                })}
              </Stack> : null
          }
        </Stack>
        {item ? <IconButton sx={{alignSelf: 'flex-end'}} onClick={(e) => {
          e.stopPropagation();
          onClickDelete();
        }}>
          <DeleteForeverIcon/>
        </IconButton> : null}
      </Stack>
      {open ?
        <EditItem orderId={orderId} item={item} open={open} setOpen={setOpen} newItemTitle={newItemTitle}/> : null}
      {/*<EditItem orderId={orderId} item={item} open={open} setOpen={setOpen} newItemTitle={newItemTitle} />*/}
    </>
  );
};
