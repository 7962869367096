import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {Controller, useFormContext} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import uniq from "lodash/uniq";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import {DateCalendar} from "@mui/x-date-pickers/DateCalendar";
import {AddItems, SelectedItems} from "../items";
import {useRouter} from "@tanstack/react-router";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { V1_OrderSummary } from "../v1-summary";
import {useCustomer} from "../../../services/auth.tsx";
import {NumberInput} from "../../number-input.tsx";
import {BoxesDescription} from "../../boxes-description.tsx";
import {OrderInput} from "../../OrderInput.tsx";

export const V1_FormGroup_Date = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
  } = formContext;
  return <Stack gap={2}>
    <Controller
      name="dateDay"
      control={control}
      render={({field}) => {
        return (
          <Box>
            <OrderInput>
              <LocalizationProvider adapterLocale={"he"} dateAdapter={AdapterDayjs}>
                <DateCalendar views={["day"]} disablePast sx={{
                  direction: 'rtl',
                  '& .MuiPickersArrowSwitcher-spacer': {
                    display: 'none'
                  },
                  '& .MuiPickersCalendarHeader-root': {
                    direction: 'ltr',
                  },
                  '& .MuiPickersArrowSwitcher-root > button': {
                    mx: 1,
                  },
                  '& .MuiPickersCalendarHeader-labelContainer': {
                    px: 2,
                  },
                }}
                              onChange={(date, a, b) => field.onChange(date)}
                              value={dayjs(field.value).isValid() ? dayjs(field.value) : null}/>
              </LocalizationProvider>
            </OrderInput>
          </Box>
        );
      }}/>
    <Controller
      name="dateDayHours"
      rules={{
        validate: {
          required: (value) => {
            if (value.length === 0) {
              return content.entries.dateDayHours.formErrorMessageRequired;
            }
          }
        },
      }}
      control={control}
      render={({field}) => {
        const handleChange = (checked: boolean, value: string) => {
          if (!checked) {
            field.onChange(field.value.filter((val: string) => val !== value))
          } else {
            if (value === 'none') {
              field.onChange(['none']);
            } else {
              field.onChange(uniq([...field.value, value].filter(v => v !== 'none')));
            }
          }
        }
        return (
          <OrderInput title={content.entries.dateDayHours.formLabel} required>
            <RadioGroup {...field}>
              {content.entries.dateDayHours.options.map((option) => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Checkbox checked={field.value.includes(option.value)}
                                                     onChange={e => handleChange(e.target.checked, option.value)}/>}
                                  label={option.label}/>
              ))}
            </RadioGroup>
          </OrderInput>
        )
      }}/>
  </Stack>
}

export const V1_FormGroup_Items = ({content}: { content: OrderContent }) => {
  const customer = useCustomer();
  const order = customer.orders.find(o => o.id === Number(content.orderId));
  const router = useRouter();
  const onClickRooms = () => {
    router.navigate({
      to: `/order/$orderId/items/rooms`,
      params: {
        /* @ts-expect-error */
        orderId: String(order.id),
      },
    });
  }
  const onClickCategories = () => {
    router.navigate({
      to: `/order/$orderId/items/categories`,
      params: {
        /* @ts-expect-error */
        orderId: String(order.id),
      },
    });
  }
  return <Stack gap={2}>
    <AddItems orderId={order.id} onClickRooms={onClickRooms} onClickCategories={onClickCategories}/>
    <SelectedItems order={order}/>
  </Stack>;
}

export const V1_FormGroup_Boxes = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
  } = formContext;
  return <Stack gap={2}>
    <Stack gap={1}>
      <Typography sx={{flexGrow: 1}}>{content.entries.orderComments.formLabel}</Typography>
      <Controller
        control={control}
        name={'orderComments'}
        rules={{
          maxLength: {
            value: content.entries.orderComments.maxLen,
            message: content.entries.orderComments.formErrorMessageMaxLen
          }
        }}
        render={({field, fieldState}) => {
          return <TextField 
            {...field}
            helperText={fieldState.invalid ? `${field.value.length}/${content.entries.orderComments.maxLen} | ${fieldState.error.message}` : field.value.length > content.entries.orderComments.maxLen / 10 ? `${field.value.length}/${content.entries.orderComments.maxLen}` : ''}
            error={fieldState.invalid}
            multiline
            fullWidth
            rows={2}
          />
        }}/>
    </Stack>
    <Divider sx={{borderStyle: 'dashed'}}/>

    <Stack direction={'row'} gap={2} alignItems={'center'}>
      <Typography sx={{flexGrow: 1}}>{content.entries.bagsCount.formLabel}</Typography>
      <Controller
        control={control}
        name={'bagsCount'}
        render={({field}) => {
          return <NumberInput
            title={content.entries.bagsCount.formLabel}
            {...field}
            value={Number(field.value) || 0}
            min={0}
            onChange={(e, rv) => {
              const v = Number(rv);
              if (typeof v === 'undefined' || v < 0) {
                field.onChange('0');
              } else {
                field.onChange(String(v));
              }
            }}
          />
        }}/>
    </Stack>
    
    <Stack direction={'row'} gap={2} alignItems={'center'}>
      <Typography sx={{flexGrow: 1}}>{content.entries.suitcasesCount.formLabel}</Typography>
      <Controller
        control={control}
        name={'suitcasesCount'}
        render={({field}) => {
          return <NumberInput
            title={content.entries.suitcasesCount.formLabel}
            {...field}
            value={Number(field.value) || 0}
            min={0}
            onChange={(e, rv) => {
              const v = Number(rv);
              if (typeof v === 'undefined' || v < 0) {
                field.onChange('0');
              } else {
                field.onChange(String(v));
              }
            }}
          />
        }}/>
    </Stack>

    <Stack direction={'row'} gap={2} alignItems={'center'}>
      <Typography sx={{flexGrow: 1}}>{content.entries.sacksCount.formLabel}</Typography>
      <Controller
        control={control}
        name={'sacksCount'}
        render={({field}) => {
          return <NumberInput
            title={content.entries.sacksCount.formLabel}
            {...field}
            value={Number(field.value) || 0}
            min={0}
            onChange={(e, rv) => {
              const v = Number(rv);
              if (typeof v === 'undefined' || v < 0) {
                field.onChange('0');
              } else {
                field.onChange(String(v));
              }
            }}
          />
        }}/>
    </Stack>
    
    <Divider sx={{borderStyle: 'dashed'}}/>

    <Stack direction={'row'} gap={2} alignItems={'center'}>
      <Typography sx={{flexGrow: 1}}>{content.entries.boxesCount.formLabel}</Typography>
      <Controller
        control={control}
        name={'boxesCount'}
        render={({field}) => {
          return <NumberInput
            title={content.entries.boxesCount.formLabel}
            {...field}
            value={Number(field.value) || 0}
            min={0}
            onChange={(e, rv) => {
              const v = Number(rv);
              if (typeof v === 'undefined' || v < 0) {
                field.onChange('0');
              } else {
                field.onChange(String(v));
              }
            }}
          />
        }}/>
    </Stack>
    <BoxesDescription/>
  </Stack>
}

export const V1_FormGroup_Summary = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
  } = formContext;
  return <Stack gap={2}>
    <V1_OrderSummary orderContent={content}/>
    <Divider/>
    <Controller
      name="additionalOffers"
      control={control}
      render={({field}) => {
        return (
          <OrderInput title={content.entries.additionalOffers.formLabel}>
            <RadioGroup {...field}>
              {
                content.entries.additionalOffers.options.map((option) => {
                  return (
                    <FormControlLabel key={option.value} value={option.value}
                                      control={<Checkbox checked={field.value.includes(option.value)}
                                                         onChange={e => field.onChange(e.target.checked ? [...field.value, option.value] : field.value.filter(v => v !== option.value))}/>}
                                      label={option.formLabel}/>
                  )
                })
              }
            </RadioGroup>
          </OrderInput>
        )
      }}/>
  </Stack>
}