import axios, {AxiosProgressEvent} from "axios";
import {appState} from "./state.ts";
import {config} from "../config.ts";

export const authGetWithToken = async <T = any>(url: string, token: string, onUnauthorized?: () => void) => {
  try {
    const {data} = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data as T;
  } catch(e) {
    console.error(e);
    if (e.response && e.response.status === 401 && onUnauthorized) {
      onUnauthorized();
    }
    throw e;
  }
}

export const authGet = async <T = any>(url: string, onUnauthorized?: () => void) => {
  const token = appState.getState().userToken;
  return authGetWithToken<T>(`${config().apiPrefix}${url}`, token, onUnauthorized);
}

export const authAdminGet = async <T = any>(url: string, onUnauthorized?: () => void) => {
  const token = appState.getState().adminToken;
  return authGetWithToken<T>(`${config().apiPrefix}${url}`, token, onUnauthorized);
}

export const authPostWithToken = async <T = any>(url: string, body: unknown, token: string, headers: Record<string, string> = {}, onUnauthorized?: () => void, onUploadProgress?: any) => {
  try {
    const token = appState.getState().userToken;
    const {data} = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        console.log('progressEvent', progressEvent);
        onUploadProgress && onUploadProgress(progressEvent.progress);
      }
    });
    return data as T;
  } catch(e) {
    console.error(e);
    if (e.response && e.response.status === 400 && onUnauthorized) {
      onUnauthorized();
    }
    throw e;
  }
}

export const authPost = async <T = any>(url: string, body: unknown = {}, headers: Record<string, string> = {}, onUploadProgress?: any) => {
  const token = appState.getState().userToken;
  return authPostWithToken<T>(`${config().apiPrefix}${url}`, body, token, headers, onUploadProgress);
}

export const authAdminPost = async <T = any>(url: string, body: unknown, headers: Record<string, string> = {}, onUploadProgress?: any) => {
  const token = appState.getState().adminToken;
  return authPostWithToken<T>(`${config().apiPrefix}${url}`, body, token, headers, onUploadProgress);
}