import './services/sentry';
import './services/firebase';
import 'dayjs/locale/he';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime)
dayjs.locale('he');

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'lightgallery/css/lightgallery.css';
import {App} from "./app.tsx";
import CookieConsent from "react-cookie-consent";

const labels = {
  cookiesTitle: () => <p style={{fontSize: 12}}>אנחנו משתמשים בעוגיות וטכנולוגיות דומות כדי לאפשר שירותים ופעולות באתר שלנו וכדי להבין את התקשורת שלך עם השירות שלנו. על ידי לחיצה על "אישור", ניתן אישור לשימוש שלנו בטכנולוגיות כאלה לשיווק וניתוח מידע. <a style={{color: 'white'}} href={"https://www.tarimli.com/%D7%AA%D7%A8%D7%99%D7%9D%D7%9C%D7%99-%D7%AA%D7%A0%D7%90%D7%99-%D7%A9%D7%99%D7%9E%D7%95%D7%A9-%D7%95%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA-%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA"}>לצפייה במדיניות הפרטיות</a></p>,
  ok: 'אישור',
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App/>
    <CookieConsent location="bottom"
                   buttonText={labels.ok}
                   style={{ background: "#2B373B" }}
                   buttonStyle={{ backgroundColor: '#C9C0FF', fontSize: "16px" }}
                   expires={365}>{labels.cookiesTitle()}</CookieConsent>
  </React.StrictMode>,
)
