import * as Sentry from "@sentry/react";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {MuiFileInput} from 'mui-file-input'
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {useItemConfig} from "@tarimli-mono/api/src/shared/items-data.ts";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {OrderInput} from './OrderInput';
import {
  OrderItem,
  OrderItemProps,
  OrderItemPropsCheckboxConfig,
  OrderItemPropsCounterConfig,
  OrderItemPropsRadioConfig,
  OrderItemPropsSelectConfig
} from "@tarimli-mono/api/src/shared/order-items-types.ts";
import {CircularProgressWithLabel} from "./progress.tsx";
import {useMutationDeleteOrderItem, useMutationUpdateOrderItem} from "../services/api-order.ts";
import {NumberInput} from "./number-input.tsx";
import {PhotoGallery} from "./PhotoGallery.tsx";

const MAX_PHOTOS_PER_ITEM = 2;
const MAX_COMMENT_LEN = 100;
const COMMENT_LEN_WARN_LEN = 30;

const labels = {
  'plus-assembly': '+ הרכבה',
  'plus-disassembly': '+ פירוק',
  'need-disassembly': 'דרוש פירוק',
  'need-assembly': 'דרוש הרכבה',
  needThrowAway: 'פינוי לפח',
  'comments': 'הערות',
  'add-photo': 'בחר תמונה',
  camera: 'פתח מצלמה',
  noMoreThanMaxPhotos: `ניתן להוסיף רק עד ${MAX_PHOTOS_PER_ITEM} תמונות`,
  'save': 'שמירה',
  'delete': 'מחיקה',
  'quantity': 'כמות',
  'please-choose': '[אנא בחרו]',
  errorRequiredField: 'נא למלא שדה חובה',
  photosDisclaimer: 'אם הפריט חריג במיוחד - ניתן להוסיף תמונה (עד 2):',
  submitError: 'סליחה קרתה תקלה 😔 נא לנסות שוב. אם הבעיה נמשכת נא ליצור קשר עם שירות הלקוחות שלנו.',
  commentsError: `הערות יכולות להכיל עד ${MAX_COMMENT_LEN} תווים`,
}

export function EditItem({orderId, item, newItemTitle, open, setOpen}: {
  orderId: number,
  item?: OrderItem,
  newItemTitle?: OrderItem['title'],
  open: boolean,
  setOpen: (open: boolean) => void,
}) {
  const [hasCamera, setHasCamera] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<[index: number, value: number]>();
  const updateOrderItemMutation = useMutationUpdateOrderItem();
  const deleteOrderItemMutation = useMutationDeleteOrderItem();
  const itemConfig = useItemConfig(item?.title || newItemTitle);
  const itemId = item?.id || null;
  const title = item?.title || newItemTitle;
  const quantity = item?.quantity || 1;
  const assembly = item?.assembly || false;
  const disassembly = item?.disassembly || false;
  const throwAway = item?.throwAway || false;
  const itemProps = (item?.itemProps || itemConfig?.props?.map((p) => ({
    type: p.type,
    value: p.config.defaultValue,
    config: p.config,
  })) || [] as OrderItemProps[])
    .filter(p => !!p);
  const existingMedia = item?.media || [];
  const isEdit = !!item;
  const comments = item?.comments || '';
  useEffect(() => {
    if (updateOrderItemMutation.error) {
      Sentry.captureException(updateOrderItemMutation.error);
    }
  }, [updateOrderItemMutation.error]);
  useEffect(() => {
    if (deleteOrderItemMutation.error) {
      Sentry.captureException(deleteOrderItemMutation.error);
    }
  }, [deleteOrderItemMutation.error]);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    reset,
    watch,
    trigger,
    formState: {errors, isValid, isSubmitting},
  } = useForm({
    mode: 'all',
    values: {
      id: itemId,
      title,
      quantity,
      assembly,
      disassembly,
      throwAway,
      itemProps,
      comments,
      existingMedia,
      newMedia: [],
    },
  });
  const {fields: itemPropFields, append, prepend, remove, swap, move, insert} = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'itemProps', // unique name for your Field Array
  });
  const watchAllFields = watch();
  const allMedia = [...watchAllFields.existingMedia, ...watchAllFields.newMedia];
  useEffect(() => {
    (async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      mediaDevices.forEach((device) => {
        if (device.kind === 'videoinput') {
          setHasCamera(true);
        }
      });
    })();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      trigger();
    }, 0);
  }, [])
  const cancelModal = () => {
    setOpen(false);
    reset();
  }
  const onClickDelete = async () => {
    Sentry.captureMessage('deleteOrderItem', {
      level: 'info',
      extra: {
        orderId,
        orderItemId: itemId,
      },
    });
    await deleteOrderItemMutation.mutateAsync({
      orderId,
      orderItemId: itemId,
    });
    setOpen(false);
    reset();
  };
  const onClickSave = async (data) => {
    Sentry.captureMessage('updateOrderItem', {
      level: 'info',
      extra: {
        orderId,
        orderItemId: item?.id,
        data,
      },
    });
    await updateOrderItemMutation.mutateAsync({
      setUploadProgress,
      orderId,
      orderItemId: item?.id || null,
      title: data.title,
      quantity: data.quantity,
      assembly: data.assembly,
      disassembly: data.disassembly,
      throwAway: data.throwAway,
      comments: data.comments,
      // TODO: make this work without the need for a filter
      itemProps: (data.itemProps || []).filter(p => !!p),
      media: data.existingMedia.map(([id, url]) => id),
      newMedia: data.newMedia,
    });
    setOpen(false);
    reset();
  }
  const onAddPhotos = async (files: File[]) => {
    const currentMedia = getValues('newMedia');
    if (currentMedia.length >= MAX_PHOTOS_PER_ITEM) {
      return;
    }
    const allMediaCount = allMedia.length;
    const allowNewMediaCount = MAX_PHOTOS_PER_ITEM - allMediaCount;
    const updatedMedia = [...currentMedia, ...Array.from(files).slice(0, allowNewMediaCount)];
    setValue('newMedia', [...updatedMedia]);
  }
  const removeAddedMedia = (idx: number) => {
    const currentMedia = getValues('newMedia');
    setValue('newMedia', currentMedia.filter((_, i) => i !== idx));
  }
  const removeExistingMedia = (mediaId: string) => {
    const currentMedia = getValues('existingMedia');
    setValue('existingMedia', currentMedia.filter((m) => m[0] !== mediaId));
  }
  const saveButton = () => <LoadingButton
    disabled={!isValid || deleteOrderItemMutation.isPending || updateOrderItemMutation.isPending}
    loading={isSubmitting}
    onClick={handleSubmit(onClickSave)}
    loadingIndicator={<CircularProgressWithLabel color="inherit" size={16} value={uploadProgress?.[1] * 100}/>}
    sx={{
      flexGrow: 1,
      position: 'relative',
    }}
    variant={'contained'}
  >
    {labels['save']}
  </LoadingButton>;
  const deleteButton = () => <LoadingButton
    disabled={isSubmitting || deleteOrderItemMutation.isPending || updateOrderItemMutation.isPending}
    loading={deleteOrderItemMutation.isPending}
    onClick={onClickDelete}
    sx={{
      backgroundColor: "red",
    }}
    startIcon={<DeleteForeverIcon sx={{marginLeft: 1}}/>}
    variant={'contained'}
  >
    {labels['delete']}
  </LoadingButton>;
  return (
    <Drawer
      anchor="bottom"
      elevation={16}
      open={open}
      onClose={cancelModal}
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxWidth: '500px',
          margin: '0 auto',
        }
      }}
    >
      <Stack>
        <Stack direction={"row"}
               gap={2}
               alignItems={"center"}
               sx={{
                 padding: 1,
                 backgroundColor: '#F5EDFF',
               }}
        >
          <IconButton onClick={cancelModal} sx={{
            backgroundColor: "#EADCFF",
          }}>
            <CloseIcon/>
          </IconButton>
          <Typography fontSize={18}>{title}</Typography>
        </Stack>
        <Box sx={{
          padding: 2,
        }}>
          <Stack gap={2}>
            {
              (itemPropFields || []).filter(p => !!p).map((f, itemPropIdx) => {
                const {type, config, value, id} = f;
                switch (type) {
                  case 'counter':
                    const {
                      label: counterLabel,
                      maxValue,
                      minValue,
                      defaultValue
                    } = config as OrderItemPropsCounterConfig;
                    return (
                      <Stack key={id} gap={2}>
                        <Stack direction={'row'} sx={{alignItems: 'center'}}>
                          <Typography sx={{flexGrow: 1}}>{counterLabel}</Typography>
                          <Controller
                            key={counterLabel}
                            control={control}
                            name={`itemProps.${itemPropIdx}`}
                            render={({field}) => {
                              return <NumberInput
                                title={counterLabel}
                                {...field}
                                value={Number(field.value?.value) || defaultValue || minValue || 1}
                                min={typeof minValue === 'number' ? minValue : 1}
                                max={maxValue}
                                onChange={(e, rv) => {
                                  const v = Number(rv);
                                  if (isNaN(v) || v < minValue) {
                                    field.onChange({...field.value, value: minValue});
                                  } else if (v > maxValue) {
                                    field.onChange({...field.value, value: maxValue});
                                  } else {
                                    field.onChange({...field.value, value: v});
                                  }
                                }}
                              />
                            }}/>
                        </Stack>
                        <Divider/>
                      </Stack>
                    )
                    break;
                  case 'checkbox':
                    const {
                      label: checkboxLabel,
                      defaultValue: checkboxDefaultValue
                    } = config as OrderItemPropsCheckboxConfig;
                    return (
                      <Stack key={id} gap={2}>
                        <Controller
                          name={`itemProps.${itemPropIdx}`}
                          control={control}
                          render={({field}) => {
                            return (
                              <FormControlLabel label={checkboxLabel} control={<Checkbox
                                {...field}
                                checked={Boolean(field.value?.value) || checkboxDefaultValue || false}
                                onChange={e => {
                                  field.onChange({...field.value, value: e.target.checked})
                                }}
                              />}/>
                            );
                          }}/>
                        <Divider/>
                      </Stack>
                    );
                    break;
                  case 'radio':
                    const {label: radioLabel, options: radioOptions} = config as OrderItemPropsRadioConfig;
                    return (
                      <Stack key={id} gap={2}>
                        <Controller
                          name={`itemProps.${itemPropIdx}`}
                          rules={{
                            validate: {
                              required: (value) => {
                                if (!value?.value) {
                                  return labels.errorRequiredField;
                                }
                              }
                            },
                          }}
                          control={control}
                          render={({field}) => {
                            return (
                              <OrderInput title={radioLabel} required>
                                <RadioGroup name={radioLabel} value={field.value?.value || ''}
                                            onChange={(e) => field.onChange({...field.value, value: e.target.value})}>
                                  {
                                    radioOptions.map(option => {
                                      return (
                                        <FormControlLabel key={option} control={<Radio value={option}/>}
                                                          label={option}/>
                                      );
                                    })
                                  }
                                </RadioGroup>
                              </OrderInput>
                            );
                          }}/>
                        <Divider/>
                      </Stack>
                    );
                    break;
                  case 'select':
                    const {label: selectLabel, options: selectOptions} = config as OrderItemPropsSelectConfig;
                    return (
                      <Stack key={id} gap={2}>
                        <Controller
                          name={`itemProps.${itemPropIdx}`}
                          rules={{
                            validate: {
                              required: (value) => {
                                if (!value?.value) {
                                  return labels.errorRequiredField;
                                }
                              }
                            },
                          }}
                          control={control}
                          render={({field, fieldState, formState}) => {
                            return (
                              <OrderInput title={selectLabel} required>
                                <Select
                                  displayEmpty
                                  sx={{flexGrow: 1}}
                                  value={field.value?.value || ''}
                                  onChange={(e) => field.onChange({...field.value, value: e.target.value})}
                                >
                                  {
                                    [
                                      <MenuItem key={'-'} value="" disabled>
                                        <em>{labels['please-choose']}</em>
                                      </MenuItem>,
                                      ...selectOptions.map(option => {
                                        return (
                                          <MenuItem key={option} value={option}>{option}</MenuItem>
                                        );
                                      })]
                                  }
                                </Select>
                              </OrderInput>
                            )
                          }}/>
                        <Divider/>
                      </Stack>
                    );
                    break;
                }
              })
            }
            <Stack direction={'row'}>
              {!itemConfig?.config?.hideDisassembly ? <Box sx={{flexGrow: 1}}>
                <Controller
                  name="disassembly"
                  control={control}
                  render={({field}) => {
                    return (
                      <FormControlLabel value="disassembly" control={<Checkbox checked={field.value}
                                                                               onChange={e => field.onChange(e.target.checked)}/>}
                                        label={labels['need-disassembly']}/>
                    );
                  }}/>
              </Box> : null}
              {!itemConfig?.config?.hideAssembly ? <Box sx={{flexGrow: 1}}>
                <Controller
                  name="assembly"
                  control={control}
                  render={({field}) => {
                    return (
                      <FormControlLabel control={<Checkbox checked={field.value}
                                                           onChange={e => field.onChange(e.target.checked)}/>}
                                        label={labels['need-assembly']}/>
                    );
                  }}/>
              </Box> : null}
              {!itemConfig?.config?.hideThrowAway ? <Box sx={{flexGrow: 1}}>
                <Controller
                  name="throwAway"
                  control={control}
                  render={({field}) => {
                    return (
                      <FormControlLabel control={<Checkbox checked={field.value}
                                                           onChange={e => field.onChange(e.target.checked)}/>}
                                        label={labels.needThrowAway}/>
                    );
                  }}/>
              </Box> : null}
            </Stack>
            <Divider/>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Typography sx={{flexGrow: 1}}>{labels['quantity']}</Typography>
              <Controller
                control={control}
                name={"quantity"}
                render={({field}) => {
                  return (
                    <NumberInput title={labels.quantity} {...field} min={1} onChange={(e, v) => {
                      const vn = Number(v);
                      if (isNaN(vn) || vn < 1) {
                        setValue('quantity', 1);
                      } else {
                        setValue('quantity', vn);
                      }
                    }}/>
                  );
                }}/>
            </Stack>
            <Controller
              name="comments"
              rules={{
                maxLength: {value: MAX_COMMENT_LEN, message: labels.commentsError}
              }}
              control={control}
              render={({field, fieldState}) => {
                return (
                  <TextField
                    multiline
                    rows={2}
                    {...field}
                    helperText={fieldState.invalid ? `${field.value.length}/${MAX_COMMENT_LEN} ${fieldState.error.message}` : field.value.length > COMMENT_LEN_WARN_LEN ? `${field.value.length}/${MAX_COMMENT_LEN}` : ''}
                    error={fieldState.invalid}
                    placeholder={itemConfig?.config?.commentsLabel || labels.comments}
                  />
                );
              }}/>
            <Stack gap={2}>
              <Typography sx={{fontSize: 14, opacity: 1}}>{labels.photosDisclaimer}</Typography>
              <Stack direction={"row"} gap={2} sx={{
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}>
                <Button
                  sx={{
                    display: allMedia.length >= MAX_PHOTOS_PER_ITEM ? 'none' : 'block',
                    width: '80px',
                    height: '80px',
                    borderRadius: 1,
                    backgroundColor: "#F6F1FE",
                    border: '1px dashed',
                  }}
                  component="label"
                  variant="text"
                  tabIndex={-1}
                  startIcon={null}
                >
                  <Stack sx={{alignItems: 'center'}} gap={1}>
                    <Typography sx={{fontSize: 10, textAlign: 'center'}}>{labels['add-photo']}</Typography>
                    <AttachFileIcon sx={{
                      fontSize: 25
                    }}/>
                  </Stack>
                  <MuiFileInput
                    sx={{
                      clip: 'rect(0 0 0 0)',
                      clipPath: 'inset(50%)',
                      height: 1,
                      overflow: 'hidden',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      whiteSpace: 'nowrap',
                      width: 1,
                    }}
                    size="small"
                    variant="outlined"
                    hideSizeText
                    multiple
                    onChange={onAddPhotos}
                    InputProps={{
                      inputProps: {
                        accept: 'image/*',
                      },
                    }}
                  />
                </Button>
                {hasCamera ? <Button
                  sx={{
                    display: allMedia.length >= MAX_PHOTOS_PER_ITEM ? 'none' : 'block',
                    width: '80px',
                    height: '80px',
                    borderRadius: 1,
                    backgroundColor: "#F6F1FE",
                    border: '1px dashed',
                  }}
                  component="label"
                  variant="text"
                  tabIndex={-1}
                  startIcon={null}
                >
                  <Stack sx={{alignItems: 'center'}} gap={1}>
                    <Typography sx={{fontSize: 10, textAlign: 'center'}}>{labels.camera}</Typography>
                    <PhotoCameraIcon sx={{
                      fontSize: 25
                    }}/>
                  </Stack>
                  <MuiFileInput
                    sx={{
                      clip: 'rect(0 0 0 0)',
                      clipPath: 'inset(50%)',
                      height: 1,
                      overflow: 'hidden',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      whiteSpace: 'nowrap',
                      width: 1,
                    }}
                    size="small"
                    variant="outlined"
                    hideSizeText
                    multiple
                    onChange={onAddPhotos}
                    InputProps={{
                      inputProps: {
                        accept: 'image/*',
                        capture: 'environment',
                      },
                      startAdornment: <PhotoCameraIcon sx={{
                        fontSize: 25
                      }}/>
                    }}
                  />
                </Button> : null}
                <Controller
                  name="newMedia"
                  control={control}
                  render={({field}) => {
                    return (
                      <PhotoGallery
                        progressIndex={uploadProgress?.[0]}
                        progress={uploadProgress?.[1]}
                        size={'80px'}
                        innerElement={(idx) => <IconButton size={'small'} onClick={() => {
                          removeAddedMedia(idx);
                        }} sx={{
                          position: 'absolute',
                          left: '5px',
                          top: '5px',
                          width: '30px',
                          height: '30px',
                          backgroundColor: "#EADCFF",
                        }}>
                          <DeleteForeverIcon/>
                        </IconButton>}
                        images={field.value.map((file, i) => ({
                          src: URL.createObjectURL(file),
                          width: 1000,
                          height: 1000,
                          title: title,
                          description: getValues('comments'),
                        }))}/>
                    )
                  }}/>
                <Controller
                  name="existingMedia"
                  control={control}
                  render={({field}) => {
                    return (<PhotoGallery
                        size={'80px'}
                        innerElement={(idx) => <IconButton size={'small'} onClick={() => {
                          removeExistingMedia(field.value[idx][0]);
                        }} sx={{
                          position: 'absolute',
                          left: '5px',
                          top: '5px',
                          width: '30px',
                          height: '30px',
                          backgroundColor: "#EADCFF",
                        }}>
                          <DeleteForeverIcon/>
                        </IconButton>}
                        images={field.value.map(([id, url]) => ({
                          src: url,
                          width: 1000,
                          height: 1000,
                          title: title,
                        }))}/>
                    )
                  }}/>
              </Stack>
            </Stack>
            {
              isEdit ?
                <Stack direction={"row"} gap={2}
                       sx={{marginTop: 2, marginBottom: 2}}
                >
                  {saveButton()}
                  {deleteButton()}
                </Stack> :
                saveButton()
            }
            {
              (!updateOrderItemMutation.isPending && !deleteOrderItemMutation.isPending) && (updateOrderItemMutation.isError || deleteOrderItemMutation.isError) ?
                <Box sx={{p: 0}}>
                  <Typography sx={{color: 'red', fontSize: 12}}>{labels.submitError}</Typography>
                </Box>
                : null
            }
          </Stack>
        </Box>
      </Stack>
    </Drawer>
  );
}