import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {TermsContent} from "./terms-content.tsx";

const labels ={
  iConfirm: 'אני מאשר/ת את תנאי השימוש',
  cancel: 'ביטול',
  scrollTillEnd: 'נא לגלול ולקרוא עד סוף תנאי השימוש',
  termsTitle: 'תנאי שימוש'
};

export function TermsLightbox({open, onOk, onCancel, onClose}: {open: boolean, onOk: () => void, onCancel: () => void, onClose: () => void}) {
  const [didScrollEnough, setDidScrollEnough] = React.useState(false);
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const thirtyPercentFromEnd = (target.clientHeight + target.scrollTop) > target.scrollHeight * 0.7;
    if (thirtyPercentFromEnd) {
      if (!didScrollEnough) {
        setDidScrollEnough(true);
      }
    }
  }
  const handleClose = () => {
    setDidScrollEnough(false);
    onClose();
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle>{labels.termsTitle}</DialogTitle>
        <DialogContent dividers={true} onScroll={onScroll}>
          <DialogContentText
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TermsContent />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack sx={{flexGrow: 1}}>
            <Box sx={{p: 1}}>
              <Typography>{labels.scrollTillEnd}</Typography>
            </Box>
            <Stack direction={'row'} gap={2}>
              <Button disabled={!didScrollEnough} sx={{flexGrow: 1}} onClick={onOk}>{labels.iConfirm}</Button>
              <Button variant={'outlined'} onClick={onCancel}>{labels.cancel}</Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
