import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import {debounce} from '@mui/material/utils';
import {FormAddress} from "@tarimli-mono/api/src/shared/client-types";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = {current: null};

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

const formAddressToStructured = (formAddress: FormAddress): PlaceType => ({
  description: formAddress.description,
  structured_formatting: {
    main_text: formAddress.mainText,
    secondary_text: formAddress.secondaryText,
  },
});

const structuredToFormAddress = (structured: PlaceType): FormAddress => ({
  description: structured.description,
  mainText: structured.structured_formatting.main_text,
  secondaryText: structured.structured_formatting.secondary_text,
});

export default function AddressAutocomplete({onChange, initialValue, onBlur, onFocus, disabled}: {
  onChange: (value: FormAddress | null) => void,
  initialValue: FormAddress | null,
  onBlur?: () => void,
  onFocus?: () => void,
  disabled?: boolean,
}) {
  const structuredInitialValue = initialValue ? formAddressToStructured(initialValue) : null;
  const [value, setValue] = React.useState<PlaceType | null>(structuredInitialValue);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>(structuredInitialValue ? [structuredInitialValue] : []);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&region=IL&language=he&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      disabled={disabled}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      openOnFocus
      includeInputInList
      filterSelectedOptions
      noOptionsText={'נא להזין כתובת'}
      value={value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue ? structuredToFormAddress(newValue) : null);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} fullWidth/>
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );
        const {key, ...restProps} = props as any;
        return (
          <li key={key} {...restProps}>
            <Grid container alignItems="center">
              {/*<Grid item sx={{ display: 'flex', width: 44 }}>*/}
              {/*  <LocationOnIcon sx={{ color: 'text.secondary' }} />*/}
              {/*</Grid>*/}
              <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{fontWeight: part.highlight ? 'bold' : 'regular'}}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
