import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {ClientCustomerOwnOrder} from "../../model/client-order.ts";
import {SelectedItem} from "../SelectedItem.tsx";
import {SearchItem} from "../SearchItem.tsx";
import {IconRoom} from "../icons/IconRoom.tsx";
import {IconFurniture} from "../icons/IconFurniture.tsx";

const labels = {
  rooms: 'חדרים',
  categories: 'קטגוריות',
  itemsIChose:() => <span>פריטים שבחרתי<br />(לחצו על פריט לשינוי)</span>,
}

export const SelectedItems = ({order}: {
  order: ClientCustomerOwnOrder,
}) => {
  return (
    <Stack gap={2}>
      {order.content.items?.length > 0 ?
        <Typography fontSize={22} textAlign={'center'} sx={{fontWeight: 600}}>{labels.itemsIChose()}</Typography>
        : null}
      {(order.content.sortedItems || []).map((item) => {
        return (<SelectedItem
          key={item.id}
          item={item}
          orderId={order.id}
        />)
      })}
    </Stack>
  );
}

export const AddItems = ({orderId, onClickRooms, onClickCategories}: { orderId: number, onClickRooms: () => void, onClickCategories: () => void }) => {
  return (
    <Box sx={{
      backgroundColor: "#F5EDFF",
      borderRadius: 1,
      padding: 2
    }}>
      <Stack gap={1}>
        <SearchItem orderId={orderId}/>
        <Stack direction={"row"} gap={1}>
          <Box
            onClick={onClickRooms}
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              backgroundColor: "white",
              padding: 1,
              borderRadius: 1,
              textAlign: "center",
            }}>
            <IconRoom sx={{fontSize: 30, color: '#AD7AFF'}}/>
            <Typography fontSize={20}>{labels.rooms}</Typography>
          </Box>
          <Box
            onClick={onClickCategories}
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              backgroundColor: "white",
              padding: 1,
              borderRadius: 1,
              textAlign: "center",
            }}>
            <IconFurniture sx={{fontSize: 30, color: '#AD7AFF'}}/>
            <Typography fontSize={20}>{labels.categories}</Typography>
          </Box>
        </Stack>

      </Stack>
    </Box>
  );
}
