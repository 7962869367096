import { createFileRoute, notFound, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/order/$orderId/_order/draft')({
  component: () => null,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'draft') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
    throw redirect({
      to: '/order/$orderId/depart',
      params: {
        // @ts-expect-error
        orderId: String(order.id),
      },
    });
  },
});