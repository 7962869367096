import dayjs from 'dayjs';
import {ICCustomerContract, ICContractStatus, ICPartnerContract} from "@tarimli-mono/api/src/shared/client-types";

export class ClientCustomerContract {
  id: DbId;
  orderId: DbId;
  offerId: DbId;
  status: ICContractStatus;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  paymentsNum: number;
  finalAmount: number;
  readonly createdAt: dayjs.Dayjs;
  readonly updatedAt: dayjs.Dayjs;

  constructor(contract: ICCustomerContract) {
    this.id = contract.id;
    this.orderId = contract.orderId;
    this.offerId = contract.offerId;
    this.status = contract.status;
    this.firstName = contract.firstName;
    this.lastName = contract.lastName;
    this.phone = contract.phone;
    this.email = contract.email;
    this.paymentsNum = contract.paymentsNum;
    this.finalAmount = contract.finalAmount;
    this.createdAt = dayjs(contract.createdAt);
    this.updatedAt = dayjs(contract.updatedAt);
  }
}

export class ClientPartnerContract {
  id: DbId;
  orderId: DbId;
  offerId: DbId;
  status: ICContractStatus;
  finalAmount: number;
  readonly createdAt: dayjs.Dayjs;
  readonly updatedAt: dayjs.Dayjs;

  constructor(contract: ICPartnerContract) {
    this.id = contract.id;
    this.orderId = contract.orderId;
    this.offerId = contract.offerId;
    this.status = contract.status;
    this.finalAmount = contract.finalAmount;
    this.createdAt = dayjs(contract.createdAt);
    this.updatedAt = dayjs(contract.updatedAt);
  }
}