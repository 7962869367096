import {createFileRoute, redirect} from "@tanstack/react-router";
import {CreateNewOrderScreen} from "../../components/orders/paid.tsx";

export const Route = createFileRoute('/_auth/orders')({
  component: OrdersScreen,
  beforeLoad: ({context}) => {
    if (context.auth.customer && !context.auth.customer.isRegistered) {
      // @ts-expect-error
      throw redirect({
        to: '/register',
      });
    }
    const latestOrder = context.auth.customer.latestOrder;
    if (latestOrder) {
      const orderId = String(latestOrder.id);
      switch (latestOrder.status) {
        case 'draft':
          throw redirect({
            to: '/order/$orderId/draft',
            params: {
              /* @ts-expect-error False alarm */
              orderId,
            },
          });
        case 'submitted':
          throw redirect({
            to: '/order/$orderId/submitted',
            params: {
              // @ts-expect-error
              orderId,
            },
          });
        case 'open':
          throw redirect({
            to: '/order/$orderId/open',
            params: {
              // @ts-expect-error
              orderId,
            },
          });
        case 'closed':
          throw redirect({
            to: '/order/$orderId/closed',
            params: {
              // @ts-expect-error
              orderId,
            },
          });
        case 'completed':
          throw redirect({
            to: '/order/$orderId/completed',
            params: {
              // @ts-expect-error
              orderId,
            },
          });
          return;
        default:
          throw new Error('Unknown order status');
      }
    }
  },
});

export function OrdersScreen() {
  return <CreateNewOrderScreen />;;
}