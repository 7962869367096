import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import AdobePdfClient from "./pdf-client.tsx";
import {ClientCustomerOwnOrder} from "../../model/client-order.ts";
import {AppBar, IconButton, Portal, Toolbar} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const labels = {
  pleaseConfirmOrder: 'אנא אשרו אם פרטי ההובלה מדוייקים'
}

export const PdfLightbox = ({order}: { order: ClientCustomerOwnOrder }) => {
  useEffect(() => {
    const client = new AdobePdfClient(order);
    client.ready().then(() => {
      client.previewFile('pdf-container', {
        // embedMode: 'LIGHT_BOX',
        embedMode: "FULL_WINDOW",
        showDownloadPDF: true,
        showZoomControl: true

      });
    });
  }, []);

  return null;
}

export default function FullScreenDialog({children}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            {labels.pleaseConfirmOrder}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}