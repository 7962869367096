import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {authGet, authPost, authPostWithToken} from "./api-call.ts";
import {
  ApiAcceptOfferResponse,
  ApiConfirmOrderResponse,
  ApiCreateOrderResponse,
  ApiDeleteDraftOrderResponse,
  ApiDeleteOrderItemItemResponse, ApiGetPdfUrl,
  ApiSubmitOrderResponse,
  ApiUndoSubmitOrderResponse,
  ApiUpdateOrderItemResponse,
  ApiUpdateOrderResponse
} from "@tarimli-mono/api/src/shared/api-types.ts";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import {OrderItemProps} from "@tarimli-mono/api/src/shared/order-items-types.ts";
import {OrderReview} from "@tarimli-mono/api/src/shared/client-types.ts";
import dayjs from "dayjs";
import {config} from "../config.ts";
import {useCustomer} from "./auth.tsx";
import {ClientCustomerOwnOrder} from "../model/client-order.ts";
import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";

export const useQueryPdfUrl = (orderId: number) => {
  return useQuery({
    queryKey: ['pdf-link', orderId],
    queryFn: async () => {
      return authGet<ApiGetPdfUrl>(`/order-pdf-link?orderId=${orderId}`);
    },
  });
}

export const useMutationCreateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return authPost<ApiCreateOrderResponse>('/create-draft-order');
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

export const useMutationUpdateOrder = (stageIndex: number, content: OrderContent) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId, updatedContent}: { orderId: DbId, updatedContent: Partial<OrderContentValues> }) => {
      return authPost<ApiUpdateOrderResponse>('/update-order', {
        orderId,
        content : {
          ...updatedContent,
          stageIndex: content.entries.stageIndex.value > stageIndex ? content.entries.stageIndex.value : stageIndex,
        }
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
    onError: (error) => {
      console.error('updateOrder error', error);
    }
  });
};

export const uploadFile = async (uploadToken: string, orderId: number, media: File, setUploadProgress: (progress: number) => void) => {
  const formData = new FormData();
  formData.append('file', media);
  const mediaId = `${dayjs().format('YYYYMMDD-HHmmss-SSS')}-${Math.round(Math.random() * 1E9)}`;
  const url = `${config().uploadFilesUrl}?token=${uploadToken}&path=order-${orderId}-${mediaId}`;
  const data = await authPostWithToken(
    url,
    formData,
    uploadToken,
    {'Content-Type': 'multipart/form-data'},
    undefined,
    setUploadProgress,
  );
  console.log('POST upload-media', data);
  return data as {
    success: boolean,
    error?: string,
    bucketPath?: string,
    originalName?: string,
    url?: string,
  };
}

export const updateOrderItem = async (
  uploadToken: string,
  orderId: number,
  orderItemId: string | null,
  {title, quantity, assembly, disassembly, throwAway, itemProps, comments, media}: {
    title: string,
    quantity: number,
    assembly: boolean,
    disassembly: boolean,
    throwAway: boolean,
    itemProps: OrderItemProps[],
    comments: string,
    media: string[],
  },
  newMedia: any[],
  setUploadProgress: ([index, progress]: [number, number]) => void,
): Promise<ApiUpdateOrderItemResponse> => {
  const newMediaIds = [];
  for (let i = 0; i < newMedia.length; i++) {
    const m = newMedia[i];
    if (m instanceof File) {
      const result = await uploadFile(uploadToken, orderId, m, (p: number) => setUploadProgress([i, p]));
      newMediaIds.push(result.bucketPath);
    }
  }
  const postBody = {
    orderId,
    orderItemId,
    newMediaIds,
    item: {
      title,
      quantity,
      assembly,
      disassembly,
      throwAway,
      itemProps,
      comments,
      media,
    }
  }
  const data = await authPost('/update-order-item', postBody, {}, setUploadProgress);
  console.log('POST update-order-item', data);
  return data as ApiUpdateOrderItemResponse;
}

export const useMutationUpdateOrderItem = () => {
  const customer = useCustomer();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
                   setUploadProgress,
                   orderId,
                   orderItemId,
                   title,
                   quantity,
                   assembly,
                   disassembly,
                   throwAway,
                   itemProps,
                   comments,
                   media,
                   newMedia,
                 }: {
      setUploadProgress: ([index, progress]: [number, number]) => void,
      orderId: DbId,
      orderItemId: string | null,
      title: string,
      quantity: number,
      assembly: boolean,
      disassembly: boolean,
      throwAway: boolean,
      itemProps: OrderItemProps[],
      comments: string,
      media: string[],
      newMedia: any[]
    }) => {
      return updateOrderItem(
        customer.orderUploadToken,
        orderId,
        orderItemId,
        {
          title,
          quantity,
          assembly,
          disassembly,
          throwAway,
          itemProps,
          comments,
          media
        },
        newMedia,
        setUploadProgress,
      );
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

export const useMutationDeleteOrderItem = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId, orderItemId}: { orderId: DbId, orderItemId: string }) => {
      return authPost<ApiDeleteOrderItemItemResponse>('/delete-order-item', {orderId, orderItemId});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

export const useMutationSubmitOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId}: {orderId: DbId}) => {
      return authPost<ApiSubmitOrderResponse>('/submit-order', {orderId});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
    onError: (error) => {
      console.error('submitOrder error', error);
    }
  });
}

export const useMutationConfirmSubmittedOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId}: { orderId: DbId }) => {
      return authPost<ApiConfirmOrderResponse>('/confirm-submitted-order', {orderId});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
    onError: (error) => {
      console.error('submitOrder error', error);
    }
  });
}

export const useMutationUndoSubmitOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId}: { orderId: DbId }) => {
      return authPost<ApiUndoSubmitOrderResponse>('/undo-submit-order', {orderId});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
    onError: (error) => {
      console.error('submitOrder error', error);
    }
  });
}

export const useMutationDeleteDraftOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId}: { orderId: DbId }) => {
      return authPost<ApiDeleteDraftOrderResponse>('/delete-draft-order', {orderId});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

export const useMutationAcceptOffer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({offerId}: { offerId: number }) => {
      return authPost<ApiAcceptOfferResponse>('/accept-offer', {offerId});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

export const useMutationRateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({orderId, review}: { orderId: DbId, review: Partial<OrderReview> }) => {
      return authPost('/rate-order', {orderId, review});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}
