console.log(import.meta.env);

const apiProxyHostname = import.meta.env.VITE_USE_API_PROXY_HOSTNAME;
let apiPrefix = import.meta.env.VITE_API_PREFIX;
const isProxyHostname = window.location.hostname === apiProxyHostname;
if (isProxyHostname) {
  apiPrefix = import.meta.env.VITE_API_PROXY_PREFIX;
}

export const config = () => {
  return {
    apiPrefix,
    pdfClientId: isProxyHostname ? import.meta.env.VITE_PDF_SERVICES_CLIENT_ID_PROXY : import.meta.env.VITE_PDF_SERVICES_CLIENT_ID,
    uploadFilesUrl: import.meta.env.VITE_UPLOAD_FILES_URL,
    registerPartnerMaxFileSizeMb: import.meta.env.VITE_MAX_FILE_SIZE_MB,
    customerServiceLink: import.meta.env.VITE_CUSTOMER_SERVICE_LINK,
    totalOrderStages: 6,
    maxBitNisAmount: 3600,
    minOffersCount: 1,
    firebase: {
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
    }
  }
}
console.log('config', config());
