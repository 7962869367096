import {useMutation, useQueryClient} from '@tanstack/react-query';
import {authPost} from "./api-call.ts";
import {ApiCreateOrderResponse} from "@tarimli-mono/api/src/shared/api-types.ts";
import {useAppState} from "./state.ts";

export const useMutationUpdateUser = () => {
  const appState = useAppState();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({name}: { name: string }) => {
      return authPost('/update-user', {name});
    },
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(['current-user'], data);
      }
    },
  });
}

export const useMutationUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({firstName, lastName}: { firstName: string, lastName: string}) => {
      return authPost<ApiCreateOrderResponse>('/update-customer', {firstName, lastName});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}