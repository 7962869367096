import {createFileRoute, notFound, redirect, useRouter} from "@tanstack/react-router";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {FormProvider, useForm} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import {EditOrderHeader, EditStageFooter, EditStageHeader} from "../../../../../components/stage-elements.tsx";
import {useCustomer} from "../../../../../services/auth.tsx";
import {config} from "../../../../../config.ts";
import { OrderForm } from "../../../../../components/forms/order-form.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/_draft/items')({
  component: OrderItemsScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'draft') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
});

const labels = {
  'back': 'חזרה',
  'content': 'תכולה',
  'rooms': 'חדרים',
  'categories': 'קטגוריות',
  'choose-by': 'בחירה לפי',
  oneMoreMoment: 'שניה וסיימנו',
}

export function OrderItemsScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const router = useRouter();
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const formMethods = useForm<OrderContentValues>({
    mode: 'all',
    values: order.content.formValues(),
  });
  const itemsCount = order?.content.items?.length || 0;
  
  const onClickNext = () => {
    router.navigate({
      to: `/order/$orderId/boxes`,
      params: {
        /* @ts-expect-error */
        orderId: String(order.id),
      },
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  return (
    <FormProvider {...formMethods}>
      <form autoComplete={'off'}>
    <Stack gap={2}>
      <EditOrderHeader order={order} stage={4} totalStages={config().totalOrderStages}/>
      <Stack gap={0} sx={{
        borderRadius: 1,
        overflow: "hidden",
        backgroundColor: "white"
      }}>
        <EditStageHeader stage={4} totalStages={5} title={labels['content']}/>
        <Box sx={{padding: 2}}>
          <OrderForm content={order.content} stage={'items'}/>
        </Box>
        <EditStageFooter loading={false} disabled={itemsCount === 0}
                         hasErrors={false}
                         onPressDone={onClickNext} />
      </Stack>
    </Stack>
      </form>
    </FormProvider>
  );
}

