import {createFileRoute, Navigate} from '@tanstack/react-router'
import {useAuth} from "../services/auth.tsx";

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  const auth = useAuth();
  if (auth.isAdmin) {
    /* @ts-expect-error */
    return <Navigate to="/admin"/>
  } else if (auth.isPartner) {
    /* @ts-expect-error */
    return <Navigate to="/partner"/>
  } else if (auth.isCustomer) {
    if (auth.customer.isRegistered) {
      /* @ts-expect-error */
      return <Navigate to="/orders"/>
    } else {
      /* @ts-expect-error */
      return <Navigate to="/register"/>
    }
  } else {
    /* @ts-expect-error */
    return <Navigate to="/login"/>
  }
}
