import {createRootRouteWithContext, Outlet, redirect} from '@tanstack/react-router';
import type {QueryClient} from "@tanstack/react-query";
import Box from '@mui/material/Box';
import {AuthContext} from "../services/auth.tsx";
import {authGetWithToken} from "../services/api-call.ts";
import {config} from "../config.ts";
import {Footer} from "../components/footer.tsx";
import { useRef } from 'react';

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient,
  auth: AuthContext,
}>()({
  beforeLoad: async ({context, search, navigate, params, buildLocation, location}) => {;
    try {
      const {token} = search as any;
      const maybeToken = typeof token === 'string' ? token : null
      if (maybeToken) {
        const data = await authGetWithToken(`${config().apiPrefix}/current-user`, maybeToken);
        if (data && (data.clientCustomer || data.clientPartner)) {
          context.queryClient.setQueryData(['current-user'], data);
          context.auth.loginUser(maybeToken);
          const newSearch: any = {
            /* @ts-expect-error */
            ...search,
          };
          delete newSearch.token;
          throw redirect({
            to: location.pathname,
            replace: true,
            search: newSearch,
            hash: location.hash,
          });
        } else {
          context.auth.logoutUser();
        }
      }
    } catch(e) {
      if (e.isRedirect) {
        throw e;
      } else {
        /* @ts-expect-error */
        throw redirect({
          to: '/login',
        });
      }
    }
  },
  component: RootComponent,
})

function RootComponent() {
  const pdfPortalRef = useRef(null);
  (window as any).pdfPortalRef = pdfPortalRef;
  return (
    <>
      <Box sx={{
        backgroundColor: '#C9C0FF',
      }}>
        <Box>
          <Outlet/>
          {/*<Box id="pdf-portal" ref={pdfPortalRef}></Box>*/}
          <Footer/>
        </Box>
      </Box>
      {/*<Debug/>*/}
      {/*<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />*/}
      {/*<TanStackRouterDevtools initialIsOpen={false} position="bottom-left" />*/}
    </>
  );
};
