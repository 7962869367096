import {createFileRoute, Outlet} from '@tanstack/react-router'
import Stack from '@mui/material/Stack';

export const Route = createFileRoute('/_auth/order/$orderId/_order')({
  component: OrderLayout,
})

function OrderLayout() {
  return (
    <Stack margin={2} gap={2}>
      <Outlet/>
    </Stack>
  );
}
