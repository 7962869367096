import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {SearchItem} from "./SearchItem.tsx";

const labels = {
  'back': 'חזרה',
  'add-items': 'הוספת פריטים',
  moreItems: 'פריטים נוספים',
}

export const ItemsHeader = ({orderId, onClickBack, showSearch, title, topTitle, icon}: {
  orderId: number,
  onClickBack: () => void,
  showSearch: boolean,
  title: string,
  topTitle: string,
  icon?: any,
}) => {
  return (
    <Stack gap={2}>
      <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'center'}} gap={1}>
        <Typography fontWeight={600} fontSize={20}>{labels['add-items']}</Typography>
      </Stack>
      {showSearch ? <SearchItem orderId={orderId} /> : null}
      <Stack direction={'row'} sx={{alignItems: 'center'}} gap={2}>
        <Button onClick={onClickBack} variant={'contained'} sx={{}} size={'medium'}>
          <Stack direction={"row"} gap={0} alignItems={"center"}>
            <ChevronRightIcon sx={{fontSize: 20}}/>
            <Typography>{labels.moreItems}</Typography>
          </Stack>
        </Button>
        {icon || null}
        <Stack direction={'row'} alignItems={'center'} gap={0}>
        {topTitle ? <>
          <Typography fontWeight={"600"} fontSize={18}>{topTitle}</Typography>
          <ChevronLeftIcon/>
        </> : null}
        <Typography fontWeight={"600"} fontSize={18}>{title}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
