import {createFileRoute, notFound, redirect, useRouter} from "@tanstack/react-router";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useForm} from "react-hook-form";
import {EditStageHeader} from "../../../../components/stage-elements.tsx";
import {useCustomer} from "../../../../services/auth.tsx";
import {useMutationConfirmSubmittedOrder, useMutationUndoSubmitOrder} from "../../../../services/api-order.ts";
import {V1_OrderSummary} from "../../../../components/forms/v1-summary.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/confirm')({
  component: ConfirmOrderScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'submitted') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
});

const labels = {
  'stage-title': 'אישור ההובלה שלך',
  submitError: 'סליחה קרתה תקלה 😔 נא לנסות שוב. אם הבעיה נמשכת נא ליצור קשר עם שירות הלקוחות שלנו.',
  change: 'שינוי',
  confirm: 'אישור',
}

export function ConfirmOrderScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const mutationConfirmSubmittedOrder = useMutationConfirmSubmittedOrder();
  const mutationUndoSubmitOrder = useMutationUndoSubmitOrder();
  const router = useRouter();
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors, isValid, isSubmitting},
  } = useForm<{additionalOffers: string[]}>({
    defaultValues: {
      additionalOffers: order?.content?.entries?.additionalOffers.value || [],
    },
  })

  const onPressConfirm = async () => {
    await mutationConfirmSubmittedOrder.mutateAsync({orderId: order.id} as any);
    setTimeout(async () => {
      location.href = '/orders';
    }, 200);
  }

  const onPressUndo = async () => {
    await mutationUndoSubmitOrder.mutateAsync({orderId: order.id} as any);
    setTimeout(async () => {
      location.href = '/orders';
    }, 200);
  }
  return (
    <Stack gap={2}>
      <Stack gap={0} sx={{
        borderRadius: 1,
        overflow: "hidden",
        backgroundColor: "white"
      }}>
        <EditStageHeader stage={5} totalStages={5} title={labels['stage-title']}/>
        <Stack sx={{padding: 2}} gap={2}>
          <V1_OrderSummary orderContent={order.content} toConfirm={true} />
          <Divider/>
          <Stack gap={2}>
            <Stack direction={'row'} gap={2}>
              <LoadingButton
                loading={mutationConfirmSubmittedOrder.isPending}
                onClick={handleSubmit(onPressConfirm)}
                disabled={mutationUndoSubmitOrder.isPending}
                sx={{
                  flexGrow: 1,
                }}
                variant={'contained'}
              >
                {labels.confirm}
              </LoadingButton>
              <LoadingButton
                loading={mutationUndoSubmitOrder.isPending}
                onClick={handleSubmit(onPressUndo)}
                disabled={mutationConfirmSubmittedOrder.isPending}
                sx={{
                  flexGrow: 1,
                }}
                variant={'outlined'}
              >
                {labels.change}
              </LoadingButton>
            </Stack>
            {mutationUndoSubmitOrder.isError || mutationConfirmSubmittedOrder.isError ? (
              <Typography color={'error'}>{labels.submitError}</Typography>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
