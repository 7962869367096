import Stack from '@mui/material/Stack';
import {createFileRoute, Link} from '@tanstack/react-router'
import {Box} from '@mui/material';
import TarimliLogo from "/tarimli-logo.svg";
import {LoginBox} from "../components/otp.tsx";
import {useAuth} from "../services/auth.tsx";

export const Route = createFileRoute('/login')({
  component: LoginScreen,
})

const labels = {
  'partner-login': 'כניסה לספקים',
}

export default function LoginScreen() {
  const auth = useAuth();
  
  const onSuccess = (accessToken: string, adminToken?: string) => {
    auth.loginUser(accessToken);
    if (adminToken) {
      auth.loginAdmin(adminToken);
      window.location.href = '/admin';
    } else {
      window.location.href = '/orders';
    }
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        paddingTop: 4
      }}
    >
      <Stack spacing={4}>
        <img src={TarimliLogo} height={60}/>
        <Box sx={{
          backgroundColor: 'white',
          borderRadius: 1,
          padding: 2,
          width: 350,
          maxWidth: '90vw',
        }}>
          <LoginBox onSuccess={onSuccess} />
        </Box>
        <Box sx={{alignSelf: 'center'}}>
          {/* @ts-expect-error */}
          <Link to="/partner-login">{labels['partner-login']}</Link>
        </Box>
      </Stack>
    </Box>
  )
}

