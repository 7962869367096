import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const labels = {
  orderNumber: (num: number) =>  `הזמנה #${num}`,
}

export function OrderNumber({orderId, sx}: {orderId: number, sx?: any}) {
    return (
        <Box sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          padding: '2px 8px',
          borderRadius: '5px',
          backgroundColor: '#F5F5F5',
          whiteSpace: 'nowrap',
          ...(sx || {}),
        }}>
          <Typography variant="subtitle2">{labels.orderNumber(orderId)}</Typography>
        </Box>
    );
}