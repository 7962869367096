import * as Sentry from "@sentry/react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {createFileRoute, useRouter} from '@tanstack/react-router'
import LoadingButton from '@mui/lab/LoadingButton';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {config} from "../../config.ts";
import {useCustomer} from "../../services/auth.tsx";
import {useStartCheckoutMutation} from "../../services/api-payment.ts";
import {PayerDetails, PaymentForm} from "../../components/payer-details.tsx";

export const Route = createFileRoute('/_auth/offer/$offerId/checkout')({
  component: CheckoutScreen
});

const labels = {
  offer: () => <Typography sx={{fontWeight: 600, fontSize: 30, textAlign: 'center'}}>החיוב יבוצע לאחר אישורך<br/>כי
    ההובלה הסתיימה בהצלחה</Typography>,
  cancel: 'ביטול',
  next: 'תשלום',
  nis: '₪',
  amountToPayTotal: 'סכום',
  amountToPayToday: 'מקדמה לתשלום היום',
  amountToPayLater: 'תשלום נוסף לאחר ההובלה',
  disclaimer: 'לאחר ההובלה תתבקשו לאשר את החיוב.',
  payerDetails: 'פרטי המשלם',
  firstName: 'שם פרטי',
  lastName: 'שם משפחה',
  ssn: 'מספר זהות',
  phone: 'טלפון נייד (קידומת 05X)',
  address: 'כתובת',
  email: 'אימייל (לקבלת חשבונית)',
  errorFirstName: 'נא למלא שם פרטי',
  errorLastName: 'נא למלא שם משפחה',
  errorSsn: 'נא למלא מספר זהות',
  errorPhone: 'נא למלא טלפון',
  errorAddress: 'נא למלא כתובת',
  errorEmail: 'נא למלא אימייל',
  errorInvalidPhone: 'נא למלא מספר בן 10 ספרות בצורת: 05XXXXXXXX',
  errorInvalidEmail: 'נא למלא אימייל תקין',
  phoneInstructions: 'נא למלא מספר בן 10 ספרות בצורת: 05XXXXXXXX',
  retry: 'נסו שוב',
  back: 'חזרה',
  paymentsNum: 'מספר תשלומים',
  saveCardDisclaimer: 'אני מאשר שפרטי הכרטיס שלי יישמר לחיוב לאחר סיום ההובלה.',
  serverError: () => <><Typography sx={{color: 'red'}}> סליחה תקלה 😕 אפשר לנסות שוב<br/>אם לא עוזר אפשר ליצור קשר
    עם <br/><a
      href={config().customerServiceLink}>שירות הלקוחות שלנו בוואצאפ: 051-520-7969</a></Typography></>,
}

export function CheckoutScreen() {
  const customer = useCustomer();
  const router = useRouter();
  const routeParams = Route.useParams() as { offerId: string };
  const offerId = routeParams.offerId;
  const startCheckoutMutation = useStartCheckoutMutation();
  const order = customer.orders.find((order) => order.submittedOffers.find((offer) => offer.id === Number(offerId)));
  const offer = order.submittedOffers.find((offer) => offer.id === Number(offerId)) || null;
  const [isNavigating, setIsNavigating] = useState(false);
  useEffect(() => {
    if (startCheckoutMutation.error) {
      setError("root.serverError", {
        type: "server-error",
      });
      Sentry.captureException(startCheckoutMutation.error);
    }
  }, [startCheckoutMutation.error]);
  const formMethods = useForm<PaymentForm>({
    mode: 'all',
    values: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.formattedPhone,
      email: customer.email || '',
      paymentsNum: 1,
      saveCard: false,
    },
  });
  const {
    setError,
    handleSubmit,
    clearErrors,
    trigger,
    formState,
  } = formMethods;
  const {errors, isValid, isSubmitting} = formState;
  const canSubmit = isValid && Object.keys(errors).length === 0;
  const onClickCancel = () => {
    router.history.back();
  }
  const onClickRetry = () => {
    clearErrors();
    trigger();
  }
  const onClickSubmit: SubmitHandler<PaymentForm> = async (data: PaymentForm) => {
    const result = await startCheckoutMutation.mutateAsync({
      offerId: String(offer.id),
      ...data,
    });
    if (result.paymentUrl) {
      setIsNavigating(true);
      window.location.href = result.paymentUrl;
    } else {
      setError("root.serverError", {
        type: "server-error",
      });
    }
  }
  return (
    <Stack gap={1} sx={{
      padding: 2,
    }}>
      {labels['offer']()}
      <Stack gap={2} sx={{
        padding: 2,
        backgroundColor: 'white',
        borderRadius: 1,
      }}>
        <Stack direction={'row'} gap={2} sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography sx={{flexGrow: 1}}>{labels['amountToPayTotal']}</Typography>
          <Typography sx={{fontSize: 20}}>{offer.amount}{labels['nis']}</Typography>
        </Stack>
      </Stack>

      <FormProvider {...formMethods}>
        <PayerDetails askToSaveCard={true} askPaymentsNum={true} />
      </FormProvider>
      {errors.root?.serverError &&
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          {labels['serverError']()}
          <Button onClick={onClickRetry}>{labels.retry}</Button>
        </Stack>
      }

      {!errors.root?.serverError ? <Stack direction={"row"} gap={2} sx={{
        alignItems: 'flex-start',
        borderRadius: 1,
        padding: 2,
        backgroundColor: '#F5EDFF',
      }}>
        <Button disabled={isSubmitting || isNavigating} onClick={onClickCancel} variant={'outlined'}>
          {labels['back']}
        </Button>
        <Stack sx={{flexGrow: 1}}>
          <LoadingButton
            onClick={handleSubmit(onClickSubmit)}
            disabled={!canSubmit || isSubmitting || isNavigating}
            loading={startCheckoutMutation.isPending}
            sx={{
              flexGrow: 1,
            }}
            variant={'contained'}
          >
            {labels['next']}
          </LoadingButton>
        </Stack>

      </Stack> : null}
    </Stack>
  );
}
