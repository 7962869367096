import BitLogo from "/bit-logo.svg";
import {createFileRoute, notFound, redirect, useRouter} from "@tanstack/react-router";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {useState} from "react";
import numbro from "numbro";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import {useCustomer} from "../../../../services/auth.tsx";
import {useMutationChargeCard, useMutationChargeToken} from "../../../../services/api-payment.ts";
import {PayerDetails, PaymentForm} from "../../../../components/payer-details.tsx";
import {config} from "../../../../config.ts";
import {OrderBox} from "../../../../components/orders/orders-elements.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/pay')({
  component: CompleteOrderScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'closed') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
});

const labels = {
  weHappyForYou: 'איזה יופי שהכל הסתיים בהצלחה 😎',
  amountToPay: 'סכום לתשלום',
  howDoYouPay: 'איך תרצו לשלם?',
  creditCard: 'אשראי',
  bit: 'ביט',
  paymentsNum: 'מספר תשלומים',
  payWithOtherCard: 'לתשלום באשראי אחר',
  payWithCard: 'לתשלום באשראי',
  payWithCardNum: (suffix: string) => `לתשלום באשראי מסתיים ב- ${suffix}`,
  payWithBit: 'לתשלום בביט',
  genericError: 'סליחה תקלה :( אפשר ליצור קשר עם שירות הלקוחות שלנו',
}

export function CompleteOrderScreen() {
  const router = useRouter();
  const customer = useCustomer();
  const routeParams = Route.useParams() as {orderId: string};
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const contract = customer.findContractForOrderId(order.id);
  const [paymentsNum, setPaymentsNum] = useState(contract.paymentsNum || 1);
  const mutationChargeToken = useMutationChargeToken();
  const mutationChargeCard = useMutationChargeCard();
  const [isLoading, setIsLoading] = useState<'cardCharge' | 'tokenCharge' | 'bitCharge' | null>(null);

  const formMethods = useForm<PaymentForm>({
    mode: 'all',
    values: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: contract.phone,
      email: contract.email,
      paymentsNum: contract.paymentsNum,
      saveCard: false,
    },
  });
  const {
    setError,
    handleSubmit,
    clearErrors,
    trigger,
    formState,
  } = formMethods;
  const {errors, isValid, isSubmitting} = formState;

  const allowCardToken = !!customer.cardSuffix;
  const allowBit = contract.finalAmount <= config().maxBitNisAmount;
  const formattedAmount = numbro(contract.finalAmount).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  });
  const chargeCard = async (type: 'cardCharge' | 'bitCharge', data: PaymentForm) => {
    setIsLoading(type);
    try {
      const result = await mutationChargeCard.mutateAsync({
        type,
        contractId: contract.id,
        ...data
      });
      if (result.paymentUrl) {
        window.location.href = result.paymentUrl;
      } else {
        setError('root.serverError', {
          type: 'server-error',
        });
      }
    } finally {
      setIsLoading(null);
    }
  }
  const onClickCreditCardWithToken = handleSubmit(async (data) => {
    setIsLoading('tokenCharge');
    try {
      await mutationChargeToken.mutateAsync({
        contractId: contract.id,
        ...data,
      });
      setTimeout(() => {
        // @ts-expect-error
        router.navigate({
          to: `/orders`,
          resetScroll: true,
        });
      }, 50);
    } catch(e) {
      setError('root.serverError', {
        type: 'server-error',
      });
    } finally {
      setIsLoading(null);
    }
  });
  const onClickCreditCard = handleSubmit((data) => chargeCard('cardCharge', data));
  const onClickBit = handleSubmit((data) => chargeCard('bitCharge', data));
  return <OrderBox title={labels.weHappyForYou}>
    <Stack padding={2} gap={3} alignItems={'center'}>
      <Stack alignItems={'center'}>
        <Typography sx={{fontSize: 20}}>{labels.amountToPay}</Typography>
        <Typography sx={{fontSize: 25, fontWeight: 600}}>{formattedAmount}</Typography>
      </Stack>
      <FormProvider {...formMethods}>
        <PayerDetails askToSaveCard={false} askPaymentsNum={false}/>
      </FormProvider>
      {allowBit ? <Typography sx={{fontSize: 20}}>{labels.howDoYouPay}</Typography> : null}
      <Stack direction={'row'} gap={2}>
        <Stack gap={2} alignItems={'center'}>
          {allowBit ?
            <Typography sx={{fontSize: 20, fontWeight: 600}}>💳&nbsp;&nbsp;{labels.creditCard}</Typography> : null}
          <Stack gap={1}>
            <Typography>{labels.paymentsNum}</Typography>
            <Controller
              name="paymentsNum"
              control={formMethods.control}
              render={({field}) => <>
                <Select {...field} sx={{flex: 1}}>
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(num => {
                      return <MenuItem key={num} value={String(num)}>{num}</MenuItem>
                    })
                  }
                </Select>
              </>}
            />
          </Stack>
          {allowCardToken ?
            <LoadingButton onClick={onClickCreditCardWithToken} disabled={!!isLoading}
                           loading={isLoading === 'tokenCharge'}
                           variant={'contained'}>{labels.payWithCardNum(customer.cardSuffix)}</LoadingButton>
            : null}
          <LoadingButton onClick={onClickCreditCard} disabled={!!isLoading} loading={isLoading === 'cardCharge'}
                         variant={'contained'}>{allowCardToken ? labels.payWithOtherCard : labels.payWithCard}</LoadingButton>
        </Stack>
        {allowBit ? <>
          <Divider orientation="vertical" variant="middle" flexItem/>
          <Stack gap={2} alignItems={'center'} justifyContent={'space-between'}>
            <Stack sx={{flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
              <img src={BitLogo} height={60}/>
            </Stack>
            <LoadingButton onClick={onClickBit} disabled={!!isLoading} loading={isLoading === 'bitCharge'}
                           variant={'contained'}>{labels.payWithBit}</LoadingButton>
          </Stack>
        </> : null}
      </Stack>
      {mutationChargeToken.error ? <Typography sx={{color: 'red'}}>{labels.genericError}</Typography> : null}
    </Stack>
  </OrderBox>;
}
