import dayjs from 'dayjs';
import {
  COrderStatus,
  ICCustomerOwnOrder,
  ICOrderForPartner
} from "@tarimli-mono/api/src/shared/client-types.ts";
import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import {OrderRating} from "@tarimli-mono/api/src/shared/rating.ts";
import {ClientOfferForCustomer} from "./client-offer.ts";
import {config} from "../config.ts";

export class ClientCustomerOwnOrder {
  id: ICCustomerOwnOrder['id'];
  customerId: ICCustomerOwnOrder['customerId'];
  status: ICCustomerOwnOrder['status'];
  content: OrderContent;
  submittedOffers: ClientOfferForCustomer[];
  acceptedOffer: ClientOfferForCustomer | null;
  review: OrderRating | null;
  readonly createdAt: dayjs.Dayjs;
  readonly updatedAt: dayjs.Dayjs;

  constructor(order: ICCustomerOwnOrder) {
    this.id = order.id;
    this.customerId = order.customerId;
    this.status = order.status;
    this.content = new OrderContent(order.id, order.content, order.items);
    this.submittedOffers = order.submittedOffers.map(offer => new ClientOfferForCustomer(offer));
    this.acceptedOffer = order.acceptedOffer ? new ClientOfferForCustomer(order.acceptedOffer) : null;
    this.review = order.review && new OrderRating(order.review) || null;
    this.createdAt = dayjs(order.createdAt);
    this.updatedAt = dayjs(order.updatedAt);
  }

  get hasOffers(): boolean {
    return this.submittedOffers.length > 0;
  }

  get hasEnoughOffers(): boolean {
    return this.submittedOffers.length >= config().minOffersCount;
  }
}

export class ClientOrderForPartner {
  id: DbId;
  status: COrderStatus;
  content: OrderContent;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(order: ICOrderForPartner) {
    this.id = order.id;
    this.status = order.status;
    this.content = new OrderContent(order.id, order.content, order.items);
    this.createdAt = dayjs(order.createdAt);
    this.updatedAt = dayjs(order.updatedAt);
  }
}
