import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {forwardRef, HTMLAttributes, useMemo, useState} from "react";
import {useItems} from "@tarimli-mono/api/src/shared/items-data.ts";
import {matchSorter} from 'match-sorter'
import Typography from "@mui/material/Typography";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import {styled} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {EditItem} from "./EditItem.tsx";

const labels = {
  searchItem: 'חיפוש רהיט או פריט',
}

const filterOptions = (options, {inputValue}) => {
  const filtered = matchSorter(options.map(opt => opt.inputValue), inputValue).map((value) => ({
    inputValue: value,
    title: value,
  }));
  if (inputValue !== '') {
    filtered.push({
      inputValue: inputValue,
      title: `➕ הוסף "${inputValue}"`,
    });
  }

  return filtered;
};

const SearchModal = ({onChange, onClose}: { onChange: (value: string) => void, onClose: () => void }) => {
  const items = useItems();
  const listItems = useMemo(
    () => Object.keys(items).map(key => ({
      inputValue: key,
      title: key,
    })),
    [items],
  );
  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: '600px',
      height: '100%',
      // backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 2000,
      padding: 2,
    }}>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Autocomplete
          sx={{flexGrow: 1}}
          autoFocus
          onChange={(e, value, reason) => {
            if (reason === 'selectOption') {
              onClose();
              onChange(value.inputValue);
            }
          }}
          filterOptions={filterOptions}
          disablePortal
          options={listItems}
          openOnFocus
          getOptionLabel={(option) => option.title}
          renderOption={(props, option) => {
            const {key, ...rest} = props as HTMLAttributes<HTMLLIElement> & { key: string };
            return (
              <li key={key} {...rest}>
                {option.title}
              </li>
            );
          }}
          renderInput={(params) => <TextField
            autoFocus
            {...params}
            sx={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 1
            }}
            placeholder={labels.searchItem}/>}
        />
        <IconButton onClick={onClose} sx={{backgroundColor: 'rgba(255,255,255,0.78)'}}>
          <CloseIcon/>
        </IconButton>
      </Stack>
    </Box>
  );
}
//
// export const SearchItem = ({onItemSelect}: {onItemSelect: (value: string) => void}) => {
//   const [open, setOpen] = useState(false);
//   return (
//     <>
//       <Box sx={{
//         width: "100%",
//         backgroundColor: "white",
//         borderRadius: 1,
//         padding: 1,
//         border: 1,
//         borderColor: "#ccc",
//         color: "#757575",
//         pointer: 'cursor',
//       }}
//            onClick={() => setOpen(true)}>
//         <Typography variant="body1" sx={{padding: 1}}>{labels.searchItem}</Typography>
//       </Box>
//       {open ? <SearchModal onChange={onItemSelect} /> : null}
//     </>
//   );
// };

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialog-paper': {
    background: 'none',
  },
  // '& .MuiBox-root': {
  //   // background: 'none',
  // },
  '&': {
    // backgroundColor: 'transparent',
  }
}));

export function SearchItem({orderId}: { orderId: number }) {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [item, setItem] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onItemSelect = (value: string) => {
    setItem(value);
    setOpenEdit(true);
  }

  return (
    <>
      <Box sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: 1,
        padding: 1,
        border: 1,
        borderColor: "#ccc",
        color: "#757575",
        cursor: 'pointer',
      }}
           onClick={handleClickOpen}>
        <Typography variant="body1" sx={{padding: 1}}>{labels.searchItem}</Typography>
      </Box>
      <BootstrapDialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <SearchModal onChange={onItemSelect} onClose={handleClose}/>
      </BootstrapDialog>
      <EditItem orderId={orderId} open={openEdit} setOpen={setOpenEdit} newItemTitle={item}/>
    </>
  );
}