import {createFileRoute, notFound, redirect, useRouter} from "@tanstack/react-router";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import {EditOrderHeader, EditStageFooter, EditStageHeader} from "../../../../../components/stage-elements.tsx";
import {useCustomer} from "../../../../../services/auth.tsx";
import {useMutationUpdateOrder} from "../../../../../services/api-order.ts";
import {config} from "../../../../../config.ts";
import {OrderContent} from "@tarimli-mono/api/dist/shared/order-content.ts";
import {OrderForm} from "../../../../../components/forms/order-form.tsx";
import _ from "lodash";

export const Route = createFileRoute('/_auth/order/$orderId/_order/_draft/arrive')({
  component: OrderArrivalScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'draft') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
});

const labels = {
  'stage-title': 'לאן עוברים',
}

export function OrderArrivalScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const updateOrderMutation = useMutationUpdateOrder(20, order.content);
  const router = useRouter()
  const formMethods = useForm<OrderContentValues>({
    mode: 'all',
    values: order.content.formValues(),
  });
  const {
    handleSubmit,
    formState,
  } = formMethods;
  const {errors, isValid, isSubmitting} = formState;

  const onSubmit: SubmitHandler<OrderContentValues> = async (content) => {
    const dirtyFieldNames = Object.entries(formState.dirtyFields)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => key);
    if (dirtyFieldNames.length > 0) {
      const propsToSubmit = _.pick(content, dirtyFieldNames);
      await updateOrderMutation.mutateAsync({orderId: order.id, updatedContent: propsToSubmit});
    }
    await router.navigate({
      to: `/order/$orderId/date`,
      params: {
        /* @ts-expect-error */
        orderId: String(order.id),
      },
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  return (
    <FormProvider {...formMethods}>
      <form autoComplete={'off'}>
        <Stack gap={2}>
          <EditOrderHeader order={order} stage={2} totalStages={config().totalOrderStages}/>
          <Stack gap={0} sx={{
            borderRadius: 1,
            overflow: "hidden",
            backgroundColor: "white"
          }}>
            <EditStageHeader stage={2} totalStages={5} title={labels['stage-title']}/>
            <Box sx={{padding: 2}}>
              <OrderForm content={order.content} stage={'arrive'}/>
            </Box>
            <EditStageFooter loading={isSubmitting} disabled={!isValid}
                             hasErrors={!isValid}
                             submitError={!!updateOrderMutation.error}
                             onPressDone={handleSubmit(onSubmit)}/>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
