import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SouthIcon from '@mui/icons-material/South';
import WarningIcon from '@mui/icons-material/Warning';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {ClientCustomerOwnOrder} from "../../model/client-order.ts";

const labels = {
  'needToAdd': 'יש להוסיף',
}

export function OrderBox({title, children}: { title: string, children: React.ReactNode }) {
  return (
    <Stack sx={{
      margin: 2,
      backgroundColor: 'white',
      borderRadius: 1,
      clipPath: 'padding-box',
    }}>
      <Stack direction={'row'} sx={{
        padding: 2,
        justifyContent: 'space-between',
        backgroundColor: '#F3EBFF',
      }}>
        <Stack sx={{flexGrow: 1, alignItems: 'center'}}>
          <Typography sx={{
            fontSize: 30,
            textAlign: 'center',
            fontWeight: 800,
          }}>{title}</Typography>
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
}

export const EntryTitle = ({title}: { title: string }) => <Typography
  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{title}</Typography>;

export const NeedToAdd = () => (
  <Stack direction={'row'} gap={1} sx={{alignItems: 'center'}}>
    <WarningIcon color={'warning'} fontSize="small"/>
    <Typography sx={{fontSize: 14}}>{labels['needToAdd']}</Typography>
  </Stack>
);

export function DateBox({order}: { order: ClientCustomerOwnOrder }) {
  return <Stack sx={{alignItems: 'center'}} gap={1}>
    <CalendarMonthIcon color={'secondary'} sx={{fontSize: 40}}/>
    <Typography sx={{fontSize: 12}}>{order.content.movingDateRelativeLabel}</Typography>
    <Typography sx={{fontSize: 18}}>{order.content.movingDateFormatted}</Typography>
  </Stack>
}

export function AddressBox({order}: { order: ClientCustomerOwnOrder }) {
  return (
    <Stack sx={{alignItems: 'center'}} gap={1}>
      <LocalShippingIcon color={'secondary'} sx={{fontSize: 42}}/>
      <Typography>{order.content.strDepartAddress}</Typography>
      <SouthIcon color={'secondary'} sx={{fontSize: 20}}/>
      <Typography>{order.content.strArriveAddress}</Typography>
    </Stack>
  );
}
