import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {createFileRoute, useRouter} from '@tanstack/react-router'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {useState} from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import numbro from 'numbro';
import Grid from '@mui/system/Grid';
import {usePartner} from "../../../services/auth.tsx";
import {ClientPartnerOwnOffer} from "../../../model/client-offer.ts";
import {
  useMutationCancelOffer, useMutationConfirmTerms,
  useMutationDeclineOrder,
  useMutationSubmitOffer
} from "../../../services/api-partner.ts";
import {OrderNumber} from "../../../components/order-number.tsx";
import {V1_OrderSummary} from "../../../components/forms/v1-summary.tsx";

export const Route = createFileRoute('/_auth/partner/order/$orderId')({
  component: PartnerOrderScreen
});

const labels = {
  'active-contract': 'הזמנה פעילה',
  myOffer: 'הצעת המחיר שלי',
  nis: '₪',
  cancelOffer: 'ביטול הצעת מחיר',
  changeOffer: 'עריכת הצעת מחיר',
  confirm: 'אישור',
  cancel: 'ביטול',
  reallyCancelOffer: 'האם אתה בטוח שברצונך לבטל את ההצעה?',
  confirmCancelOffer: 'כן, בטל את ההצעה',
  close: 'סגור',
  amount: 'סכום ההצעה',
  total: 'סה״כ לתשלום ללקוח',
  priceBeforeVat: 'מחיר',
  vat: 'מע"מ 17%',
  'addOffer': 'הגש הצעת מחיר',
  'rejectOrder': 'דחה הזמנה',
  'newOrder': 'הזמנה חדשה',
  'send': 'שלח',
  continue: 'המשך',
  submitOfferTitle: 'הזן את סכום ההצעה שלך',
  confirmOfferTitle: 'אנא אשר את סכום ההצעה שלך',
  'includingVat': 'כולל מע"מ',
  'notIncludingVat': 'לא כולל מע"מ',
  'chat': 'בירור',
  'declineReason': 'מה סיבת הדחיה?',
  'notAvailable': 'לא פנוי בתאריכים אלו',
  'outOfZone': 'מחוץ לאיזור הפעילות',
  'orderTooLarge': 'הזמנה גדולה מדי',
  'orderTooSmall': 'הזמנה קטנה מדי',
  'other': 'אחר',
  amountDisclaimer: 'הסכום חייב לכלול את כל מרכיבי עלות ההובלה: מנוף (אם נדרש), פירוקים והרכבות ככל שנדרשים, גישה למשאית וכל מה שהלקוח ציין בהזמנה וכן מע״מ.',
  offerDisclaimer: ' נעדכן אותך אם הלקוח אישר את ההזמנה',
  makeCorrection: 'תיקון',
  errorOtherReason: 'יש לציין סיבת דחיה',
  submitErrorMessage: 'סליחה תקלה, אנא נסה שוב, אם הבעיה נמשכת נא לפנות לשירות הלקוחות',
  confirmOffer: (amount: number) => <Typography>נא לאשר שליחת הצעת מחיר בסכום <em>{amount}₪</em></Typography>,
  orderFromArchive: (date: string) => `הזמנה מארכיון (${date})`,
  registrationNotCompletedText: () => <Typography>לצערינו לא השלמת את הרישום למערכת ועל כן אינך יכול להגיש הצעות. על מנת
    להגיש הצעות עליך להשלים רישום, אנא פנה ל - <a
      href={`https://wa.me/${encodeURIComponent('+972524751656')}`}>052-475-1656</a></Typography>,
  iConfirmTheTerms: 'קראתי ואני מאשר',
  notAccepted: '(לא התקבלה)',
  termsNotConfirmedText: () => <Typography>
    ברוך הבא לתריםלי!
    <br/><br/>
    מעכשיו נתחיל לשלוח לך את כל הצעות המחיר שלנו. אנא זכור, מהירות תגובה היא שם המשחק.
    <br/><br/>
    תריםלי שואפת להיות סוגרת העסקאות המהירה ביותר בשוק בשביל לתת חווית לקוח אופטימלית ועדכנית.
    <br/><br/>
    אנא זכור את תנאי השימוש עליהם חתמת ובמיוחד את הדגשים הבאים:
    <br/><br/>
    היה אדיב ושירותי: השירות שלנו מתבסס לא רק על מחיר, אלא גם על הדירוג שתקבל מהלקוחות. בסוף כל הובלה הלקוחות
    יתבקשו<br/>
    לדרג את טיב השירות שהם קיבלו ממך ואת איכות ההובלה, הניקוד הזה יהיה גלוי ללקוחות הבאים אחריהם ואלה יוכלו לבחור
    את<br/>
    המוביל שלהם עפ״י דירוג או מחיר.<br/>
    יש לעטוף את כל הרהיטים ומוצרי החשמל הגדולים בטרם העמסתם למשאית.<br/>
    יש להקפיד על עמידה בזמנים.<br/>
    אנא וודא שאתה קורא היטב את כל פרטי ההובלה בטרם הגשת הצעת מחיר על מנת למנוע אי נעימויות או מבוכות ודירוג נמוך.<br/>
    התשלום להובלות יבוצע דרך תריםלי ורק לאחר שהלקוח יאשר שההובלה בוצעה בהצלחה, כרטיסו יחוייב.<br/>
    אתה תקבל את התשלום המגיע לך על ההובלה מיד עם ביצוע החיוב, כלומר מחיר ההובלה בהפחתה של 10% עמלה לתריםלי ועמלת
    סליקה.<br/>
    <br/><br/>
    בחרנו אותך בפינצטה ואנחנו סמוכים ובטוחים שיהיה לנו משתלם ונעים לעבוד יחד, אנחנו תמיד זמינים.
    בהצלחה!
    <br/><br/>
  </Typography>,
};

const declineReasonOptions = [
  labels['notAvailable'],
  labels['outOfZone'],
  labels['orderTooLarge'],
  labels['orderTooSmall'],
  labels['other'],
]

const SubmittedOfferHeader = ({offer}: { offer: ClientPartnerOwnOffer }) => {
  return <Stack>
    <Stack direction={'row'} alignItems={'center'} gap={2}>
      <Typography fontSize={20}>{labels.myOffer}</Typography>
      <Typography
        sx={{fontSize: 22, fontWeight: 600}}>{labels['nis']}{offer.amount}</Typography>
    </Stack>
    {offer.isExpired && <Typography fontSize={15}>{labels.notAccepted}</Typography>}
  </Stack>
}

const AcceptedOfferHeader = ({offer}: { offer: ClientPartnerOwnOffer }) => {
  return <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={2}>
    <Typography fontSize={20}>{labels['active-contract']}</Typography>
    <Typography
      sx={{fontSize: 22, fontWeight: 600}}>{labels['nis']}{offer.amount}</Typography>
  </Stack>
}

export function PartnerOrderScreen() {
  const partner = usePartner()
  const router = useRouter();
  const routeParams = Route.useParams() as { orderId: string };
  const orderId = Number(routeParams.orderId);
  const order = partner.findOrder(orderId);
  const offer = partner.findOfferForOrderId(orderId);
  const cancelOfferMutation = useMutationCancelOffer();
  const [cancelOfferDialogOpen, setCancelOfferDialogOpen] = useState(false);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const declineOfferMutation = useMutationDeclineOrder();
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [openAlertRegistrationIncomplete, setOpenAlertRegistrationIncomplete] = useState(false);
  const [openAlertTermsNotConfirmed, setOpenAlertTermsNotConfirmed] = useState(false);
  const isInviteOffer = offer.status === 'invite';
  const isSubmittedOffer = offer.status === 'submitted';
  const isAcceptedOffer = offer.status === 'accepted';
  const isDeclinedOffer = offer.status === 'declined';
  const isInviteOrDeclinedOffer = isInviteOffer || isDeclinedOffer;

  const onClickBack = async () => {
    /* @ts-expect-error False alarm */
    await router.navigate({
      to: `/partner/orders`,
      replace: true,
    });
  }
  const handleCancelOffer = async () => {
    const result = await cancelOfferMutation.mutateAsync({offerId: offer.id});
    /* @ts-expect-error False alarm */
    await router.navigate({
      to: `/partner/orders`,
    });
  }
  const handleCancelOfferOpen = () => {
    setCancelOfferDialogOpen(true);
  }
  const handleCancelOfferClose = () => {
    setCancelOfferDialogOpen(false);
  }
  const handleClickSubmitOpen = () => {
    if (partner.onboardingRegistrationCompleted) {
      setSubmitDialogOpen(true);
    } else {
      setOpenAlertRegistrationIncomplete(true);
    }
  };
  const handleSubmitClose = () => {
    setSubmitDialogOpen(false);
  };
  const handleDeclineClose = () => {
    setDeclineDialogOpen(false);
  }
  const onSubmitOffer = async () => {
    setTimeout(() => {
      // @ts-expect-error
      router.navigate({
        to: `/partner/orders`,
      });
    }, 100);
  }
  const onSubmitDecline = async ({reason, text}: { reason: string, text?: string }) => {
    const message = reason === labels['other'] ? `${reason}: ${text}` : reason;
    await declineOfferMutation.mutateAsync({
      offerId: offer.id,
      message,
    });
    setTimeout(() => {
      // @ts-expect-error
      router.navigate({
        to: `/partner/orders`,
      });
    }, 100);
  }
  const onClickDeclineOfferInvite = async () => {
    setDeclineDialogOpen(true);
  }

  return (
    <>
      <Stack gap={2} sx={{
        margin: 2,
        borderRadius: 1,
        backgroundColor: "#F5EDFF",
        padding: 2,
        overflow: 'auto',
      }}>
        <Stack gap={2}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <IconButton onClick={onClickBack} sx={{
              backgroundColor: "#EADCFF",
            }}>
              <ChevronRightIcon/>
            </IconButton>
            {isSubmittedOffer ? <Box sx={{flexGrow: 1}}><SubmittedOfferHeader offer={offer}/></Box> : null}
            {isAcceptedOffer ? <Box sx={{flexGrow: 1}}><AcceptedOfferHeader offer={offer}/></Box> : null}
            {isInviteOrDeclinedOffer ?
              <Box sx={{flexGrow: 1}}><Typography fontSize={20}>{labels['newOrder']}</Typography></Box> : null}
            <OrderNumber orderId={order.id}/>
          </Stack>
        </Stack>
        <Stack sx={{
          overflow: 'auto',
        }}
               gap={2}>
          <Box sx={{overflow: 'auto'}}>
            <V1_OrderSummary orderContent={order.content} forPartner={true}/>
          </Box>
          {isSubmittedOffer && !offer.isExpired ? <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'center'} sx={{
            p: 2,
          }}>
            <LoadingButton
              loading={cancelOfferMutation.isPending}
              sx={{alignSelf: 'flex-end', backgroundColor: 'red'}} variant={'contained'}
              onClick={handleCancelOfferOpen}>{labels.cancelOffer}</LoadingButton>
          </Stack> : null}
          {isInviteOrDeclinedOffer && !offer.isExpired ? <Box sx={{
            flexGrow: 0,
            flexShrink: 0,
          }}>
            <Stack direction={'row'} gap={2} sx={{
              padding: 2,
            }}>
              <Button sx={{flex: 2}} onClick={handleClickSubmitOpen}>{labels['addOffer']}</Button>
              {partner.canSubmitOffers && !partner.orderIsDeclined(orderId) ?
                <Button sx={{flex: 1}} variant={'outlined'}
                        onClick={onClickDeclineOfferInvite}>{labels['rejectOrder']}</Button> : null}
            </Stack>
          </Box> : null}
        </Stack>
      </Stack>
      {isSubmittedOffer ? <CancelOfferDialog
        onConfirm={handleCancelOffer}
        open={cancelOfferDialogOpen}
        onClose={handleCancelOfferClose}/> : null}
      {isInviteOrDeclinedOffer ? <>
        <SubmitOfferDialog
          offerId={offer.id}
          onSubmit={onSubmitOffer}
          open={submitDialogOpen}
          onClose={handleSubmitClose}/>
        <DeclineDialog
          handleSubmit={onSubmitDecline}
          declineDialogOpen={declineDialogOpen}
          handleDeclineClose={handleDeclineClose}/>
      </> : null}
      <AlertRegistrationIncomplete open={openAlertRegistrationIncomplete}
                                   handleClose={() => setOpenAlertRegistrationIncomplete(false)}/>
      <AlertTermsNotConfirmed open={openAlertTermsNotConfirmed}
                              handleClose={(confirmed: boolean) => {
                                setOpenAlertTermsNotConfirmed(false);
                                if (confirmed) {
                                  setSubmitDialogOpen(true);
                                }
                              }}/>
    </>
  );
}

function CancelOfferDialog({open, onClose, onConfirm}) {
  return <Dialog
    open={open}
    onClose={onClose}
  >
    <Stack sx={{alignItems: 'center', p: 2}} gap={4}>
      {labels.reallyCancelOffer}
      <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
        <Button onClick={onConfirm}>{labels.confirmCancelOffer}</Button>
        <Button variant={'outlined'} onClick={onClose}>{labels.close}</Button>
      </Stack>
    </Stack>
  </Dialog>
}

function SubmitOfferDialog({offerId, onSubmit, open, onClose}) {
  const partner = usePartner();
  const submitOfferMutation = useMutationSubmitOffer();
  const [includingVat, setIncludingVat] = useState<null | 'yes' | 'no'>(null);
  const [dialogShowing, setDialogShowing] = useState<'offer' | 'confirm'>('offer');
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState,
    watch,
    setValue,
    reset,
  } = useForm<{ amount: string }>({
    defaultValues: {
      amount: '',
    },
  });
  const watchAllFields = watch();
  const amount = watchAllFields.amount;
  const parsedAmount = Number(amount) || 0;
  let vat = 0;
  let totalAmount = 0;
  if (partner.plusVat) {
    if (typeof parsedAmount === 'number' && parsedAmount > 0 && includingVat !== null) {
      if (includingVat === 'yes') {
        vat = parsedAmount - Math.round(parsedAmount / 1.17);
        totalAmount = Math.round(parsedAmount);
      } else {
        totalAmount = Math.round(parsedAmount * 1.17);
        vat = Math.round(parsedAmount * 0.17);
      }
    }
  } else {
    totalAmount = parsedAmount;
  }
  const formattedAmount = amount ? numbro(parsedAmount).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  }) : '-';
  const formattedAmountMinusVat = amount ? numbro(includingVat === 'yes' ? parsedAmount - vat : parsedAmount).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  }) : '-';
  const formattedTotalAmount = totalAmount ? numbro(totalAmount).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',

  }) : '-';
  const formattedVat = vat ? numbro(vat).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  }) : '-';
  const onClickClose = () => {
    reset();
    setIncludingVat(null);
    onClose();
    setDialogShowing('offer');
  }
  const onClickContinue = () => {
    setDialogShowing('confirm');
  }
  const onClickBack = () => {
    setDialogShowing('offer');
  }
  const onFormSubmit = async () => {
    await submitOfferMutation.mutateAsync({
      offerId,
      amount: totalAmount,
    });
    onSubmit();
  }
  return <Dialog
    open={open}
    onClose={onClose}
  >
    <>
      <Box sx={{display: dialogShowing === 'offer' ? 'block' : 'none', maxWidth: 800}}>
        <Stack sx={{padding: 2}} gap={2}>
          <Typography variant={'h6'}>{labels.submitOfferTitle}</Typography>
          <Typography sx={{color: 'red', fontWeight: 500}}>{labels.amountDisclaimer}</Typography>
          <Controller
            name="amount"
            rules={{required: true}}
            control={control}
            render={({field}) => {
              return <>
                <Grid container spacing={2} sx={{display: 'flex', flexGrow: 1}}>
                  <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                    <Typography>{labels.amount} <span style={{color: 'red'}}>*</span></Typography>
                  </Grid>
                  <Grid size={7}>
                    <TextField
                      {...field} />
                  </Grid>
                  {partner.plusVat && <>
                    <Grid size={5}>

                    </Grid>

                    <Grid size={7} sx={{alignItems: 'flex-end'}}>
                      <RadioGroup>
                        <FormControlLabel control={<Radio checked={includingVat === 'yes'}
                                                          onChange={(e, v) => setIncludingVat(v ? 'yes' : 'no')}/>}
                                          label={labels['includingVat']}/>
                        <FormControlLabel control={<Radio checked={includingVat === 'no'}
                                                          onChange={(e, v) => setIncludingVat(v ? 'no' : 'yes')}/>}
                                          label={labels['notIncludingVat']}/>
                      </RadioGroup>
                    </Grid>
                  </>
                  }
                  <Grid size={12}>
                    <Divider/>
                  </Grid>
                  {partner.plusVat && <>
                    <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                      <Typography>{labels.priceBeforeVat}</Typography>
                    </Grid>
                    <Grid size={7}>
                      <Typography>{formattedAmountMinusVat}</Typography>
                    </Grid>
                    <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                      <Typography>{labels.vat}</Typography>
                    </Grid>
                    <Grid size={7}>
                      <Typography>{formattedVat}</Typography>
                    </Grid>
                    <Grid size={12}>
                      <Divider/>
                    </Grid>
                  </>
                  }
                  <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                    <Typography>{labels.total}</Typography>
                  </Grid>
                  <Grid size={7}>
                    <Typography variant={'h5'}>{formattedTotalAmount}</Typography>
                  </Grid>
                </Grid>
              </>
            }}
          />

          <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
            <Button disabled={totalAmount <= 0 || formState.isSubmitting}
                    sx={{flexGrow: 1}}
                    onClick={onClickContinue}>{labels.continue}</Button>
            <Button variant={'outlined'} onClick={onClickClose}>{labels['cancel']}</Button>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{display: dialogShowing === 'confirm' ? 'block' : 'none', maxWidth: 800}}>
        <Stack sx={{padding: 2}} gap={2}>
          <Typography variant={'h6'}>{labels.confirmOfferTitle}</Typography>
          <Typography sx={{fontWeight: 500}}>{labels.offerDisclaimer}</Typography>
          <Grid container spacing={2} sx={{display: 'flex', flexGrow: 1}}>
            <Grid size={12}>
              <Divider/>
            </Grid>
            {partner.plusVat &&
              <>
                <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                  <Typography>{labels.amount}</Typography>
                </Grid>
                <Grid size={7}>
                  <Typography>{formattedAmount}</Typography>
                </Grid>
                <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                  <Typography>{labels.vat}</Typography>
                </Grid>
                <Grid size={7}>
                  <Typography>{formattedVat}</Typography>
                </Grid>
              </>
            }
            <Grid size={5} sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
              <Typography>{labels.total}</Typography>
            </Grid>
            <Grid size={7}>
              <Typography variant={'h5'}>{formattedTotalAmount}</Typography>
            </Grid>
          </Grid>
          <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
            <LoadingButton variant={'contained'} disabled={totalAmount <= 0 || formState.isSubmitting}
                           loading={formState.isSubmitting} sx={{flexGrow: 1}}
                           onClick={handleSubmit(onFormSubmit)}>{labels.confirm}</LoadingButton>
            <Button variant={'outlined'} onClick={onClickBack}>{labels.makeCorrection}</Button>
          </Stack>
          {submitOfferMutation.isError ?
            <Typography sx={{color: 'red'}}>{labels.submitErrorMessage}</Typography> : null}
        </Stack>
      </Box>
    </>
  </Dialog>
}

function DeclineDialog({handleSubmit, declineDialogOpen, handleDeclineClose}) {
  const form = useForm({
    mode: 'all',
    defaultValues: {
      selectedDeclineReason: '',
      declineReasonText: '',
    },
  });
  const selectedDeclineReason = useWatch({
    control: form.control,
    name: 'selectedDeclineReason', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "", // default value before the render
  });
  const onSubmitDecline = async () => {
    await handleSubmit({
      reason: form.getValues().selectedDeclineReason,
      text: form.getValues().declineReasonText,
    });
    handleDeclineClose();
  }
  const onClose = () => {
    form.reset();
    handleDeclineClose();
  }
  return <Dialog
    open={declineDialogOpen}
    onClose={handleDeclineClose}
  >
    <Stack sx={{padding: 2}} gap={2}>
      <Controller
        name='selectedDeclineReason'
        control={form.control}
        rules={{required: true}}
        render={({field, formState, fieldState}) => {
          return (
            <>
              <FormControl>
                <FormLabel>{labels['declineReason']} <span style={{color: 'red'}}>*</span></FormLabel>
                <RadioGroup name='selectedDeclineReason' value={field.value || ''}
                            onChange={(e) => {
                              field.onChange(e.target.value)
                            }}>
                  {
                    declineReasonOptions.map(option => {
                      return (
                        <FormControlLabel key={option} control={<Radio value={option}/>}
                                          label={option}/>
                      );
                    })
                  }
                </RadioGroup>
              </FormControl>
            </>);
        }}/>
      <Box sx={{display: form.getValues().selectedDeclineReason === labels['other'] ? 'block' : 'none'}}>
        <Controller
          name="declineReasonText"
          rules={{required: selectedDeclineReason === labels['other']}}
          control={form.control}
          render={({field, formState}) => <>
            <TextField
              required={form.getValues().selectedDeclineReason === labels['other']}
              error={!!formState.errors?.selectedDeclineReason}
              helperText={labels.errorOtherReason}
              size={'small'}
              {...field}
              variant="outlined"/>
          </>}
        />
      </Box>
      <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
        <LoadingButton variant={'contained'} disabled={!form.formState.isValid}
                       loading={form.formState.isSubmitting} sx={{flexGrow: 1}}
                       onClick={form.handleSubmit(onSubmitDecline)}>{labels['send']}</LoadingButton>
        <Button variant={'outlined'} onClick={onClose}>{labels['cancel']}</Button>
      </Stack>
    </Stack>
  </Dialog>
}

function AlertRegistrationIncomplete({open, handleClose}: { open: boolean, handleClose: () => void }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        {labels.registrationNotCompletedText()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {labels.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AlertTermsNotConfirmed({open, handleClose}: { open: boolean, handleClose: (boolean) => void }) {
  const mutationConfirmTerms = useMutationConfirmTerms();
  const handleConfirmTerms = async () => {
    await mutationConfirmTerms.mutateAsync();
    handleClose(true);
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <DialogContentText>
          {labels.termsNotConfirmedText()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant={'contained'} loading={mutationConfirmTerms.isPending} sx={{flex: 1}}
                       onClick={handleConfirmTerms} autoFocus>
          {labels.iConfirmTheTerms}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}