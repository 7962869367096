import {createFileRoute, redirect} from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/checkout-complete')({
  component: () => null,
  beforeLoad: ({context, search}) => {
    // @ts-expect-error
    throw redirect({
      to: '/orders',
    })
  }
})
