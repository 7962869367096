import {createFileRoute, useRouter} from '@tanstack/react-router'
import {useRooms} from "@tarimli-mono/api/src/shared/items-data.ts";
import {useCustomer} from "../../../../../services/auth.tsx";
import {TopCategory} from "../../../../../components/TopCategory.tsx";
import {IconRoom} from "../../../../../components/icons/IconRoom.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/items/rooms')({
  component: OrderRoomsScreen,
})

const labels = {
  rooms: "חדרים",
}

export function OrderRoomsScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as {orderId: string};
  const rooms = useRooms();
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const router = useRouter()

  return (
    <TopCategory
      orderId={order.id}
      title={labels['rooms']}
      icon={<IconRoom sx={{ fontSize: 30, color: '#AD7AFF' }} />}
      categoriesData={rooms}
      selectedItems={order.content.sortedItems}
      onClickBack={() => {
        router.history.back();
      }}
      onClickCategory={(catTitle: string) => {
        router.navigate({
          to: `/order/$orderId/items/rooms/room`,
          params: {
            /* @ts-expect-error */
            orderId: String(order.id),
          },
          search: {
            /* @ts-expect-error */
            title: catTitle,
          }
        });
      }}
    />
  );
}
