import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import {Controller, useFormContext} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import uniq from "lodash/uniq";
import Checkbox from "@mui/material/Checkbox";
import {OrderInput} from "../../OrderInput.tsx";
import AddressAutocomplete from "../../AddressAutocomplete.tsx";

export const V1_FormGroup_DepartAddress = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
  } = formContext;
  return <Stack gap={2}>
    <Controller
      rules={{required: content.entries.departAddress.formErrorMessageRequired}}
      name="departAddress"
      control={control}
      render={({field}) => <>
        <OrderInput title={content.entries.departAddress.formLabel} required>
          <AddressAutocomplete
            onChange={value => {
              field.onChange(value);
            }} initialValue={field.value}/>
        </OrderInput>
      </>}
    />
    <Stack direction={"row"} gap={2}>
      <Controller
        rules={{
          required: content.entries.departFloor.formErrorMessageRequired,
          minLength: 1,
          maxLength: {
            value: content.entries.arriveFloor.maxLen,
            message: content.entries.arriveFloor.formErrorMessageMaxLen
          },
        }}
        name="departFloor"
        control={control}
        render={({field, fieldState, formState}) => <>
          <OrderInput title={content.entries.departFloor.formLabel} required>
            <TextField
              {...field}
              helperText={fieldState.invalid ? fieldState.error.message : ""}
              error={fieldState.invalid}
            />
          </OrderInput>
        </>}
      />
      <Controller
        name="departRooms"
        rules={{
          required: content.entries.departRooms.formErrorMessageRequired,
        }}
        control={control}
        render={({field}) => <>
          <OrderInput title={content.entries.departRooms.formLabel} required>
            <Select
              {...field}
            >
              {
                content.entries.departRooms.options.map(option => (
                  <MenuItem key={option.value} value={option.value}>{option.formLabel}</MenuItem>
                ))
              }
            </Select>
          </OrderInput>
        </>}
      />
    </Stack>

  </Stack>;
}

export const V1_FormGroup_DepartParking = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchParking = watch('departParking');
  return <Stack gap={2}>
    <Controller
      name="departParking"
      rules={{required: content.entries.departParking.formErrorMessageRequired}}
      control={control}
      render={({field}) => <>
        <OrderInput title={content.entries.departParking.formLabel} required>
          <RadioGroup {...field}>
            {
              content.entries.departParking.options.map(option => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Radio checked={field.value === option.value}/>}
                                  label={option.formLabel}/>
              ))
            }
          </RadioGroup>
        </OrderInput>
      </>}
    />
    <Controller
      name="departParkingComments"
      rules={{
        required: watchParking === 'maybe' ? content.entries.departParkingComments.formErrorMessageRequired : undefined,
        maxLength: {
          value: content.entries.departParkingComments.maxLen,
          message: content.entries.departParkingComments.formErrorMessageMaxLen
        },
      }}
      control={control}
      render={({field, fieldState}) => {
        return <>
          <Box sx={{display: ['maybe', 'no'].includes(watchParking) ? 'block' : 'none'}}>
            <OrderInput title={content.entries.departParkingComments.formLabel}
                        required={['maybe'].includes(watchParking)}>
              <TextField
                {...field}
                helperText={fieldState.invalid ? `${field.value.length}/${content.entries.departParkingComments.maxLen} | ${fieldState.error.message}` : field.value.length > content.entries.departParkingComments.maxLen / 10 ? `${field.value.length}/${content.entries.departParkingComments.maxLen}` : ''}
                error={fieldState.invalid}
              />
            </OrderInput>
          </Box>
        </>
      }}
    />
  </Stack>
}

export const V1_FormGroup_DepartLift = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
  } = formContext;
  return <Stack gap={2}>
    <Controller
      name="departLift"
      rules={{required: content.entries.departLift.formErrorMessageRequired}}
      control={control}
      render={({field}) => <>
        <OrderInput title={content.entries.departLift.formLabel} required>
          <RadioGroup {...field}>
            {
              content.entries.departLift.options.map(option => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Radio checked={field.value === option.value}/>}
                                  label={option.formLabel}/>
              ))
            }
          </RadioGroup>
        </OrderInput>
      </>}
    />
  </Stack>
}

export const V1_FormGroup_DepartCrane = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchCrane = watch('departCrane');
  return <Stack gap={2}>
    <Controller
      name="departCrane"
      rules={{required: content.entries.departCrane.formErrorMessageRequired}}
      control={control}
      render={({field}) => (
        <OrderInput title={content.entries.departCrane.formLabel} required>
          <RadioGroup {...field}>
            {
              content.entries.departCrane.options.map(option => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Radio checked={field.value === option.value}/>}
                                  label={option.formLabel}/>
              ))
            }
          </RadioGroup>
        </OrderInput>
      )}/>
    <Controller
      name="departCraneComments"
      rules={{
        required: watchCrane === 'maybe' ? content.entries.departCraneComments.formErrorMessageRequired : undefined,
        maxLength: {
          value: content.entries.departCraneComments.maxLen,
          message: content.entries.departCraneComments.formErrorMessageMaxLen
        },
      }}
      control={control}
      render={({field, fieldState}) => {
        return <>
          <Box sx={{display: ['maybe', 'yes'].includes(watchCrane) ? 'block' : 'none'}}>
            <OrderInput title={content.entries.departCraneComments.formLabel} required={['maybe'].includes(watchCrane)}>
              <TextField
                {...field}
                helperText={fieldState.invalid ? `${field.value.length}/${content.entries.departCraneComments.maxLen} | ${fieldState.error.message}` : field.value.length > content.entries.departCraneComments.maxLen / 10 ? `${field.value.length}/${content.entries.departCraneComments.maxLen}` : ''}
                error={fieldState.invalid}
              />
            </OrderInput>
          </Box>
        </>
      }}
    />
  </Stack>
}

export const V1_FormGroup_DepartExternalSpace = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchExternalSpace = watch('departExternalSpace');
  return <Stack gap={2}>
    <Controller
      name="departExternalSpace"
      rules={{
        validate: {
          required: (value) => {
            if (value.length === 0) {
              return content.entries.departExternalSpace.formErrorMessageRequired;
            }
          }
        },
      }}
      control={control}
      render={({field}) => {
        const handleChange = (checked: boolean, value: string) => {
          if (!checked) {
            field.onChange(field.value.filter((val: string) => val !== value))
          } else {
            if (value === 'none') {
              field.onChange(['none']);
            } else {
              field.onChange(uniq([...field.value, value].filter(v => v !== 'none')));
            }
          }
        }
        return (
          <OrderInput title={content.entries.departExternalSpace.formErrorMessageRequired} required>
            <RadioGroup {...field}>
              <FormControlLabel value="balcony" control={<Checkbox checked={field.value.includes('balcony')}
                                                                   onChange={e => handleChange(e.target.checked, 'balcony')}/>}
                                label={content.entries.departExternalSpace.options.find(opt => opt.value === 'balcony').formLabel}/>
              <Controller
                name="departBalconyCount"
                rules={{required: watchExternalSpace?.includes('balcony') ? content.entries.departExternalSpace.formErrorMessageRequired : undefined}}
                control={control}
                render={({field}) => (
                  <Box sx={{
                    display: watchExternalSpace?.includes('balcony') ? 'block' : 'none',
                    marginLeft: 4
                  }}>
                    <OrderInput title={content.entries.departBalconyCount.formLabel} required>
                      <Select
                        {...field}
                        sx={{alignSelf: 'flex-start', width: 150}}
                      >
                        {
                          content.entries.departBalconyCount.options.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.formLabel}</MenuItem>
                          ))
                        }
                      </Select>
                    </OrderInput>
                  </Box>
                )}
              />
              <FormControlLabel value="roof" control={<Checkbox checked={field.value.includes('roof')}
                                                                onChange={e => handleChange(e.target.checked, 'roof')}/>}
                                label={content.entries.departExternalSpace.options.find(opt => opt.value === 'roof').formLabel}/>
              <FormControlLabel value="garden" control={<Checkbox checked={field.value.includes('garden')}
                                                                  onChange={e => handleChange(e.target.checked, 'garden')}/>}
                                label={content.entries.departExternalSpace.options.find(opt => opt.value === 'garden').formLabel}/>
              <FormControlLabel value="none" control={<Checkbox checked={field.value.includes('none')}
                                                                onChange={e => handleChange(e.target.checked, 'none')}/>}
                                label={content.entries.departExternalSpace.options.find(opt => opt.value === 'none').formLabel}/>
            </RadioGroup>
          </OrderInput>
        )
      }}/>
  </Stack>;
}

export const V1_FormGroup_DepartHouseStructure = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchHouseStructure = watch('departHouseStructure');
  return <Stack gap={2}>
    <Stack direction={"row"} gap={2} alignItems={'flex-end'}>
      <Controller
        name="departHouseStructure"
        rules={{required: content.entries.departHouseStructure.formErrorMessageRequired}}
        control={control}
        render={({field}) => (
          <OrderInput title={content.entries.departHouseStructure.formLabel} required>
            <RadioGroup {...field} onChange={e => field.onChange(e.target.value)}>
              {
                content.entries.departHouseStructure.options.map(option => (
                  <FormControlLabel key={option.value} value={option.value}
                                    control={<Radio checked={field.value === option.value}/>}
                                    label={option.formLabel}/>
                ))
              }
            </RadioGroup>
          </OrderInput>
        )}/>
      <Controller
        name="departHouseFloorsNum"
        rules={{required: watchHouseStructure === 'more-floors' ? content.entries.departHouseFloorsNum.formErrorMessageRequired : undefined}}
        control={control}
        render={({field}) => (
          <Box sx={{display: watchHouseStructure === 'more-floors' ? 'block' : 'none'}}>
            <OrderInput title={content.entries.departHouseFloorsNum.formLabel} required>
              <Select
                {...field}
              >
                {
                  content.entries.departHouseFloorsNum.options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.formLabel}</MenuItem>
                  ))
                }
              </Select>
            </OrderInput>
          </Box>
        )}
      />
    </Stack>
  </Stack>
}
