import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const FieldTitle = ({title, required}: { title: string, required?: boolean }) => {
  return (
    <Stack direction={'row'}>
      <Typography>{title}</Typography>&nbsp;
      {required ? <Typography sx={{color: 'red'}}>*</Typography> : ''}
    </Stack>
  );
}

export const OrderInput = ({
                             title,
                             errorMsgs,
                             children,
                             required,
  row,
  sx,
                           }: {
  row?: boolean,
  title?: string,
  errorMsgs?: string[],
  children: React.ReactNode,
  required?: boolean
  sx?: any
}) => {
  const errors = (errorMsgs || []).filter(err => typeof err === 'string').map(msg => <Typography
    variant="formError">{msg}</Typography>);
  return (
    <Stack gap={1} direction={row ? 'row' : 'column'} sx={sx || {}}>
      <FieldTitle title={title} required={required}/>
      {...errors}
      {children}
    </Stack>
  );
};
