import * as React from 'react';
import { Unstable_NumberInput as BaseNumberInput, NumberInputProps } from '@mui/base/Unstable_NumberInput';
import {styled} from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { grey } from '@mui/material/colors';

const labels = {
  cancel: 'ביטול',
  ok: 'אישור',
};

export const NumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps & { title: string },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  return (
    <>
      <NumberInputDialog value={props.value} title={props.title} open={dialogOpen} onClose={() => setDialogOpen(false)}
                         onSubmit={(rn) => {
                           const n = Number(rn);
                           if (!isNaN(n)) {
                             props.onChange(null, n);
                           }
                           setDialogOpen(false);
                         }}/>
      <BaseNumberInput
        readOnly={true}
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          input: {
            disabled: true,
            onPointerUp: (event) => {
              setDialogOpen(true);
            }
          },
          incrementButton: {
            children: <AddIcon fontSize="small"/>,
            className: 'increment',
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small"/>,
          },
        }}
        {...props}
        ref={ref}
      />
    </>
  );
});

const StyledInputRoot = styled('div')(
  ({theme}) => `
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`,
);

const StyledInput = styled('input')(
  ({theme}) => `
  font-size: 16pt;
  font-family: inherit;
  font-weight: 400;
  color: black;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 8px;
  margin: 0 8px;
  padding: 0 12px;
  height: 45px;
  line-height: 45px;
  outline: 0;
  min-width: 0;
  width: 80px;
  text-align: center;

  &:hover {
    border-color: #AD7AFF;
  }

  &:focus {
    border-color: #AD7AFF;
    box-shadow: 0 0 0 3px #AD7AFF33;
  }

  &:focus-visible {
    outline: 0;
  }
  &:disabled {
    color: black;
    opacity: 1;
  }
`,
);

const StyledButton = styled('div')(
  ({theme}) => `
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  width: 50px;
  height: 45px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: #AD7AFF;
    border-color: #AD7AFF;
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);


function NumberInputDialog({value, title, open, onClose, onSubmit}: {
  value: number,
  title: string,
  open: boolean,
  onClose: () => void,
  onSubmit: (n: number) => void
}) {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        (document.querySelector('input[name=num]') as HTMLInputElement)?.focus();
      }, 100);
    } else {
      setTimeout(() => {
        (document.querySelector('input[name=num]') as HTMLInputElement)?.blur();
      }, 100);
    }
  }, [open]);
  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  }
  return (
    <React.Fragment>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            height: '50%',
          }
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(Number((event.target as any).num.value));
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <TextField
              // placeholder={String(value)}
              autoFocus
              name="num"
              type="number"
              inputMode="numeric"
              slotProps={{
                htmlInput: {
                  pattern: '[0-9]*',
                  style: {
                    textAlign: 'center',
                    fontSize: 22,
                  }
                }
              }}
              fullWidth
            />
            <Stack direction={'row'} gap={1}>
              <Button variant={'outlined'} size={'large'} onClick={onClose}>{labels.cancel}</Button>
              <Button sx={{flexGrow: 1}} size={'large'} type="submit">{labels.ok}</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}