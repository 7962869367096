import Stack from "@mui/material/Stack";
import {createFileRoute, useRouter} from "@tanstack/react-router";
import TextField from "@mui/material/TextField";
import {Controller, useForm} from "react-hook-form";
import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {useCustomer} from "../../services/auth.tsx";
import {useMutationUpdateCustomer} from "../../services/api-customer.ts";
import {OrderBox} from "../../components/orders/orders-elements.tsx";
import {TermsLightbox} from "../../components/terms-lightbox.tsx";
import {OrderInput} from "../../components/OrderInput.tsx";
import {CustomerServiceButton} from "../../components/stage-elements.tsx";

export const Route = createFileRoute('/_auth/register')({
  component: RegisterScreen,
});

const labels = {
  letsMeet: 'בואו נכיר 😎',
  letsGo: 'יוצאים לדרך!',
  firstName: 'שם פרטי?',
  lastName: 'שם משפחה?',
  errorFirstName: 'נא להזין שם פרטי',
  errorLastName: 'נא להזין שם משפחה',
}

type NewCustomerFormData = {
  firstName: string;
  lastName: string;
};

export function RegisterScreen() {
  const router = useRouter()
  const customer = useCustomer();
  const updateCustomerMutation = useMutationUpdateCustomer();
  const [termsOpen, setTermsOpen] = useState(false);
  const form = useForm<NewCustomerFormData>({
    defaultValues: {
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
    }
  });
  const onSubmit = form.handleSubmit(async () => {
    await updateCustomerMutation.mutateAsync({
      firstName: form.getValues('firstName'),
      lastName: form.getValues('lastName'),
    });
    setTimeout(() => {
      // @ts-expect-error
      router.navigate({
        to: `/orders`,
        resetScroll: true,
      });
    });
  });
  const onTermsCancel = () => {
    setTermsOpen(false);
  }
  const onTermsOk = () => {
    setTermsOpen(false);
    onSubmit();
  }
  const onClickNewOrder = async () => {
    setTermsOpen(true);
  }
  return (
    <OrderBox title={labels['letsMeet']}><Stack gap={2} sx={{
      padding: 2,
      // alignItems: "center",
    }}>
      <TermsLightbox open={termsOpen} onOk={onTermsOk} onCancel={onTermsCancel} onClose={onTermsCancel} />
      <Controller
        control={form.control}
        name="firstName"
        rules={{required: labels['errorFirstName']}}
        render={({field}) => (
          <OrderInput title={labels['firstName']} required>
            <TextField
              {...field}
              autoFocus={true}
              tabIndex={1}

            />
          </OrderInput>
        )}/>
      <Controller
        control={form.control}
        name="lastName"
        rules={{required: labels['errorLastName']}}
        render={({field}) => (
          <OrderInput title={labels['lastName']} required>
            <TextField
              {...field}
              tabIndex={2}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  form.handleSubmit(onClickNewOrder)();
                }
              }}
            />
          </OrderInput>
        )}/>
      <Stack direction={'row'} gap={2} sx={{
        justifyContent: 'center',
      }}>
        <LoadingButton variant={'contained'}
                       tabIndex={3}
                       loading={form.formState.isSubmitting}
                       disabled={!form.formState.isValid}
                       size={"large"} onClick={onClickNewOrder}>{labels['letsGo']}</LoadingButton>
        <CustomerServiceButton/>
      </Stack>

    </Stack>
    </OrderBox>
  )
}
