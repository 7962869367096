import {createFileRoute, redirect, notFound} from "@tanstack/react-router";

export const Route = createFileRoute('/_auth/order/$orderId/_order/')({
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    throw redirect({
      to: '/order/$orderId/depart',
      params: {
        /* @ts-expect-error */
        orderId: params.orderId,
      }
    })
  },
  component: null,
})
