import {createFileRoute, useRouter} from "@tanstack/react-router";
import {useCategories} from "@tarimli-mono/api/src/shared/items-data.ts";
import {useCustomer} from "../../../../../services/auth.tsx";
import {TopCategory} from "../../../../../components/TopCategory.tsx";
import {IconFurniture} from "../../../../../components/icons/IconFurniture.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/items/categories')({
  component: OrderCategoriesScreen,
});

const labels = {
  categories: "קטגוריות",
}

export function OrderCategoriesScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const categories = useCategories();
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const router = useRouter()

  return (
    <TopCategory
      orderId={order.id}
      title={labels['categories']}
      icon={<IconFurniture sx={{ fontSize: 30, color: '#AD7AFF' }} />}
      categoriesData={categories}
      selectedItems={order.content.sortedItems}
      onClickBack={() => {
        router.history.back();
      }}
      onClickCategory={(catTitle: string) => {
        router.navigate({
          to: `/order/$orderId/items/categories/category`,
          params: {
            /* @ts-expect-error */
            orderId: String(order.id),
          },
          search: {
            /* @ts-expect-error */
            title: catTitle,
          }
        });
      }}
    />
  );
}
