import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {createFileRoute} from '@tanstack/react-router'
import {config} from "../../config.ts";

export const Route = createFileRoute('/_auth/checkout-error')({
  component: () => <CheckoutError />,
})

const labels = {
  title: 'סליחה תקלה :(',
  serverError: () => <><Typography sx={{color: 'red'}}> סליחה תקלה 😕 
    <br />אפשר ליצור קשר עם <br/><a
    href={config().customerServiceLink}>שירות הלקוחות שלנו בוואצאפ: 051-520-7969</a></Typography></>,
}

export function CheckoutError() {
  return (
    <Stack sx={{
      margin: 2,
      backgroundColor: 'white',
      borderRadius: 1,
      clipPath: 'padding-box',
    }}>
      <Stack sx={{
        padding: 2,
        justifyContent: 'space-between',
        backgroundColor: '#F3EBFF',
        alignItems: 'center',
      }}>
        <Typography sx={{
          fontSize: 30,
          textAlign: 'center',
          fontWeight: 800,
        }}>{labels.title}</Typography>
      </Stack>
      <Stack>
        <Typography sx={{
          fontSize: 20,
          textAlign: 'center',
          fontWeight: 800,
          p: 2,
        }}>{labels.serverError()}</Typography>
      </Stack>
    </Stack>
  )
}