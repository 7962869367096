import {config} from "../../config.ts";
import {ClientCustomerOwnOrder} from "../../model/client-order.ts";

export class AdobePdfClient {
  readyPromise: Promise<void>;
  adobeDCView: any;
  constructor(public order: ClientCustomerOwnOrder) {
    this.readyPromise = new Promise((resolve) => {
      if ((window as any).AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
        const script = document.createElement('script');
        script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
        script.async = true;
        document.body.appendChild(script);
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, viewerConfig) {
    const pdfConfig: any = {
      /* Pass your registered client id */
      clientId: config().pdfClientId,
    };
    if (divId) { /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      pdfConfig.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View(pdfConfig);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url: this.order.content.pdfFile,
          /*
          If the file URL requires some additional headers, then it can be passed as follows:-
          headers: [
              {
                  key: "<HEADER_KEY>",
                  value: "<HEADER_VALUE>",
              }
          ]
          */
        },
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName: `tarimli-order-${this.order.id}.pdf`,
        /* file ID */
        id: `order-${this.order.id}`,
      }
    }, viewerConfig);

    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId, filePromise, fileName) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View({
      /* Pass your registered client id */
      clientId: "8c0cd670273d451cbc9b351b11d22318",
      /* Pass the div id in which PDF should be rendered */
      divId,
    });

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* pass file promise which resolve to arrayBuffer */
        promise: filePromise,
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName: fileName
      }
    }, {});
  }

  registerSaveApiHandler() {
    /* Define Save API Handler */
    const saveApiHandler = (metaData, content, options) => {
      console.log(metaData, content, options);
      return new Promise(resolve => {
        /* Dummy implementation of Save API, replace with your business logic */
        setTimeout(() => {
          const response = {
            code: (window as any).AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {updatedAt: new Date().getTime()})
            },
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(
      (window as any).AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {}
    );
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      (window as any).AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      event => {
        console.log(event);
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      }
    );
  }
}

export default AdobePdfClient;
