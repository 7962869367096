import {useCustomer} from "../services/auth.tsx";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {IconWhatsapp} from "./icons/IconWhatsapp.tsx";

const labels = {
  talkToPartner: 'דבר עם המוביל',
  waMessageToPartner: (customerName: string, orderNumber: string) => `שלום כאן ${customerName}, רוצה לסגור איתך את הזמנה מס' ${orderNumber}. 

מתי תוכל להגיע?`,
}

export const TalkToPartner = ({partnerPhone, customerName, orderId, label}: {
  partnerPhone: string,
  customerName: string,
  orderId: string
  label?: string
}) => {
  const waUrl = `https://wa.me/${partnerPhone}?text=${encodeURIComponent(labels.waMessageToPartner(customerName, orderId))}`;
  return (
    <a href={waUrl} target={'_blank'}>
      <Button variant={'outlined'}>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <IconWhatsapp
            sx={{color: '#25D366', fontSize: 18, marginBottom: '0'}}/>
          {label || labels.talkToPartner}
        </Stack>
      </Button>
    </a>
  );
}