import {createFileRoute, useRouter, useSearch} from "@tanstack/react-router";
import {useCategoryItems} from "@tarimli-mono/api/src/shared/items-data.ts";
import {useCustomer} from "../../../../../services/auth.tsx";
import {CategoryItems} from "../../../../../components/TopCategory.tsx";
import {IconFurniture} from "../../../../../components/icons/IconFurniture.tsx";

export const Route = createFileRoute(`/_auth/order/$orderId/_order/items/categories/category`)({
  component: OrderContentScreen,
});

const labels= {
  categories: "קטגוריות",

}

export function OrderContentScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as {orderId: string};
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));

  const categoryTitle = useSearch({
    from: `/_auth/order/$orderId/_order/items/categories/category`,
    select: (search: { title: string }) => search.title,
  })
  const router = useRouter()
  const categoryItems = useCategoryItems(categoryTitle);
  return (
    <CategoryItems
      orderId={order.id}
      topTitle={labels['categories']}
      title={categoryTitle}
      icon={<IconFurniture sx={{ fontSize: 30, color: '#AD7AFF' }} />}
      itemsData={categoryItems}
      selectedItems={order.content.sortedItems}
      onClickBack={() => {
        router.history.back();
      }}
    />
  );
}
