import {createFileRoute, notFound, redirect} from '@tanstack/react-router'
import {useCustomer} from "../../../../services/auth.tsx";
import {AwaitingOffersScreen} from "../../../../components/orders/awaiting-offers.tsx";
import {OffersScreen} from "../../../../components/orders/order-offers.tsx";

export const Route = createFileRoute('/_auth/order/$orderId/_order/open')({
  component: OpenOrderScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'open') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
})

function OpenOrderScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const order = customer.findOrder(Number(routeParams.orderId));

  if (!order.hasEnoughOffers) {
    return <AwaitingOffersScreen order={order}/>
  } else {
    return <OffersScreen offers={order.submittedOffers}/>;
  }
}