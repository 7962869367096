import TarimliLogo from "/tarimli-logo.svg";
import {createFileRoute, Outlet, redirect, useRouter,} from '@tanstack/react-router'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useAuth, useCustomer, usePartner} from "../services/auth.tsx";
import {CustomerServiceButton} from "../components/stage-elements.tsx";

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({context, search, navigate, params, buildLocation, location}) => {
    if (!context.auth.isAuthenticated) {
      /* @ts-expect-error */
      throw redirect({
        to: '/login',
      })
    }
  },
  component: AuthLayout,
})

const labels = {
  logout: 'יציאה',
  greeting: (name: string) => ` היי ${name} 👋`,
}

function AuthLayout() {
  const auth = useAuth();
  return (
    <Stack gap={0}>
      {!auth.isAdmin ? <Header/> : null}
      <Outlet/>
    </Stack>
  );
}

const PartnerHeader = () => {
  const partner = usePartner();
  const greeting = partner?.businessName;
  return (
    <Stack direction={'row'} gap={1}
           sx={{flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} alignItems={'center'}
           justifyContent={'flex-end'}>
      <Typography sx={{fontSize: 14}}>{greeting}</Typography>
    </Stack>
  );
}

const CustomerHeader = () => {
  const customer = useCustomer();
  const greeting = customer?.firstName ? labels['greeting'](customer?.firstName) : customer?.formattedPhone;
  return (
    <Stack direction={'row'} gap={1}
           sx={{flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} alignItems={'center'}
           justifyContent={'flex-end'}>
      <Typography
        sx={{fontSize: 14, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{greeting}</Typography>
    </Stack>
  );
}

const Header = () => {
  const router = useRouter()
  const auth = useAuth()

  const onLogoClick = async () => {
    /* @ts-expect-error */
    await router.navigate({
      to: '/',
      resetScroll: true,
    })
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleLogout = () => {
    auth.logoutUser();
  }

  return <Stack direction="row" alignItems="center" gap={1} sx={{
    margin: 2
  }}>
    <Stack sx={{alignItems: 'center'}} gap={0}>
      <Box sx={{cursor: 'pointer'}} onClick={onLogoClick}>
        <img src={TarimliLogo} height={40}/>
      </Box>
      <CustomerServiceButton variant={'small'}/>
    </Stack>
    {auth.isCustomer ? <CustomerHeader/> : auth.isPartner ? <PartnerHeader/> : null}
    <Button variant="outlined" sx={{padding: 0}} color="primary" onClick={handleLogout}>{labels['logout']}</Button>
  </Stack>
}
