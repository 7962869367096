import {create} from 'zustand'
import {devtools, persist} from 'zustand/middleware'

export interface AppState {
  registrationToken: string | null,
  userToken: string | null,
  adminToken: string | null,
  setUserToken: (token: string | null) => void,
  setAdminToken: (token: string | null) => void,
  setRegistrationToken: (token: string | null) => void,
  clearRegistration: () => void,
  clear: () => void,
}

export const appState = create<AppState>()(devtools(
  persist(
    (set) => ({
      registrationToken: null,
      userToken: null,
      adminToken: null,
      clearRegistration: () => {
        set((state) => ({...state, registrationToken: null}))
      },
      setRegistrationToken: (registrationToken) => {
        return set((state) => ({...state, registrationToken}))
      },
      setUserToken: (userToken: string | null) => {
        return set((state) => ({...state, userToken}))
      },
      setAdminToken: (adminToken: string | null) => {
        return set((state) => ({...state, adminToken}))
      },
      clear: () => {
        appState.persist.clearStorage()
        set((state) => ({
          ...state,
          registrationToken: null,
          userToken: null,
          adminToken: null,
        }))
      },
    }),
    {
      name: 'app-store',
      partialize: (state) => {
        return state;
      },
    },
  ),
));

export const useAppState = appState;