import {createFileRoute, notFound, redirect, useNavigate} from "@tanstack/react-router";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {PdfLightbox} from "../../../../components/pdf/pdf.tsx";
import {useState} from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useMutationConfirmSubmittedOrder, useMutationUndoSubmitOrder} from "../../../../services/api-order.ts";
import {useRouteOrder} from "../../../../services/hooks.ts";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";

export const Route = createFileRoute('/_auth/order/$orderId/_order/submitted')({
  component: SubmittedOrderScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'submitted') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
})

const labels = {
  needToConfirmSubmittedOrder: () => (
    <Typography sx={{fontSize: 22, textAlign: 'center', lineHeight: '1.5em'}}>
      {'בעוד שבריר שניה,'}
      <br/>
      {'הבוט שלנו יפנה אליך לאישור פרטי הובלה.'}
      <br/><br/>
      {'לאחר מכן נוכל לשלוח לך את הצעות המחיר של נבחרת המובילים שלנו,'}
      <br/><br/>
      {' נפגש בוואטסאפ 😎'}
    </Typography>
  ),
  ourBotIsWaiting: 'הבוט שלנו מחכה לך לסיום...',
  showPdf: 'הצג קובץ PDF',
  showSummary: 'הצג סיכום הזמנה',
  pdfIntuctions: 'לחץ על הכפתור למטה כדי להציג את סיכום ההזמנה שלך בקובץ PDF',
  summary: 'אישור פרטים',
  pdfHeader: 'לסיום נא לאשר שהפרטים מדוייקים כפי שמופיעים במסמך',
  checkboxAgree: 'מאשרים נכונות הפרטים',
  send: 'אישור ושליחה',
  back: 'שינוי',
}

function SubmittedOrderScreen() {
  const order = useRouteOrder();
  const navigate = useNavigate();
  const mutationConfirm = useMutationConfirmSubmittedOrder();
  const mutationUndo = useMutationUndoSubmitOrder();
  const [agreeChecked, setAgreeChecked] = useState(false);
  
  const onClickConfirm = async () => {
    await mutationConfirm.mutateAsync({orderId: order.id});
    setTimeout(() => {
      // @ts-expect-error
      navigate({
        to: '/orders',
      });
    }, 100);
  }
  
  const handleBack = async () => {
    await mutationUndo.mutateAsync({orderId: order.id});
    setTimeout(() => {
      // @ts-expect-error
      navigate({
        to: '/orders',
      });
    }, 100);
  }

  return (
    <Stack>
      <PdfLightbox order={order}/>
      <Stack id="pdf-wrap" sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}>
        <Stack sx={{
          p: 1,
          alignItems: 'center',
          backgroundColor: '#C9C0FF',
        }}>
          <Stack direction={'row'} gap={1} sx={{
            alignItems: 'center',
          }}>
            <LoadingButton variant={'outlined'} loading={mutationUndo.isPending} onClick={handleBack}>
              <ChevronRightIcon sx={{fontSize: 40}}/>
              <Typography>
                {labels.back}
              </Typography>
            </LoadingButton>
            <Box sx={{flex: 1}}>
              <Typography sx={{textAlign: 'center', fontWeight: 600, fontSize: 20}}>
                {labels.pdfHeader}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Box id="pdf-container"></Box>
        <Stack sx={{
          alignItems: 'center',
          // backgroundColor: '#000000',
          p: 1,
        }}>
          <Stack
            direction={'row'}
            sx={{
              backgroundColor: '#C9C0FF',
              borderRadius: 1,
              p: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <FormControlLabel
              checked={agreeChecked}
              slotProps={{
                typography: {
                  sx: {
                    fontWeight: 600,
                    fontSize: 20,
                    display: 'flex',
                    flexShrink: 1,
                  }
                }
              }}
              label={labels.checkboxAgree} control={<Checkbox
              onChange={e => {
                setAgreeChecked(e.target.checked);
              }}
            />}/>
            <Box>
              <LoadingButton variant={'contained'} loading={mutationConfirm.isPending} onClick={onClickConfirm} disabled={!agreeChecked}>
                {labels.send}
              </LoadingButton>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}