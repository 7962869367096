import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {
  V1_FormGroup_DepartAddress,
  V1_FormGroup_DepartCrane,
  V1_FormGroup_DepartExternalSpace,
  V1_FormGroup_DepartHouseStructure,
  V1_FormGroup_DepartLift,
  V1_FormGroup_DepartParking
} from "./form-groups/v1-depart.tsx";
import {
  V1_FormGroup_ArriveAddress,
  V1_FormGroup_ArriveCrane,
  V1_FormGroup_ArriveHouseStructure,
  V1_FormGroup_ArriveLift,
  V1_FormGroup_ArriveParking
} from "./form-groups/v1-arrive.tsx";
import {
  V1_FormGroup_Boxes,
  V1_FormGroup_Date,
  V1_FormGroup_Items,
  V1_FormGroup_Summary
} from "./form-groups/v1-others.tsx";
import {V_test_FormGroup_Test1} from "./form-groups/v-test-groups.tsx";

const v1_orderFormStages = {
  depart: 'depart',
  arrive: 'arrive',
  date: 'date',
  items: 'items',
  boxes: 'boxes',
  review: 'review',
};
const v_test_orderFormStages = {
  depart: 'depart',
  arrive: 'arrive',
  date: 'date',
  items: 'items',
  boxes: 'boxes',
  review: 'review',
};

type V1_OrderFormStages = keyof typeof v1_orderFormStages;
type V_test_OrderFormStages = keyof typeof v_test_orderFormStages;
type OrderFormStages = V1_OrderFormStages | V_test_OrderFormStages;

export const V1_DepartForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V1_FormGroup_DepartAddress content={content}/>
    <Divider/>
    <V1_FormGroup_DepartParking content={content}/>
    <Divider/>
    <V1_FormGroup_DepartLift content={content}/>
    <Divider/>
    <V1_FormGroup_DepartCrane content={content}/>
    <Divider/>
    <V1_FormGroup_DepartExternalSpace content={content}/>
    <Divider/>
    <V1_FormGroup_DepartHouseStructure content={content}/>
  </Stack>;
}

export const V_test_DepartForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V_test_FormGroup_Test1 content={content}/>
    <Divider/>
    <V1_FormGroup_DepartAddress content={content}/>
  </Stack>;
}

export const V1_ArriveForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V1_FormGroup_ArriveAddress content={content}/>
    <Divider/>
    <V1_FormGroup_ArriveParking content={content}/>
    <Divider/>
    <V1_FormGroup_ArriveCrane content={content}/>
    <Divider/>
    <V1_FormGroup_ArriveLift content={content}/>
    <Divider/>
    <V1_FormGroup_ArriveHouseStructure content={content}/>
  </Stack>;
}

export const V_test_ArriveForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V1_FormGroup_ArriveParking content={content}/>
  </Stack>;
}

export const V1_DateForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V1_FormGroup_Date content={content}/>
  </Stack>;
}

export const V1_ItemsForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V1_FormGroup_Items content={content}/>
  </Stack>
}

export const V1_BoxesForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
    <V1_FormGroup_Boxes content={content}/>
  </Stack>
}

export const V1_SummaryForm = ({content}: { content: OrderContent }) => {
  return <Stack gap={2}>
   <V1_FormGroup_Summary content={content}/>
  </Stack>
}

export const V1_OrderForm = ({content, stage}: { content: OrderContent, stage: V1_OrderFormStages }) => {
  switch (stage) {
    case 'depart':
      return <V1_DepartForm content={content}/>;
    case 'arrive':
      return <V1_ArriveForm content={content}/>;
    case 'date':
      return <V1_DateForm content={content}/>;
    case 'items':
      return <V1_ItemsForm content={content}/>;
    case 'boxes':
      return <V1_BoxesForm content={content}/>;
    case 'review':
      return <V1_SummaryForm content={content}/>;
    default:
      return null;
  }
}

export const V_test_OrderForm = ({content, stage}: { content: OrderContent, stage: V_test_OrderFormStages }) => {
  switch (stage) {
    case 'depart':
      return <V_test_DepartForm content={content}/>;
    case 'arrive':
      return <V_test_ArriveForm content={content}/>;
    case 'date':
      return <V1_DateForm content={content}/>;
    case 'items':
      return <V1_ItemsForm content={content}/>;
    case 'boxes':
      return <V1_BoxesForm content={content}/>;
    case 'review':
      return <V1_SummaryForm content={content}/>;
    default:
      return null;
  }
}

export const OrderForm = ({content, stage}: { content: OrderContent, stage: OrderFormStages }) => {
  switch (content.version) {
    case 'test':
      return <V_test_OrderForm content={content} stage={stage}/>
    default:
      return <V1_OrderForm content={content} stage={stage}/>
  }
}