import TarimliLogo from "/tarimli-logo.svg";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import {createFileRoute} from '@tanstack/react-router'

export const Route = createFileRoute('/register-partner/thank-you')({
  component: () => <RegisterPartnerThankYou/>,
})

const labels = {
  thankYouHeader: () => <p>
    תודה רבה,<br />נחזור אליך בהקדם!
    <br />
    <span style={{fontSize: 40}}>😎</span>

  </p>
}

export function RegisterPartnerThankYou() {
  return (
    <Stack gap={4} sx={{
      margin: '0 auto',
      alignSelf: 'center',
      justifySelf: 'center',
      padding: 2,
      alignItems: 'stretch',
      justifyContent: 'center',
      maxWidth: 600,
      textAlign: 'center',
    }}>
      <img src={TarimliLogo} height={60}/>
      <Typography sx={{fontWidth: 600, fontSize: 22}}>{labels.thankYouHeader()}</Typography>
    </Stack>
  )
}