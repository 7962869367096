import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {Checkbox, FormControlLabel, FormHelperText, MenuItem, Select} from '@mui/material';
import {Controller, useFormContext} from 'react-hook-form';
import {useEffect} from 'react';
import {config} from "../config.ts";

export type PaymentForm = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  paymentsNum: number;
  saveCard: boolean;
}

const labels = {
  offer: () => <Typography sx={{fontWeight: 600, fontSize: 30, textAlign: 'center'}}>החיוב יבוצע לאחר אישורך<br/>כי
    ההובלה הסתיימה בהצלחה</Typography>,
  cancel: 'ביטול',
  next: 'תשלום',
  nis: '₪',
  amountToPayTotal: 'סכום',
  amountToPayToday: 'מקדמה לתשלום היום',
  amountToPayLater: 'תשלום נוסף לאחר ההובלה',
  disclaimer: 'לאחר ההובלה תתבקשו לאשר את החיוב.',
  payerDetails: 'פרטי המשלם',
  firstName: 'שם פרטי',
  lastName: 'שם משפחה',
  ssn: 'מספר זהות',
  phone: 'טלפון נייד (קידומת 05X)',
  address: 'כתובת',
  email: 'אימייל (לקבלת חשבונית)',
  errorFirstName: 'נא למלא שם פרטי',
  errorLastName: 'נא למלא שם משפחה',
  errorSsn: 'נא למלא מספר זהות',
  errorPhone: 'נא למלא טלפון',
  errorAddress: 'נא למלא כתובת',
  errorEmail: 'נא למלא אימייל',
  errorInvalidPhone: 'נא למלא מספר בן 10 ספרות בצורת: 05XXXXXXXX',
  errorInvalidEmail: 'נא למלא אימייל תקין',
  phoneInstructions: 'נא למלא מספר בן 10 ספרות בצורת: 05XXXXXXXX',
  retry: 'נסו שוב',
  back: 'חזרה',
  paymentsNum: 'מספר תשלומים',
  saveCard: 'שמור פרטי כרטיס לחיוב לאחר סיום ההובלה',
  // saveCardDisclaimer: 'אני מאשר שפרטי הכרטיס שלי יישמר לחיוב לאחר סיום ההובלה.',
  // pleaseCheckDisclaimer: 'נא לאשר את הסכמתך',
  serverError: () => <><Typography sx={{color: 'red'}}> סליחה תקלה 😕 אפשר לנסות שוב<br/>אם לא עוזר אפשר ליצור קשר
    עם <br/><a
      href={config().customerServiceLink}>שירות הלקוחות שלנו בוואצאפ: 051-520-7969</a></Typography></>,
}

export function PayerDetails({askToSaveCard, askPaymentsNum}: { askToSaveCard: boolean, askPaymentsNum }) {
  // const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const validatePhone = (value) => {
    const p = value?.replace(/[\s-]/g, '');
    if (/^05\d{8}/.test(p || '')) {
      return true;
    }
    return false;
  }
  const {
    watch,
    setError,
    control,
    handleSubmit,
    clearErrors,
    reset,
    trigger,
    formState,
  } = useFormContext<PaymentForm>();
  const {errors, isValid, isSubmitting} = formState;
  const watchPhone = watch('phone');
  useEffect(() => {
    if (!validatePhone(watchPhone)) {
      setError('phone', {
        message: labels.errorInvalidPhone
      });
    } else {
      clearErrors('phone');
      trigger();
    }
  }, [watchPhone]);
  // useEffect(() => {
  //   if (askToSaveCard) {
  //     if (!disclaimerChecked) {
  //       setError('root.disclaimerChecked', {
  //         type: 'custom',
  //       });
  //     } else {
  //       clearErrors('root.disclaimerChecked');
  //       trigger();
  //     }
  //   }
  // }, [disclaimerChecked]);
  return (
    <Stack gap={4} sx={{
      padding: 2,
      backgroundColor: 'white',
      borderRadius: 1,
    }}>
      <Stack direction={'row'} sx={{alignItems: 'center'}} gap={2}>
        <Typography sx={{flexGrow: 1}}>{labels['firstName']} <span style={{color: 'red'}}>*</span></Typography>
        <Controller
          name="firstName"
          rules={{required: labels['errorFirstName'], minLength: 1}}
          control={control}
          render={({field}) => <Stack>
            <TextField {...field} error={!!errors.firstName}
                       helperText={errors.firstName ? labels['errorFirstName'] : ''}/>
          </Stack>}
        />
      </Stack>
      <Stack direction={'row'} sx={{alignItems: 'center'}} gap={2}>
        <Typography sx={{flexGrow: 1}}>{labels['lastName']} <span style={{color: 'red'}}>*</span></Typography>
        <Controller
          name="lastName"
          rules={{required: labels['errorLastName'], minLength: 1}}
          control={control}
          render={({field}) => <>
            <TextField {...field} error={!!errors.lastName}
                       helperText={errors.lastName ? labels['errorLastName'] : ''}/>
          </>}
        />
      </Stack>
      <Stack direction={'row'} sx={{alignItems: 'center'}} gap={2}>
        <Typography sx={{flexGrow: 1}}>{labels['phone']} <span style={{color: 'red'}}>*</span></Typography>
        <Controller
          name="phone"
          control={control}
          render={({field}) => <Stack>
            <TextField sx={{flexShrink: 1}} {...field} error={!!errors.phone}
                       helperText={errors.phone?.message || ''}
                       InputProps={{
                         style: {
                           direction: 'ltr',
                         },
                         // startAdornment:
                         //   (<InputAdornment position="start">
                         //     <PhoneIphoneIcon/>
                         //   </InputAdornment>)
                       }}
                       FormHelperTextProps={{
                         style: {
                           width: 180,
                         }
                       }}
            />
            {!errors.phone && <FormHelperText sx={{width: 180}}>{labels.phoneInstructions}</FormHelperText>}
          </Stack>}
        />
      </Stack>
      <Stack direction={'row'} sx={{alignItems: 'center'}} gap={2}>
        <Typography sx={{flexGrow: 1}}>{labels['email']} <span style={{color: 'red'}}>*</span></Typography>
        <Controller
          name="email"
          rules={{
            required: labels['errorEmail'], pattern: {
              value: /\S+@\S+\.\S+/,
              message: labels.errorInvalidEmail,
            }
          }}
          control={control}
          render={({field}) => <>
            <TextField {...field} error={!!errors.email} helperText={errors.email?.message || ''}/>
          </>}
        />
      </Stack>
      {askPaymentsNum ? <Stack direction={'row'} sx={{alignItems: 'center'}} gap={2}>
        <Typography sx={{flex: 1}}>{labels.paymentsNum}</Typography>
        <Controller
          name="paymentsNum"
          control={control}
          render={({field}) => <>
            <Select {...field} sx={{flex: 1}}>
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(num => {
                  return <MenuItem key={num} value={String(num)}>{num}</MenuItem>
                })
              }
            </Select>
          </>}
        />

      </Stack> : null}
      {askToSaveCard ?
        <Stack>
          <Stack direction={'row'} sx={{alignItems: 'center'}} gap={1}>
            <Controller
              name="saveCard"
              control={control}
              render={({field}) => <>
                <FormControlLabel label={labels.saveCard} control={
                  <Checkbox {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                  />}/>
              </>}
            />
          </Stack>
          {/*{errors.root?.disclaimerChecked ?*/}
          {/*  <Typography sx={{color: 'red', fontSize: 14}}>{labels.pleaseCheckDisclaimer}</Typography> : null}*/}
        </Stack> : null
      }
    </Stack>
  );
}