import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function IconFurniture(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="72" height="70" viewBox="0 0 72 70" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.9674 59.1347L29.6243 50.3848C34.6433 49.2051 38.4025 44.6189 38.4025 39.1455V34.4627C38.3995 31.4304 35.9786 28.9653 32.9305 28.9653C29.9516 28.9653 27.5306 31.4304 27.5306 34.4627C27.5306 37.3472 25.2446 39.6916 22.4307 39.6916H15.9718C13.1578 39.6916 10.8718 37.3441 10.8718 34.4627C10.8688 31.4304 8.4479 28.9653 5.3999 28.9653C2.42094 28.9653 0 31.4304 0 34.4627V39.1455C0 44.6187 3.75893 49.2046 8.77814 50.3848L15.4351 59.1378L8.6462 68.0599C8.3672 68.425 8.3192 68.9168 8.5232 69.3272C8.72719 69.7405 9.14419 70 9.60015 70H14.1121C14.4871 70 14.8381 69.825 15.0661 69.5263L19.2 64.0922L23.331 69.5263C23.556 69.825 23.907 70 24.282 70H28.7999C29.2559 70 29.6729 69.7405 29.8769 69.3272C30.0809 68.9168 30.0329 68.425 29.7539 68.0599L22.9674 59.1347ZM16.5445 50.6892H21.8574L19.1995 54.1831L16.5445 50.6892ZM21.4554 57.1491L20.7084 56.1685L24.8813 50.6892H26.3693L21.4554 57.1491ZM2.39997 39.148V34.4653C2.39997 32.7636 3.74396 31.3786 5.46894 31.3786C7.12489 31.3786 8.46887 32.7636 8.46887 34.4622C8.46887 38.6773 11.8318 42.1049 15.9688 42.1049H22.4277C26.5647 42.1049 29.9276 38.6773 29.9276 34.4622C29.9306 32.7636 31.2746 31.3786 32.9996 31.3786C34.6555 31.3786 35.9995 32.7636 35.9995 34.4622V39.145C35.9995 44.1807 32.0006 48.2749 27.0839 48.2749H11.3163C6.39936 48.2749 2.39997 44.1804 2.39997 39.148ZM13.5182 67.5851H12.0302L16.9441 61.1252L17.6911 62.1058L13.5182 67.5851ZM24.876 67.5851L12.0275 50.6886H13.5245L26.3707 67.5851H24.876Z"
          fill="currentColor"/>
        <path
          d="M71.8554 0.633617C71.6454 0.244391 71.2404 0 70.7994 0H20.4C19.959 0 19.554 0.244399 19.344 0.633617C19.134 1.02284 19.155 1.49957 19.398 1.87071L22.548 6.69829C22.767 7.03622 23.145 7.24139 23.55 7.24139H24V29.8221C24 30.4889 24.537 31.029 25.2 31.029C25.863 31.029 26.3999 30.4889 26.3999 29.8221V7.24139H44.4001V48.2758H37.9682C37.3052 48.2758 36.7682 48.8159 36.7682 49.4827C36.7682 50.1495 37.3052 50.6896 37.9682 50.6896H44.4001V57.9309H27.5043C26.8413 57.9309 26.3043 58.471 26.3043 59.1378C26.3043 59.8046 26.8413 60.3447 27.5043 60.3447H28.8003V62.4206C28.8003 63.0964 29.3493 63.6245 30.0243 63.6275C29.9943 63.7361 29.9763 63.8477 29.9763 63.9654C29.9763 64.6322 30.5133 65.1723 31.1763 65.1723H37.2003C37.8633 65.1723 38.4003 64.6322 38.4003 63.9654V60.3447H52.8001V63.9654C52.8001 64.6322 53.3371 65.1723 54.0001 65.1723H61.2C61.863 65.1723 62.4 64.6322 62.4 63.9654V60.3447H67.1999C67.8629 60.3447 68.3999 59.8046 68.3999 59.1378V49.4827C68.3999 48.8159 67.8629 48.2758 67.1999 48.2758V7.24139H67.6499C68.0549 7.24139 68.4329 7.03622 68.6519 6.69829L71.8019 1.87071C72.0449 1.49657 72.0654 1.02284 71.8554 0.633617ZM35.9994 62.7587H31.1755H31.1515C31.1845 62.6501 31.1995 62.5385 31.1995 62.4208V60.3449H35.9995L35.9994 62.7587ZM59.9991 62.7587H55.1992V60.3449H59.9991V62.7587ZM65.9991 57.9311H46.7993V50.6898H65.9991V57.9311ZM64.7991 48.276H46.7989V7.2416H64.7991V48.276ZM67.0011 4.82782H24.1978L22.6229 2.41403H68.5764L67.0011 4.82782Z"
          fill="currentColor"/>
        <path
          d="M51.5989 28.9659C52.2619 28.9659 52.7989 28.4258 52.7989 27.759V22.9314C52.7989 22.2646 52.2619 21.7245 51.5989 21.7245C50.9359 21.7245 50.3989 22.2646 50.3989 22.9314V27.759C50.3989 28.4258 50.9359 28.9659 51.5989 28.9659Z"
          fill="currentColor"/>
        <path
          d="M40.7994 27.7586V22.931C40.7994 22.2642 40.2624 21.7241 39.5994 21.7241C38.9364 21.7241 38.3994 22.2642 38.3994 22.931V27.7586C38.3994 28.4254 38.9364 28.9655 39.5994 28.9655C40.2624 28.9655 40.7994 28.4254 40.7994 27.7586Z"
          fill="currentColor"/>
        <path
          d="M53.9997 55.5167H58.7996C59.4626 55.5167 59.9996 54.9766 59.9996 54.3098C59.9996 53.643 59.4626 53.1029 58.7996 53.1029H53.9997C53.3367 53.1029 52.7997 53.643 52.7997 54.3098C52.7997 54.9766 53.3367 55.5167 53.9997 55.5167Z"
          fill="currentColor"/>
        <path
          d="M31.1995 54.3102C31.1995 54.977 31.7365 55.5171 32.3994 55.5171H37.1994C37.8624 55.5171 38.3994 54.977 38.3994 54.3102C38.3994 53.6434 37.8624 53.1033 37.1994 53.1033H32.3994C31.7365 53.1033 31.1995 53.6434 31.1995 54.3102Z"
          fill="currentColor"/>
      </svg>
    </SvgIcon>
  );
}