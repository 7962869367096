import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
  ApiCancelOfferResponse,
  ApiCurrentUserResponse,
  ApiDeclineOfferResponse,
  ApiSubmitOfferResponse
} from "@tarimli-mono/api/src/shared/api-types.ts";
import {authPost} from "./api-call.ts";

export const useMutationConfirmTerms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return authPost<ApiCurrentUserResponse>('/partner/confirm-terms');
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    }
  });
}

export const useMutationSubmitOffer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({offerId, amount}: {offerId: number, amount: number}) => {
      return authPost<ApiSubmitOfferResponse>('/partner/submit-offer', {offerId, amount});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    }
  });
}

export const useMutationDeclineOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({offerId, message}: {offerId: number, message?: string}) => {
      return authPost<ApiDeclineOfferResponse>('/partner/decline-offer', {offerId, message});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

export const useMutationCancelOffer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({message, offerId}: {message?: string, offerId: number}) => {
      return authPost<ApiCancelOfferResponse>('/partner/cancel-offer', {offerId, message});
    },
    onError: (error) => {
      console.error(error);
      throw error;
    },
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(['current-user'], data);
      }
    }
  });
}
