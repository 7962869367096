import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid2';
import Rating from "@mui/material/Rating";
import * as React from "react";
import {Controller, useForm} from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import {OrderReview} from "@tarimli-mono/api/src/shared/client-types.ts";
import {useMutationCreateOrder, useMutationRateOrder} from "../../services/api-order.ts";
import {ClientCustomerOwnOrder} from "../../model/client-order.ts";
import {ClientCustomer} from "../../model/client-customer.ts";

const labels = {
  title: 'תודה שבחרתם תריםלי',
  rate: 'ספרו לנו איך היה לכם :)',
  quality: 'איכות ההובלה',
  timing: 'עמידה בזמנים',
  service: 'שירות המובילים',
  experience: 'חווית השימוש בתריםלי',
  doYouRecommend: 'האם תמליץ על תריםלי לחברים או משפחה?',
  recommend1: 'לא נראה לנו',
  recommend3: 'לא בטוחים',
  recommend5: 'בטח!',
  comments: 'עוד משהו שבא לכם לספר לנו?',
  name: 'השם שלי',
  iFinished: 'סיימתי',
  thumbsUp: '👍',
  newOrder: 'הובלה חדשה',
  reviewerName: 'השם שלי:',
}

const EntryRating = ({value, onChange}: {
  value: number,
  onChange: (event: React.SyntheticEvent, value: number | null) => void
}) => {
  return (
    <Rating
      sx={{
        '& .MuiRating-iconFilled': {
          color: '#ff6d75',
        },
        '& .MuiRating-iconHover': {
          color: '#ff3d47',
        },
      }}
      value={value}
      onChange={onChange}
      precision={1}
      size={'large'}
      icon={<FavoriteIcon fontSize="inherit"/>}
      emptyIcon={<FavoriteBorderIcon fontSize="inherit"/>}
    />
  );
}

export function RateOrderScreen({order, customer}: { order: ClientCustomerOwnOrder, customer: ClientCustomer }) {
  const mutationRateOrder = useMutationRateOrder();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState,
    watch,
    setValue,
    reset,
  } = useForm<Partial<OrderReview>>({
    defaultValues: {
      quality: 0,
      timing: 0,
      service: 0,
      experience: 0,
      recommend: 0,
      name: customer.firstName,
      comments: '',
    },
  });
  const onSubmit = async (review: Partial<OrderReview>) => {
    await mutationRateOrder.mutateAsync({
      orderId: order.id,
      review,
    });
  }
  return (
    <Stack sx={{
      margin: 2,
      backgroundColor: 'white',
      borderRadius: 1,
      clipPath: 'padding-box',
    }}>
      <Stack sx={{
        justifyContent: 'space-between',
        backgroundColor: '#F3EBFF',
        alignItems: 'center',
        p: 2,
      }}>
        <Typography sx={{
          fontSize: 30,
          textAlign: 'center',
          fontWeight: 800,
        }}>{labels.title}</Typography>
      </Stack>
      <Stack sx={{}}>
        <Typography sx={{
          fontSize: 20,
          textAlign: 'center',
          fontWeight: 800,
          p: 2,
        }}>{labels.rate}</Typography>
        <Grid container spacing={2} sx={{display: 'flex', flexGrow: 1, px: 4, py: 2}}>
          <Grid size={7} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Typography sx={{fontSize: 20}}>{labels.quality}</Typography>
          </Grid>
          <Grid size={5} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Controller
              control={control}
              name="quality"
              render={({field, fieldState}) => {
                return (
                  <EntryRating value={field.value} onChange={(e, v) => {
                    field.onChange(v)
                  }}/>
                );
              }}/>
          </Grid>
          <Grid size={7} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Typography sx={{fontSize: 20}}>{labels.timing}</Typography>
          </Grid>
          <Grid size={5} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Controller
              control={control}
              name="timing"
              render={({field, fieldState}) => {
                return (
                  <EntryRating value={field.value} onChange={(e, v) => {
                    field.onChange(v)
                  }}/>
                );
              }}/>
          </Grid>
          <Grid size={7} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Typography sx={{fontSize: 20}}>{labels.service}</Typography>
          </Grid>
          <Grid size={5} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Controller
              control={control}
              name="service"
              render={({field, fieldState}) => {
                return (
                  <EntryRating value={field.value} onChange={(e, v) => {
                    field.onChange(v)
                  }}/>
                );
              }}/>
          </Grid>
          <Grid size={7} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Typography sx={{fontSize: 20}}>{labels.experience}</Typography>
          </Grid>
          <Grid size={5} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
            <Controller
              control={control}
              name="experience"
              render={({field, fieldState}) => {
                return (
                  <EntryRating value={field.value} onChange={(e, v) => {
                    field.onChange(v)
                  }}/>
                );
              }}/>
          </Grid>
        </Grid>
        <Stack gap={2} sx={{px: 4, py: 2}}>
          <Stack>
            <Typography sx={{fontSize: 20}}>{labels.doYouRecommend}</Typography>
            <Controller
              control={control}
              name="recommend"
              render={({field, fieldState}) => {
                return (
                  <RadioGroup  {...field}>
                    <Stack direction={'row'}>
                      <FormControlLabel value={'5'} control={<Radio checked={String(field.value) === '5'}/>}
                                        label={labels.recommend5}/>
                      <FormControlLabel value={'3'} control={<Radio checked={String(field.value) === '3'}/>}
                                        label={labels.recommend3}/>
                      <FormControlLabel value={'1'} control={<Radio checked={String(field.value) === '1'}/>}
                                        label={labels.recommend1}/>
                    </Stack>
                  </RadioGroup>
                );
              }}/>
          </Stack>
          <Controller
            name="comments"
            control={control}
            render={({field}) => {
              return (
                <TextField
                  multiline
                  rows={2}
                  {...field}
                  slotProps={{
                    htmlInput: {maxLength: 500}
                  }}
                  placeholder={labels.comments}
                />
              );
            }}/>
          <Typography sx={{fontSize: 16}}>{labels.reviewerName}</Typography>
          <Controller
            name="name"
            control={control}
            render={({field}) => {
              return (
                <TextField
                  {...field}
                  slotProps={{
                    htmlInput: {maxLength: 500}
                  }}
                  placeholder={labels.name}
                />
              );
            }}/>
          <LoadingButton
            loading={mutationRateOrder.isPending}
            disabled={mutationRateOrder.isPending || !formState.isValid}
            onClick={handleSubmit(onSubmit)} variant={'contained'}
            sx={{alignSelf: 'center'}}>{labels.iFinished}</LoadingButton>
        </Stack>

      </Stack>
    </Stack>
  )
}

export function CreateNewOrderScreen() {
  const mutationCreateOrder = useMutationCreateOrder();
  const onClickNewOrder = async () => {
    const response = await mutationCreateOrder.mutateAsync();
    if (response.newOrderId) {
      window.location.href = `/order/${response.newOrderId}`;
    }
  }
  return (
  <Stack sx={{
    margin: 2,
    backgroundColor: 'white',
    borderRadius: 1,
    clipPath: 'padding-box',
  }}>
    <Stack sx={{
      justifyContent: 'space-between',
      backgroundColor: '#F3EBFF',
      alignItems: 'center',
      p: 2,
    }}>
      <Typography sx={{
        fontSize: 30,
        textAlign: 'center',
        fontWeight: 800,
      }}>{labels.title}</Typography>
    </Stack>
    <Stack sx={{alignItems: 'center', p: 4}} gap={4}>
      <Typography sx={{fontSize: 50}}>{labels.thumbsUp}</Typography>
      <LoadingButton variant={'contained'} onClick={onClickNewOrder} loading={mutationCreateOrder.isPending}>
        {labels.newOrder}
      </LoadingButton>
    </Stack>
  </Stack>
  );
}