import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import {Controller, useFormContext} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import Stack from "@mui/material/Stack";
import {OrderInput} from "../../OrderInput.tsx";
import TextField from "@mui/material/TextField";

export const V_test_FormGroup_Test1 = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  return <Stack gap={2}>
    <Stack direction={"row"} gap={2} alignItems={'flex-end'}>
      <Controller
        name="v_test_field1"
        control={control}
        render={({field}) => <>
          <OrderInput title={content.entries.v_test_field1.label} required>
            <TextField
              sx={{maxWidth: 200}}
              {...field} />
          </OrderInput>
        </>}
      />
    </Stack>
  </Stack>
}

