import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import {Controller, useFormContext} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {OrderInput} from "../../OrderInput.tsx";
import AddressAutocomplete from "../../AddressAutocomplete.tsx";

export const V1_FormGroup_ArriveAddress = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    formState,
  } = formContext;
  const {errors} = formState;
  return <Stack gap={2}>
    <Controller
      rules={{required: content.entries.arriveAddress.formErrorMessageRequired, minLength: 2}}
      name="arriveAddress"
      control={control}
      render={({field}) => <>
        <OrderInput title={content.entries.arriveAddress.formLabel} required>
          <AddressAutocomplete onChange={value => {
            field.onChange(value);
          }} initialValue={field.value}/>
        </OrderInput>
      </>}
    />
    <Stack direction={"row"} gap={2}>
      <Controller
        name="arriveFloor"
        rules={{
          required: content.entries.arriveFloor.formErrorMessageRequired,
          minLength: 1,
          maxLength: {
            value: content.entries.arriveFloor.maxLen,
            message: content.entries.arriveFloor.formErrorMessageMaxLen
          },
        }}
        control={control}
        render={({field, fieldState}) => {
          return <OrderInput title={content.entries.arriveFloor.label} required>
            <TextField
              sx={{maxWidth: 200}}
              {...field}
              helperText={fieldState.invalid ? fieldState.error.message : ""}
              error={fieldState.invalid}
            />
          </OrderInput>
        }}/>
    </Stack>
  </Stack>
}

export const V1_FormGroup_ArriveParking = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchParking = watch('arriveParking');
  return <Stack gap={2}>
    <Controller
      name="arriveParking"
      rules={{required: content.entries.arriveParking.formErrorMessageRequired}}
      control={control}
      render={({field}) => <>
        <OrderInput title={content.entries.arriveParking.formLabel} required>
          <RadioGroup {...field}>
            {
              content.entries.arriveParking.options.map(option => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Radio checked={field.value === option.value}/>}
                                  label={option.formLabel}/>
              ))
            }
          </RadioGroup>
        </OrderInput>
      </>}
    />
    <Controller
      name="arriveParkingComments"
      rules={{
        required: watchParking === 'maybe' ? content.entries.arriveParkingComments.formErrorMessageRequired : undefined,
        maxLength: {
          value: content.entries.arriveParkingComments.maxLen,
          message: content.entries.arriveParkingComments.formErrorMessageMaxLen
        }
    }}
      control={control}
      render={({field, fieldState}) => {
        return <>
          <Box sx={{display: ['no', 'maybe'].includes(watchParking) ? 'block' : 'none'}}>
            <OrderInput title={content.entries.arriveParkingComments.formLabel} required={['maybe'].includes(watchParking)}>
              <TextField
                {...field}
                helperText={fieldState.invalid ? `${field.value.length}/${content.entries.arriveParkingComments.maxLen} | ${fieldState.error.message}` : field.value.length > content.entries.arriveParkingComments.maxLen / 10 ? `${field.value.length}/${content.entries.arriveParkingComments.maxLen}` : ''}
                error={fieldState.invalid}
              />
            </OrderInput>
          </Box>
        </>
      }}
    />
  </Stack>
}

export const V1_FormGroup_ArriveLift = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
  } = formContext;
  return <Stack gap={2}>
    <Controller
      name="arriveLift"
      rules={{required: content.entries.arriveLift.formErrorMessageRequired}}
      control={control}
      render={({field}) => <>
        <OrderInput title={content.entries.arriveLift.formLabel} required>
          <RadioGroup {...field}>
            {
              content.entries.arriveLift.options.map(option => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Radio checked={field.value === option.value}/>}
                                  label={option.formLabel}/>
              ))
            }
          </RadioGroup>
        </OrderInput>
      </>}
    />
  </Stack>;
}

export const V1_FormGroup_ArriveCrane = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchCrane = watch('arriveCrane');
  return <Stack gap={2}>
    <Controller
      name="arriveCrane"
      rules={{required: content.entries.arriveCrane.formErrorMessageRequired}}
      control={control}
      render={({field}) => (
        <OrderInput title={content.entries.arriveCrane.formLabel} required>
          <RadioGroup {...field}>
            {
              content.entries.arriveCrane.options.map(option => (
                <FormControlLabel key={option.value} value={option.value}
                                  control={<Radio checked={field.value === option.value}/>}
                                  label={option.formLabel}/>
              ))
            }
          </RadioGroup>
        </OrderInput>
      )}/>
    <Controller
      name="arriveCraneComments"
      rules={{
        required: ['maybe'].includes(watchCrane) ? content.entries.arriveCraneComments.formErrorMessageRequired : undefined,
        maxLength: {
          value: content.entries.arriveCraneComments.maxLen,
          message: content.entries.arriveCraneComments.formErrorMessageMaxLen
        }
      }}
      control={control}
      render={({field, fieldState}) => {
        return <>
          <Box sx={{display: ['yes', 'maybe'].includes(watchCrane) ? 'block' : 'none'}}>
            <OrderInput title={content.entries.arriveCraneComments.formLabel} required={['maybe'].includes(watchCrane)}>
              <TextField
                {...field}
                helperText={fieldState.invalid ? `${field.value.length}/${content.entries.arriveCraneComments.maxLen} | ${fieldState.error.message}` : field.value.length > content.entries.arriveCraneComments.maxLen / 10 ? `${field.value.length}/${content.entries.arriveCraneComments.maxLen}` : ''}
                error={fieldState.invalid}
              />
            </OrderInput>
          </Box>
        </>
      }}
    />
  </Stack>
}

export const V1_FormGroup_ArriveHouseStructure = ({content}: { content: OrderContent }) => {
  const formContext = useFormContext<Partial<OrderContentValues>>();
  const {
    control,
    watch,
  } = formContext;
  const watchHouseStructure = watch('arriveHouseStructure');
  return <Stack gap={2}>
    <Stack direction={"row"} gap={2} alignItems={'flex-end'}>
      <Controller
        name="arriveHouseStructure"
        rules={{required: content.entries.arriveHouseStructure.formErrorMessageRequired}}
        control={control}
        render={({field}) => (
          <OrderInput title={content.entries.arriveHouseStructure.formLabel} required>
            <RadioGroup {...field} onChange={e => field.onChange(e.target.value)}>
              {
                content.entries.arriveHouseStructure.options.map(option => (
                  <FormControlLabel key={option.value} value={option.value}
                                    control={<Radio checked={field.value === option.value}/>}
                                    label={option.formLabel}/>
                ))
              }
            </RadioGroup>
          </OrderInput>
        )}/>
      <Controller
        name="arriveHouseFloorsNum"
        rules={{required: watchHouseStructure === 'more-floors' ? content.entries.arriveHouseFloorsNum.formErrorMessageRequired : undefined}}
        control={control}
        render={({field}) => (
          <Box sx={{display: watchHouseStructure === 'more-floors' ? 'block' : 'none'}}>
            <OrderInput title={content.entries.arriveHouseFloorsNum.formLabel} required>
              <Select
                {...field}
              >
                {
                  content.entries.arriveHouseFloorsNum.options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.formLabel}</MenuItem>
                  ))
                }
              </Select>
            </OrderInput>
          </Box>
        )}
      />
    </Stack>
  </Stack>
}
