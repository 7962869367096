import {
  ArriveAddress,
  ArriveCrane,
  ArriveCraneComments,
  ArriveFloor,
  ArriveHouseFloorsNum,
  ArriveHouseStructure,
  ArriveLift,
  ArriveParking,
  ArriveParkingComments,
  BagsCount,
  BoxesCount,
  SacksCount,
  DepartAddress,
  DepartBalconyCount,
  DepartCrane,
  DepartCraneComments,
  DepartExternalSpace,
  DepartFloor,
  DepartHouseFloorsNum,
  DepartHouseStructure,
  DepartLift,
  DepartParking,
  DepartParkingComments,
  DepartRooms,
  OrderAdditionalOffers,
  OrderComments,
  OrderContentName,
  OrderContentValues,
  OrderDateDay,
  OrderDateDayHours,
  StageIndex,
  SuitcasesCount,
  V_Test_Field1,
} from "./order-entries";
import {OrderContentEntry} from "./order-entries-types";
import {OrderItem, OrderItemData, OrderItemProps} from "./order-items-types";
import 'dayjs/locale/he';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import {sharedConfig} from "../shared/shared-config";

dayjs.extend(relativeTime)
dayjs.locale('he');

const labels = {
  items: 'פריטים',
  itemsCount: 'מספר פריטים',
  orderId: '#️⃣ מספר הזמנה:',
  missing: 'לא צוין',
  plusAssembly: '+ הרכבה',
  plusDisassembly: '+ פירוק',
  plusThrowAway: '+ פינוי לפח',
  craneNeeded: 'נדרש מנוף',
}

export class OrderContent {
  orderId: number;
  entries: Partial<Record<OrderContentName, OrderContentEntry<any>>>;
  items: OrderItem[];
  version: string;
  pdfFile: string | null;

  constructor(orderId: number, json: Record<string, any>, items: OrderItemData[]) {
    this.orderId = orderId;
    this.version = json._version || '1';
    this.pdfFile = json.pdfFile || null;
    this.items = items.map(item => new OrderItem(item));
    switch (this.version) {
      case '1': {
        this.entries = v1_orderJsonToEntries(json);
      }
        break;
      case 'test': {
        this.entries = v_test_orderJsonToEntries(json);
      }
        break;
    }
  }

  sanitize(): OrderContentValues {
    const sanitizedEntries = {
      _version: this.version,
      pdfFile: this.pdfFile,
    };
    for (const [name, entry] of Object.entries(this.entries)) {
      sanitizedEntries[name] = entry.sanitize();
    }
    return sanitizedEntries;
  }

  makeItemTextObject(item: OrderItem) {
    return {
      title: item.title,
      propsText: this.makeItemPropsText(item),
      assemblyText: this.makeItemAssemblyText(item),
      comments: item.comments,
    }
  }

  makeItemPropsText(item: OrderItem): string {
    return (item.itemProps || []).filter(p => !!p).map((p: OrderItemProps) => {
      switch (p.type) {
        case 'checkbox':
          return `\n- ${p.config.label}`;
        case 'radio':
          return `\n${p.config.label}: ${p.value}`
        case 'select':
          return p.value ? `\n${p.config.label}: ${p.value}` : '';
        case 'counter':
          return `\n${p.config.label}: ${p.value}`;
        default:
          return `\n${p.config.label}: ${p.value}`;
      }
    }).join('');
  }

  makeItemAssemblyText(item: OrderItem): string[] {
    return [
      item.assembly ? labels.plusAssembly : null,
      item.disassembly ? labels.plusDisassembly : null,
      item.throwAway ? labels.plusThrowAway : null,
    ].filter(t => !!t);
  }

  makeItemText(item: OrderItem): string {
    const quantity = `(${item.quantity})`;
    const assemblyDisassemblyThrowAway = this.makeItemAssemblyText(item).join(' ');
    const comments = item.comments ? `\n(${item.comments})` : '';
    const itemProps = this.makeItemPropsText(item);
    return `${item.title} ${quantity}${assemblyDisassemblyThrowAway.trim() !== '' ? `\n${assemblyDisassemblyThrowAway}` : ''}${itemProps}${comments}`;
  }

  makeItemsText() {
    return this.items
      .map(this.makeItemText)
      .join('\n\n');
  }

  toItemsMessageText(): string {
    const itemsText = this.makeItemsText();
    return `
*${labels.items}*:

${itemsText}`
  }

  toShortItemsMessageText(): string {
    return `*${labels.itemsCount}: ${this.items.length}*`;
  }

  toDetailsMessageText(): string {
    const messageText = `
*${labels.orderId}* ${this.orderId}
*${this.entries.dateDay.label}*: ${this.movingDateFormatted}

*${this.entries.departAddress.label}*: ${this.entries.departAddress.toSummaryDisplay()}
*${this.entries.departFloor.label}*: ${this.entries.departFloor.toSummaryDisplay()}
*${this.entries.departRooms.label}*: ${this.entries.departRooms.toSummaryDisplay()}
*${this.entries.departParking.label}*: ${this.entries.departParking.toSummaryDisplay()}
${['no', 'maybe'].includes(this.entries.departParking.value) ? `*${this.entries.departParkingComments.label}*: ${this.entries.departParkingComments.toSummaryDisplay()}` : ''}
*${this.entries.departLift.label}*: ${this.entries.departLift.toSummaryDisplay()}
*${this.entries.departCrane.label}*: ${this.entries.departCrane.toSummaryDisplay()}
${['yes', 'maybe'].includes(this.entries.departCrane.value) ? `*${this.entries.departCraneComments.label}*: ${this.entries.departCraneComments.toSummaryDisplay()}` : ''}
*${this.entries.departExternalSpace.label}*: ${this.entries.departExternalSpace.toSummaryDisplay()}${this.entries.departExternalSpace.value.includes('balcony') ? `\n*${this.entries.departBalconyCount.label}*: ${this.entries.departBalconyCount.toSummaryDisplay()}` : ''}
${['more-floors'].includes(this.entries.departHouseStructure.value) ? `*${this.entries.departHouseFloorsNum.label}*: ${this.entries.departHouseFloorsNum.toSummaryDisplay()}` : `*${this.entries.departHouseStructure.label}*: ${this.entries.departHouseStructure.toSummaryDisplay()}`
    }

*${this.entries.arriveAddress.label}*: ${this.strArriveAddress}
*${this.entries.arriveFloor.label}*: ${this.entries.arriveFloor.toSummaryDisplay()}
*${this.entries.arriveParking.label}*: ${this.entries.arriveParking.toSummaryDisplay()}
${['no', 'maybe'].includes(this.entries.arriveParking.value) ? `*${this.entries.arriveParkingComments.label}*: ${this.entries.arriveParkingComments.toSummaryDisplay()}` : ''}
*${this.entries.arriveLift.label}*: ${this.entries.arriveLift.toSummaryDisplay()}
*${this.entries.arriveCrane.label}*: ${this.entries.arriveCrane.toSummaryDisplay()}
${['yes', 'maybe'].includes(this.entries.arriveCrane.value) ? `*${this.entries.arriveCraneComments.label}*: ${this.entries.arriveCraneComments.toSummaryDisplay()}` : ''}
${['more-floors'].includes(this.entries.arriveHouseStructure.value) ? `*${this.entries.arriveHouseFloorsNum.label}*: ${this.entries.arriveHouseFloorsNum.toSummaryDisplay()}` : `*${this.entries.arriveHouseStructure.label}*: ${this.entries.arriveHouseStructure.toSummaryDisplay()}`}
`
    return messageText;
  }

  toSimpleMessageDetailsText(): string {
    const craneText = this.entries.departCrane.value !== 'no' || this.entries.arriveCrane.value !== 'no' ? `// *${labels.craneNeeded}*` : '';
    const messageText = `*${this.entries.departAddress.label}*: ${this.entries.departAddress.toSummaryDisplay()} // *${this.entries.arriveAddress.label}*: ${this.strArriveAddress} // *${this.entries.dateDay.label}*: ${this.movingDateFormatted} // *${labels.itemsCount}: ${this.items.length}* ${craneText}`;
    return messageText;
  }

  toMessageText(maxCharCount: number = sharedConfig.maxWaMessageLength): string {
    const fullMessageText = this.toFullMessageText();
    if (maxCharCount === -1 || fullMessageText.length <= maxCharCount) {
      return fullMessageText;
    } else {
      const shortMessageText = this.toShortMessageText();
      return shortMessageText;
    }
  }

  toShortMessageText(): string {
    const bodyText = `
${this.toDetailsMessageText()}
${this.toShortItemsMessageText()}
`
    return bodyText;
  }

  toFullMessageText(): string {
    const bodyText = `
${this.toDetailsMessageText()}
${this.toItemsMessageText()}
`
    return bodyText;
  }

  toAdminEmailHtml(): string {
    const toEntryRow = (entry1: OrderContentEntry<any>, entry2?: OrderContentEntry<any>) => {
      return `<tr>
<td><b>${entry1.label}</b></td>
<td>${entry1.toSummaryDisplay()}</td>
<td><b>${entry2?.label || ''}</b></td>
<td>${entry2?.toSummaryDisplay() || ''}</td>
</tr>`;
    }
    const toItemsRow = (item: OrderItem) => {
      return `
<tr>
    <td style="padding: 5px">${this.makeItemText(item).replaceAll('\n', '<br />')}</td>
</tr>
`;
    }
    const html = `
<div style="direction: rtl; line-height: 2em;">
<h2>הזמנה #${this.orderId}</h2>
<h3>לאתריך: ${this.entries.dateDay.toSummaryDisplay()} ${this.entries.dateDayHours.toSummaryDisplay()}</h3>
<table cellpadding="2" cellspacing="2" border="1" style="border-spacing: 1px; border-collapse: separate; border: 1px solid grey; border-color: grey;">
<tr>
<td style="width: 50%" colspan="2"><h4>פרטי מוצא</h4></td>
<td style="width: 50%" colspan="2"><h4>פרטי יעד</h4></td>
</tr>
${[
      [this.entries.departAddress, this.entries.arriveAddress],
      [this.entries.departFloor, this.entries.arriveFloor],
      [this.entries.departRooms, this.entries.arriveParking],
      [this.entries.departParking, this.entries.arriveParkingComments],
      [this.entries.departParkingComments, this.entries.arriveLift],
      [this.entries.departLift, this.entries.arriveCrane],
      [this.entries.departCrane, this.entries.arriveCraneComments],
      [this.entries.departCraneComments, this.entries.arriveHouseStructure],
      [this.entries.departExternalSpace, this.entries.arriveHouseFloorsNum],
      [this.entries.departBalconyCount],
      [this.entries.departHouseStructure],
      [this.entries.departHouseFloorsNum],
    ]
      .map(([entry1, entry2]) => toEntryRow(entry1, entry2))
      .join('\n')
    }
</table>
<h4>פריטים:</h4>
<table cellpadding="2" cellspacing="2" border="1" style="border-spacing: 1px; border-collapse: separate; border: 1px solid grey; border-color: grey;">
${this.items.map(toItemsRow).join('')}
</table>
<h4>הערות להזמנה:</h4>
<p>
${this.entries.orderComments.toSummaryDisplay() || '--'}
</p>
<h4>הצעות נוספות:</h4>
<p>
${this.entries.additionalOffers.toSummaryDisplay() || '--'}
</p>
</div>
`;
    return html;
  }

  formValues(): Record<string, any> {
    const values: Record<string, any> = {};
    for (const [name, entry] of Object.entries(this.entries)) {
      values[name] = entry.value || entry.defaultValue;
    }
    return values;
  }

  get sortedItems() {
    return this.items.sort((a, b) => a.title.localeCompare(b.title));
  }

  get strDepartAddress(): string {
    return this.entries.departAddress?.value?.description;
  }

  get strArriveAddress(): string {
    return this.entries.arriveAddress?.value?.description;
  }

  get hasDepartExternalSpace(): boolean {
    return this.entries.departExternalSpace?.value?.length > 0;
  }

  get hasItems(): boolean {
    return this.items?.length > 0;
  }

  get totalPackagesCount(): number {
    const boxesCount = this.entries.boxesCount?.value;
    const bagsCount = this.entries.bagsCount?.value;
    const suitcasesCount = this.entries.suitcasesCount?.value;
    const sacksCount = this.entries.sacksCount?.value;
    return boxesCount + bagsCount + suitcasesCount + sacksCount;
  }

  get movingDateRelativeLabel(): string | null {
    if (!this.entries.dateDay) {
      return null;
    }
    return dayjs().to(this.entries.dateDay.value);
  }

  get movingDateFormatted(): string | null {
    if (!this.entries.dateDay) {
      return null;
    }
    return dayjs(this.entries.dateDay.value).format('DD/MM/YYYY');
  }

  has(entry: keyof OrderContentValues): boolean {
    return !!this.entries[entry];
  }
}

const v1_orderJsonToEntries = (json: Record<string, any>): Partial<Record<OrderContentName, OrderContentEntry<any>>> => {
  return {
    stageIndex: new StageIndex(json['stageIndex'] || null),
    departAddress: new DepartAddress(json['departAddress'] || null),
    departFloor: new DepartFloor(json['departFloor'] || ''),
    departRooms: new DepartRooms(json['departRooms'] || ''),
    departParking: new DepartParking(json['departParking'] || ''),
    departParkingComments: new DepartParkingComments(json['departParkingComments'] || ''),
    departLift: new DepartLift(json['departLift'] || ''),
    departCrane: new DepartCrane(json['departCrane'] || ''),
    departCraneComments: new DepartCraneComments(json['departCraneComments'] || ''),
    departExternalSpace: new DepartExternalSpace(json['departExternalSpace'] || []),
    departBalconyCount: new DepartBalconyCount(json['departBalconyCount'] || ''),
    departHouseStructure: new DepartHouseStructure(json['departHouseStructure'] || ''),
    departHouseFloorsNum: new DepartHouseFloorsNum(json['departHouseFloorsNum'] || ''),
    arriveAddress: new ArriveAddress(json['arriveAddress'] || null),
    arriveFloor: new ArriveFloor(json['arriveFloor'] || ''),
    arriveParking: new ArriveParking(json['arriveParking'] || ''),
    arriveParkingComments: new ArriveParkingComments(json['arriveParkingComments'] || ''),
    arriveLift: new ArriveLift(json['arriveLift'] || ''),
    arriveCrane: new ArriveCrane(json['arriveCrane'] || ''),
    arriveCraneComments: new ArriveCraneComments(json['arriveCraneComments'] || ''),
    arriveHouseStructure: new ArriveHouseStructure(json['arriveHouseStructure'] || ''),
    arriveHouseFloorsNum: new ArriveHouseFloorsNum(json['arriveHouseFloorsNum'] || ''),
    dateDay: new OrderDateDay(json['dateDay'] || ''),
    dateDayHours: new OrderDateDayHours(json['dateDayHours'] || []),
    bagsCount: new BagsCount(json['bagsCount'] || 0),
    suitcasesCount: new SuitcasesCount(json['suitcasesCount'] || 0),
    sacksCount: new SacksCount(json['sacksCount'] || 0),
    boxesCount: new BoxesCount(json['boxesCount'] || 0),
    orderComments: new OrderComments(json['orderComments'] || ''),
    additionalOffers: new OrderAdditionalOffers(json['additionalOffers'] || []),
  };
}

const v_test_orderJsonToEntries = (json: Record<string, any>): Partial<Record<OrderContentName, OrderContentEntry<any>>> => {
  return {
    stageIndex: new StageIndex(json['stageIndex'] || null),
    v_test_field1: new V_Test_Field1(json['v_test_field1'] || null),
    departAddress: new DepartAddress(json['departAddress'] || null),
    departFloor: new DepartFloor(json['departFloor'] || ''),
    departRooms: new DepartRooms(json['departRooms'] || ''),
    departParking: new DepartParking(json['departParking'] || ''),
    departParkingComments: new DepartParkingComments(json['departParkingComments'] || ''),
    departLift: new DepartLift(json['departLift'] || ''),
    departCrane: new DepartCrane(json['departCrane'] || ''),
    departCraneComments: new DepartCraneComments(json['departCraneComments'] || ''),
    departExternalSpace: new DepartExternalSpace(json['departExternalSpace'] || []),
    departBalconyCount: new DepartBalconyCount(json['departBalconyCount'] || ''),
    departHouseStructure: new DepartHouseStructure(json['departHouseStructure'] || ''),
    departHouseFloorsNum: new DepartHouseFloorsNum(json['departHouseFloorsNum'] || ''),
    arriveAddress: new ArriveAddress(json['arriveAddress'] || null),
    arriveFloor: new ArriveFloor(json['arriveFloor'] || ''),
    arriveParking: new ArriveParking(json['arriveParking'] || ''),
    arriveParkingComments: new ArriveParkingComments(json['arriveParkingComments'] || ''),
    arriveLift: new ArriveLift(json['arriveLift'] || ''),
    arriveCrane: new ArriveCrane(json['arriveCrane'] || ''),
    arriveCraneComments: new ArriveCraneComments(json['arriveCraneComments'] || ''),
    arriveHouseStructure: new ArriveHouseStructure(json['arriveHouseStructure'] || ''),
    arriveHouseFloorsNum: new ArriveHouseFloorsNum(json['arriveHouseFloorsNum'] || ''),
    dateDay: new OrderDateDay(json['dateDay'] || ''),
    dateDayHours: new OrderDateDayHours(json['dateDayHours'] || []),
    bagsCount: new BagsCount(json['bagsCount'] || 0),
    suitcasesCount: new SuitcasesCount(json['suitcasesCount'] || 0),
    sacksCount: new SacksCount(json['sacksCount'] || 0),
    boxesCount: new BoxesCount(json['boxesCount'] || 0),
    orderComments: new OrderComments(json['orderComments'] || ''),
    additionalOffers: new OrderAdditionalOffers(json['additionalOffers'] || []),
  }
}