import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Stack from "@mui/material/Stack";
import {useNavigate} from "@tanstack/react-router";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Face6Icon from "@mui/icons-material/Face6";
import {ClientOfferForCustomer} from "../../model/client-offer.ts";
import {OrderBox} from "./orders-elements.tsx";
import {PartnerBasicRating} from "../partner-rating.tsx";

const labels = {
  'nis': '₪',
  yourOffers: 'הצעות המחיר שלך 😎',
}

export function OffersScreen({offers}: { offers: ClientOfferForCustomer[] }) {
  const navigate = useNavigate();
  const onClickOffer = (offerId: number) => {
    navigate({
      to: '/offer/$offerId',
      /* @ts-expect-error False alarm */
      params: {offerId: String(offerId)},
    });
  }
  return (
    <OrderBox title={labels['yourOffers']}>
      <Stack padding={2} gap={3}>
        {offers.map(offer => (
          <Stack
            onClick={() => onClickOffer(offer.id)}
            gap={1}
            direction={'row'}
            key={offer.id}
            sx={{
              cursor: 'pointer',
              padding: 1,
              borderRadius: 1,
              border: '1px solid #F3EBFF',
              alignItems: 'center',
              transition: 'box-shadow 0.3s ease',
              '&:hover': {
                // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
              }
            }}>
            {offer.partner.profilePhotos?.[0] ? (
              <Box sx={{
                width: 60,
                height: 60,
                borderRadius: 1,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <img src={offer.partner.profilePhotos[0]} alt={offer.partner.businessName} style={{maxWidth: '100%', maxHeight: '100%'}}/>
              </Box>
            ) : <Face6Icon sx={{fontSize: 40, opacity: 0.3}}/>}
            <Stack sx={{flexGrow: 1}}>
              <Typography>{offer.partner.businessName}</Typography>
              <Stack direction={'row'} sx={{alignItems: 'center'}} gap={0.5}>
                <PartnerBasicRating rating={offer.partner.rating}/>
              </Stack>
            </Stack>
            <Typography sx={{fontSize: 20, fontWeight: 600}}>{offer.amount}{labels['nis']}</Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <ChevronLeftIcon sx={{color: '#6F4DFF'}}/>
            </Box>
          </Stack>
        ))}
      </Stack>
    </OrderBox>
  );
}