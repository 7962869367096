import React, {useCallback, useEffect, useRef, useState} from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Button from '@mui/material/Button';
import { FieldPinInput } from '../mui-treasury/field-pin-input/FieldPinInput';
import {IconWhatsapp} from "./icons/IconWhatsapp.tsx";
import {useCheckOtpMutation, useResendOtpMutation, useSendOtpMutation} from "../services/api-login.ts";

const PIN_LENGTH = 6;
const RESEND_DELAY_SECONDS = 30;

const labels = {
  'invalid-phone': 'מספר הטלפון שהוזן אינו תקין',
  'invalid-otp': 'קוד אימות שגוי',
  'partner-not-found': 'משתמש לא נמצא',
  'partner-inactive': 'משתמש לא פעיל',
  'server-error': 'סליחה קרתה תקלה 😔 נא לנסות שוב. אם הבעיה נמשכת נא ליצור קשר עם שירות הלקוחות שלנו.',
  maxAttempts: 'ניסיתם הרבה פעמים, אולי מספר הטלפון לא נכון?',
  sendNewCode: 'נא לשלוח קוד חדש',
  yourPhone: () => <Typography>מה מספר הוואצאפ <IconWhatsapp
    sx={{verticalAlign: -4, fontSize: 18, color: '#25D366'}}/> שלך?</Typography>,
  'we-sent-code': 'שלחנו קוד אימות למספר:',
  'what-code': 'מה הקוד שקיבלתם?',
  'next': 'המשך',
  'partner-login': 'כניסה לספקים',
  'sms': 'SMS',
  'whatsapp': 'וואטסאפ',
  'choose-method': 'כיצד תרצו לקבל את הקוד?',
  sendAgain: 'שלח שוב קוד אימות',
  canResendIn: (seconds: number) => <Typography sx={{fontSize: 12}}>ניתן לשלוח שוב קוד אימות
    בעוד {seconds} שניות</Typography>,
}

const ErrorMessage = ({msg}: { msg: string }) => (
  <Box>
    <Typography sx={{
      fontSize: 12,
      color: 'red',
    }}>{msg}</Typography>
  </Box>
);

function PhoneBox({onSendOtp}: { onSendOtp: (phone: string, formattedPhone: string, verificationId: string) => void }) {
  const sendOtpMutation = useSendOtpMutation();
  const [phoneError, setPhoneError] = useState(null);
  const {
    control,
    getValues,
    formState,
    handleSubmit,
  } = useForm<{ phone: string, method: 'sms' | 'whatsapp' }>({
    defaultValues: {
      phone: '',
      method: 'whatsapp',
    },
  });
  const onSubmit = async () => {
    setPhoneError(null);
    const p = getValues().phone;
    const method = getValues().method;
    const result = await sendOtpMutation.mutateAsync({phone: p, method});
    if (result.success) {
      onSendOtp(result.phone, result.formattedPhone, result.verificationId);
    } else {
      setPhoneError(labels[result.error]);
    }
  }
  return (
    <Stack gap={2}>
      <Box sx={{textAlign: 'center'}}>{labels['yourPhone']()}</Box>
      <Controller
        rules={{
          required: labels['invalid-phone'],
          minLength: 5,
        }}
        name="phone"
        control={control}
        render={({field}) => (
          <>
            <TextField
              name="phone"
              autoFocus={true}
              sx={{
                direction: 'rtl',
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(onSubmit)();
                }
              }}
              InputProps={{
                type: 'tel',
                sx: {
                  backgroundColor: 'white',
                },
                startAdornment:
                  (<InputAdornment position="start">
                    <PhoneIphoneIcon/>
                  </InputAdornment>)
              }}
              {...field}
            />
          </>
        )}
      />
      {phoneError ? <ErrorMessage msg={phoneError}/> : null}
      {/*<Controller*/}
      {/*  name="method"*/}
      {/*  control={control}*/}
      {/*  render={({field}) => (*/}
      {/*    <FormControl>*/}
      {/*      <Stack gap={1}>*/}
      {/*      <Typography>{labels['choose-method']}</Typography>*/}
      {/*      <RadioGroup row={true} defaultValue={'whatsapp'}>*/}
      {/*        <Stack direction={'row'} gap={2}>*/}
      {/*          <Box sx={{*/}
      {/*            borderRadius: 1,*/}
      {/*            border: field.value === 'whatsapp' ? '1px solid #ccc' : '1px solid #fff',*/}
      {/*            px: 2,*/}
      {/*            py: 1,*/}
      {/*          }}>*/}
      {/*            <FormControlLabel sx={{margin: 0}} value="whatsapp" control={<Radio onChange={field.onChange}/>} label={*/}
      {/*              <Stack direction={'row'} gap={1}>*/}
      {/*                <Typography>{labels['whatsapp']}</Typography>*/}
      {/*                <IconWhatsapp sx={{ fontSize: 22, color: '#25D366' }}/>*/}
      {/*              </Stack>}/>*/}
      {/*          </Box>*/}
      {/*          <Box sx={{*/}
      {/*            borderRadius: 1,*/}
      {/*            border: field.value === 'sms' ? '1px solid #ccc' : '1px solid #fff',*/}
      {/*            px: 2,*/}
      {/*            py: 1,*/}
      {/*          }}>*/}
      {/*            <FormControlLabel sx={{margin: 0}} value="sms" control={<Radio onChange={field.onChange}/>} label={<Stack direction={'row'} gap={1}>*/}
      {/*              <Typography>{labels['sms']}</Typography>*/}
      {/*            </Stack>}/>*/}
      {/*          </Box>*/}
      {/*        </Stack>*/}
      {/*      </RadioGroup>*/}
      {/*      </Stack>*/}
      {/*    </FormControl>*/}
      {/*  )}*/}
      {/*/>*/}
      <LoadingButton onClick={handleSubmit(onSubmit)} loading={formState.isSubmitting} variant={'contained'}
                     disabled={!formState.isValid}>המשך</LoadingButton>
    </Stack>
  );
}

function OtpBox({
                  phone,
                  formattedPhone,
                  verificationId,
                  onOtpBack,
                  onSuccess,
                  isPartner,
                }: {
  phone: string,
  formattedPhone: string,
  verificationId: string,
  onOtpBack: () => void,
  onSuccess: (accessToken: string, adminToken?: string) => void,
  isPartner?: boolean,
}) {
  const checkOtpMutation = useCheckOtpMutation(isPartner);
  const resendOtpMutation = useResendOtpMutation();
  const [canResendInSeconds, setCanResendInSeconds] = useState(RESEND_DELAY_SECONDS);
  const resendTimerCallback = useCallback(() => setCanResendInSeconds(currTimer => currTimer - 1), []);
  const [otpError, setOtpError] = useState(null);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const firstOtpRef = useRef<HTMLInputElement>(null);
  const {
    control,
    getValues,
    setValue,
    formState,
    handleSubmit,
  } = useForm<{ phone: string, otp: string, method: 'sms' | 'whatsapp' }>({
    defaultValues: {
      otp: '',
      method: 'whatsapp',
    },
  });
  useEffect(() => {
    canResendInSeconds > 0 && setTimeout(resendTimerCallback, 1000);
  }, [canResendInSeconds, resendTimerCallback]);

  const onSubmit: SubmitHandler<{ phone: string, otp: string }> = async ({otp}) => {
    setOtpError(null);
    const result: any = await checkOtpMutation.mutateAsync({
      phone,
      verificationId,
      otp,
    });
    if (result.success) {
      onSuccess(result.accessToken, result.adminToken);
    } else {
      if (result.error === 'max-attempts') {
        setOtpError(null);
        setMaxAttemptsReached(true);
      } else {
        setValue('otp', '');
        setOtpError(labels[result.error! || 'server-error']);
        firstOtpRef.current?.focus();
      }
    }
  }
  const onResendOtp = async () => {
    const result = await resendOtpMutation.mutateAsync({verificationId, method: 'whatsapp'});
    if (!result.success) {
      setOtpError(labels[result.error!]);
    } else {
      setCanResendInSeconds(RESEND_DELAY_SECONDS);
    }
  }
  return (
    <Stack gap={2}>
      <Stack>
        <Typography textAlign={"center"}>{labels['we-sent-code']}</Typography>
        <Typography textAlign={"center"}
                    sx={{direction: "ltr"}}>{formattedPhone}</Typography>
      </Stack>
      <Typography textAlign={"center"}>{labels['what-code']}</Typography>
      <Controller
        rules={{validate: (value) => value.length === PIN_LENGTH}}
        name="otp"
        control={control}
        render={({field}) => (
          <Stack>
            <Box sx={{direction: 'rtl', alignSelf: 'center'}}>
              <FieldPinInput {...field} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(onSubmit)();
                }
              }}>
                <TextField inputRef={firstOtpRef} data-cy="input-otp1"
                           InputProps={{type: 'number', inputMode: 'numeric'}} autoFocus={true}/>
                <TextField data-cy="input-otp2" inputProps={{type: 'number', inputMode: 'numeric'}}/>
                <TextField data-cy="input-otp3" inputProps={{type: 'number', inputMode: 'numeric'}}/>
                <TextField data-cy="input-otp4" inputProps={{type: 'number', inputMode: 'numeric'}}/>
                <TextField data-cy="input-otp5" inputProps={{type: 'number', inputMode: 'numeric'}}/>
                <TextField data-cy="input-otp6" inputProps={{type: 'number', inputMode: 'numeric'}}/>
              </FieldPinInput>
            </Box>
          </Stack>
        )}
      />
      {!maxAttemptsReached ?
        canResendInSeconds <= 1 ? <Button disabled={resendOtpMutation.isPending} variant={'text'} onClick={onResendOtp}>{labels.sendAgain}</Button> :
          labels.canResendIn(canResendInSeconds) : null}
      {otpError ? <ErrorMessage msg={otpError}/> : null}
      <Stack direction={'row'} gap={2}>
        {!maxAttemptsReached ?
          <>
            <Button variant="outlined" onClick={onOtpBack}>
              <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                <ArrowForwardIcon sx={{fontSize: 20}}/> {labels['back']}
              </Stack>
            </Button>
            <LoadingButton data-cy="button-otp-next" sx={{flexGrow: 1}} onClick={handleSubmit(onSubmit)}
                           loading={formState.isSubmitting}
                           variant={'contained'}
                           disabled={!formState.isValid}>{labels['next']}</LoadingButton>
          </>
          :
          <>
            <Stack gap={2}>
              <Typography>{labels.maxAttempts}</Typography>
              <Button onClick={onOtpBack}>{labels.sendNewCode}</Button>
            </Stack>
          </>
        }
      </Stack>
    </Stack>
  );
}

export function LoginBox({onSuccess, isPartner}: {
  isPartner?: boolean,
  onSuccess: (accessToken: string, adminToken?: string) => void
}) {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [formattedPhone, setFormattedPhone] = useState<string | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [currentScreen, setCurrentScreen] = useState<'phone' | 'otp'>('phone');

  const onSendOtp = (phone: string, formattedPhone: string, verificationId: string) => {
    setPhoneNumber(phone);
    setFormattedPhone(formattedPhone);
    setVerificationId(verificationId);
    setCurrentScreen('otp');
  }

  const onOtpBack = () => {
    setPhoneNumber(null);
    setFormattedPhone(null);
    setVerificationId(null);
    setCurrentScreen('phone');
  }

  if (currentScreen === 'phone') {
    return <PhoneBox onSendOtp={onSendOtp}/>;
  } else if (currentScreen === 'otp') {
    return <OtpBox
      isPartner={isPartner}
      phone={phoneNumber!}
      formattedPhone={formattedPhone!}
      verificationId={verificationId!}
      onOtpBack={onOtpBack}
      onSuccess={onSuccess}
    />;
  }
}