import dayjs from 'dayjs';
import {
  ICPartner,
  ICPartnerPublicProfile, OrderReview,
  PartnerOnboardingStatus,
  PartnerSocials
} from "@tarimli-mono/api/src/shared/client-types";
import {AggregatedRating} from "@tarimli-mono/api/src/shared/rating.ts";
import {ClientPartnerOwnOffer} from "./client-offer.ts";
import {ClientPartnerContract} from "./client-contract.ts";

export class ClientPartnerPublicProfile {
  id: DbId;
  businessName: string;
  phone: string;
  profilePhotos: string[];
  socials: PartnerSocials;
  rating: AggregatedRating;
  
  constructor(partner: ICPartnerPublicProfile) {
    this.id = partner.id;
    this.businessName = partner.businessName;
    this.phone = partner.phone;
    this.profilePhotos = partner.profilePhotos;
    this.socials = partner.socials;
    this.rating = partner.reviews ? new AggregatedRating(partner.reviews) : null;
  }
  
  get hasSocials() {
    return !!this.socials?.facebook?.url || !!this.socials?.instagram?.url || !!this.socials?.twitter?.url || !!this.socials?.tiktok?.url || !!this.socials?.website?.url;
  }
}

export class ClientPartner {
  id: DbId;
  phone: string;
  businessName: string;
  profilePhotos: string[];
  socials: PartnerSocials;
  rating: AggregatedRating;
  plusVat: boolean;
  offers: ClientPartnerOwnOffer[];
  contracts: ClientPartnerContract[];
  onboardingStatus: PartnerOnboardingStatus;
  publicProfile: ClientPartnerPublicProfile;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(partner: ICPartner) {
    this.id = partner.id;
    this.phone = partner.phone;
    this.businessName = partner.businessName;
    this.profilePhotos = partner.profilePhotos;
    this.plusVat = partner.plusVat;
    this.socials = partner.socials;
    this.rating = partner.reviews ? new AggregatedRating(partner.reviews) : null;
    this.offers = partner.offers.map(offer => new ClientPartnerOwnOffer(offer));
    this.contracts = partner.contracts.map(contract => new ClientPartnerContract(contract));
    this.onboardingStatus = partner.onboardingStatus;
    this.publicProfile = new ClientPartnerPublicProfile({
      id: partner.id,
      businessName: partner.businessName,
      phone: partner.phone,
      profilePhotos: partner.profilePhotos,
      socials: partner.socials,
      reviews: partner.reviews,
    });
    this.createdAt = dayjs(partner.createdAt);
    this.updatedAt = dayjs(partner.updatedAt);
  }

  get canSubmitOffers() {
    // return this.onboardingStatus?.registrationCompleted && this.onboardingStatus?.termsConfirmed;
    return this.onboardingStatus?.registrationCompleted;
  }
  
  get onboardingRegistrationCompleted() {
    return !!this.onboardingStatus?.registrationCompleted;
  }
  
  // get onboardingTermsConfirmed() {
  //   return !!this.onboardingStatus?.termsConfirmed;
  // }
  
  findOrderForContractId(contractId: number) {
    const contract = this.contracts.find(contract => contract.id === contractId);
    if (!contract) {
      return null;
    }
    const order = this.findOrder(contract.orderId);
    return order || null;
  }
  
  findOfferForContractId(contractId: number) {
    const contract = this.contracts.find(contract => contract.id === contractId);
    if (!contract) {
      return null;
    }
    const offer = this.offers.find(offer => offer.id === contract.offerId);
    return offer || null;
  }
  
  findOrder(orderId: number) {
    const offer = this.offers.find(of => of.order.id === orderId);
    if (!offer) {
      return null;
    }
    return offer.order;
  }
  
  findOfferForOrderId(orderId: number) {
    return this.offers.find(offer => offer.order.id === orderId) || null;
  }

  // get avgRating() {
  //   const ratings = this.socials.map(social => social.rating).filter(r => typeof r === 'number' && r > 0);
  //   const ratingsSum = ratings.reduce((acc, rating) => acc + rating, 0);
  //   return Math.round(ratingsSum / ratings.length * 10) / 10;
  // }
  
  get cancelledContracts() {
    return this.contracts.filter(contract => contract.status === 'cancelled');
  }
  
  get hasCancelledContracts() {
    return this.cancelledContracts.length > 0;
  }
  
  get completedContracts() {
    return this.contracts.filter(contract => contract.status === 'completed');
  }
  
  get hasCompletedContracts() {
    return this.completedContracts.length > 0;
  }
  
  get activeContracts() {
    return this.contracts.filter(contract => contract.status === 'active');
  }
  
  get hasActiveContracts() {
    return this.activeContracts.length > 0;
  }
  
  get hasInviteOffers() {
    return this.inviteOffers.length > 0;
  }

  get submittedOffers() {
    return this.offers.filter(offer => offer.status === 'submitted' && !offer.isExpired);
  }

  get hasSubmittedOffers() {
    return this.submittedOffers.length > 0;
  }
  
  get inviteOffers() {
    return this.offers.filter(offer => offer.status === 'invite' && !offer.isExpired);
  }
  
  get declinedOffers() {
    return this.offers.filter(offer => offer.status === 'declined' && !offer.isExpired);
  }
  
  get expiredOffers() {
    return this.offers.filter(offer => offer.isExpired);
  }

  get hasDeclinedOffers() {
    return this.declinedOffers.length > 0;
  }
  
  get hasExpiredOffers() {
    return this.expiredOffers.length > 0;
  }
  
  orderIsDeclined(orderId: number) {
    return this.offers.find(offer => offer.order.id === orderId)?.status === 'declined';
  }

}
