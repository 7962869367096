import dayjs from 'dayjs';
import {ICOfferForCustomer, ICOfferStatus, ICPartnerOwnOffer} from "@tarimli-mono/api/src/shared/client-types";
import {ClientPartnerPublicProfile} from "./client-partner.ts";
import {ClientOrderForPartner} from "./client-order.ts";

export class ClientOfferForCustomer {
  id: DbId;
  orderId: DbId;
  partner: ClientPartnerPublicProfile;
  amount: number;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(offer: ICOfferForCustomer) {
    this.id = offer.id;
    this.orderId = offer.orderId;
    this.partner = new ClientPartnerPublicProfile(offer.partner);
    this.amount = offer.amount;
    this.createdAt = dayjs(offer.createdAt);
    this.updatedAt = dayjs(offer.updatedAt);
  }
}

export class ClientPartnerOwnOffer {
  id: DbId;
  amount: number;
  order: ClientOrderForPartner;
  orderId: DbId;
  status: ICOfferStatus;
  isExpired: boolean;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
  
  constructor(offer: ICPartnerOwnOffer) {
    this.id = offer.id;
    this.amount = offer.amount;
    this.order = new ClientOrderForPartner(offer.order);
    this.orderId = offer.order.id;
    this.status = offer.status;
    this.isExpired = offer.isExpired;
    this.createdAt = dayjs(offer.createdAt);
    this.updatedAt = dayjs(offer.updatedAt);
  }
}