import {createFileRoute, notFound, redirect, useRouter} from "@tanstack/react-router";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import {FormProvider, useForm} from "react-hook-form";
import {OrderContentValues} from "@tarimli-mono/api/src/shared/order-entries.ts";
import {EditOrderHeader, EditStageHeader} from "../../../../../components/stage-elements.tsx";
import {useCustomer} from "../../../../../services/auth.tsx";
import {useMutationSubmitOrder, useMutationUpdateOrder} from "../../../../../services/api-order.ts";
import {config} from "../../../../../config.ts";
import {OrderForm} from "../../../../../components/forms/order-form.tsx";
import _ from "lodash";

export const Route = createFileRoute('/_auth/order/$orderId/_order/_draft/summary')({
  component: OrderSummaryScreen,
  beforeLoad: ({context, params}) => {
    const order = context.auth.customer.findOrder(Number(params.orderId));
    if (!order) {
      throw notFound();
    }
    if (order?.status !== 'draft') {
      // @ts-expect-error
      throw redirect({
        to: '/orders',
      });
    }
  },
});

const labels = {
  'stage-title': 'ההובלה שלך',
  'next': 'הנה סיימנו 😎',
  submitError: 'סליחה קרתה תקלה 😔 נא לנסות שוב. אם הבעיה נמשכת נא ליצור קשר עם שירות הלקוחות שלנו.',
}

export function OrderSummaryScreen() {
  const customer = useCustomer();
  const routeParams = Route.useParams() as { orderId: string };
  const order = customer.orders.find(o => o.id === Number(routeParams.orderId));
  const updateOrderMutation = useMutationUpdateOrder(50, order.content);
  const submitOrderMutation = useMutationSubmitOrder();
  const router = useRouter();
  const formMethods = useForm<OrderContentValues>({
    mode: 'all',
    values: order.content.formValues(),
  });
  const {
    handleSubmit,
    formState,
  } = formMethods;
  const {errors, isValid, isSubmitting} = formState;

  const onPressNext = async (content: { additionalOffers: string[] }) => {
    const dirtyFieldNames = Object.entries(formState.dirtyFields)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => key);
    if (dirtyFieldNames.length > 0) {
      const propsToSubmit = _.pick(content, dirtyFieldNames);
      await updateOrderMutation.mutateAsync({orderId: order.id, updatedContent: propsToSubmit});
    }
    await submitOrderMutation.mutateAsync({orderId: order.id});
    setTimeout(async () => {
      await router.navigate({
        to: `/orders`,
        params: {
          // @ts-expect-error
          orderId: order.id,
        },
      });
      window.scrollTo({top: 0, behavior: 'smooth'})
    }, 200);
  }
  return (
    <FormProvider {...formMethods}>
      <form autoComplete={'off'}>
        <Stack gap={2}>
          <EditOrderHeader hideSummary={true} order={order} stage={6} totalStages={config().totalOrderStages}/>
          <Stack gap={0} sx={{
            borderRadius: 1,
            overflow: "hidden",
            backgroundColor: "white"
          }}>
            <EditStageHeader stage={5} totalStages={5} title={labels['stage-title']}/>
            <Stack gap={2} sx={{padding: 2}}>
              <OrderForm content={order.content} stage={'review'}/>
              <Stack gap={2}>
                <LoadingButton
                  loading={submitOrderMutation.isPending}
                  onClick={handleSubmit(onPressNext)}
                  sx={{
                    flexGrow: 1,
                  }}
                  variant={'contained'}
                >
                  {labels['next']}
                </LoadingButton>
                {submitOrderMutation.isError ? (
                  <Typography color={'error'}>{labels.submitError}</Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
