import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {PhotoGallery} from "../PhotoGallery";
import Button from '@mui/material/Button';
import {useRouter} from '@tanstack/react-router';
import {
  OrderItem,
  OrderItemPropsCheckboxConfig,
  OrderItemPropsCounterConfig,
  OrderItemPropsRadioConfig,
  OrderItemPropsSelectConfig
} from "@tarimli-mono/api/src/shared/order-items-types";
import {OrderContent} from "@tarimli-mono/api/src/shared/order-content";

const labels = {
  change: 'עריכה',
  'items': 'פריטים',
  'from-address': 'הבית שעוזבים',
  'to-address': 'הבית החדש',
  'moving-date': 'מתי עוברים',
  'rooms': 'חדרים',
  'floor': 'קומה',
  entrance: 'כניסה',
  'parking': 'חנייה למשאית',
  'lift': 'מעלית',
  'crane': 'מנוף',
  'there-is': 'יש',
  'there-isnt': 'אין',
  'apartment-has': `בדירה יש`,
  'need-to': 'יש צורך',
  'no-need-to': 'אין צורך',
  'floors': 'קומות',
  'one-floor': 'קומה אחת',
  'two-floors': 'שתי קומות',
  'more-floors': 'יותר מקומה אחת',
  gallery: 'גלריה',
  'missing': 'לא צוין',
  'plus-assembly': '+ הרכבה',
  'plus-disassembly': '+ פירוק',
  plusThrowAway: '+ פינוי לפח',
  packages: 'תכולה ארוזה והערות',
  bags: 'שקיות',
  suitcases: 'מזוודות',
  cases: 'תיקים',
  boxes: 'ארגזים',
  orderComments: 'הערות להובלה:',
}

export function V1_OrderSummary({orderContent, forPartner, toConfirm, forAdmin}: { orderContent: OrderContent, forPartner?: boolean, toConfirm?: boolean, forAdmin?: boolean }) {
  const router = useRouter();
  const allowEdit = !forPartner && !toConfirm && !forAdmin;
  const onClickChangeFromAddress = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const onClickChangeToAddress = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/arrive`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const onClickChangeDate = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/date`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickChangeItems = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/items`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  
  const onClickChangePackages = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/boxes`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const entry = (title: string, value: string | null) => (
    <Stack gap={1} direction={'row'} sx={{alignItems: 'center'}}>
      <Typography sx={{fontSize: 14, opacity: 0.5}}>{title}</Typography>
      {value ? <Typography>{value}</Typography> :
        <Typography sx={{fontSize: 14, fontStyle: 'italic', opacity: 0.5}}>{labels['missing']}</Typography>}
    </Stack>
  )

  const itemRow = (item: OrderItem) => (
    <Stack gap={1} direction={'row'} sx={{alignItems: 'center'}}>
      <Box
        sx={{
          alignSelf: "flex-start",
          // backgroundColor: "#F5EDFF",
          border: "1px solid #F5EDFF",
          padding: 0.5,
          borderRadius: 0.5,
          aspectRatio: "1/1",
          height: "2em",
          textAlign: "center"
        }}>
        <Typography fontSize={14}>{item.quantity}</Typography>
      </Box>
      <Stack gap={0.5}>
        <Typography fontSize={14} fontWeight={400}>{item.title}</Typography>
        {(item.disassembly || item.assembly || item.throwAway) ?
          <Stack direction={"row"} gap={1}>
            {item.disassembly ? <Typography fontSize={12}>{labels['plus-disassembly']}</Typography> : null}
            {item.assembly ? <Typography fontSize={12}>{labels['plus-assembly']}</Typography> : null}
            {item.throwAway ? <Typography fontSize={12}>{labels.plusThrowAway}</Typography> : null}
          </Stack> : null
        }
        {
          item.itemProps.filter(p => !!p).map(({type, config, value}, itemPropIdx) => {
            switch (type) {
              case 'counter':
                const {label: counterLabel} = config as OrderItemPropsCounterConfig;
                return (
                  <Stack key={counterLabel} direction={'row'} gap={1}>
                    <Typography fontSize={12}>{counterLabel}:</Typography>
                    <Typography fontSize={12}>{value}</Typography>
                  </Stack>
                );
                break;
              case 'checkbox':
                if (value) {
                  const {label: checkboxLabel} = config as OrderItemPropsCheckboxConfig;
                  return (
                    <Stack key={checkboxLabel} direction={'row'} gap={1} sx={{alignItems: 'center'}}>
                      <CheckBoxIcon sx={{fontSize: 16, opacity: 0.5}}/>
                      <Typography fontSize={12}>{checkboxLabel}</Typography>
                    </Stack>
                  );
                } else {
                  return null;
                }
                break;
              case 'radio':
                const {label: radioLabel} = config as OrderItemPropsRadioConfig;
                return (
                  <Stack key={radioLabel} direction={'row'} gap={1}>
                    <Typography fontSize={12}>{radioLabel}:</Typography>
                    <Typography fontSize={12}>{value}</Typography>
                  </Stack>
                );
                break;
              case 'select':
                const {label: selectLabel} = config as OrderItemPropsSelectConfig;
                return (
                  <Stack key={selectLabel} direction={'row'} gap={1}>
                    <Typography fontSize={12}>{selectLabel}:</Typography>
                    <Typography fontSize={12}>{value}</Typography>
                  </Stack>
                );
                break;
            }
          })
        }
        {item.comments ? <Typography fontSize={12}>{item.comments}</Typography> : null}
        {
          item?.media?.length > 0 ?
            <PhotoGallery images={(item.media || []).map(([id, url]) => ({
              src: url,
              width: 100,
              height: 100,
              title: item.title,
              description: item.comments
            }))}/> : null
        }
      </Stack>
    </Stack>
  );

  const boxesItemRow = (label: string, count: string) => (
    <Stack gap={1} direction={'row'} sx={{alignItems: 'center'}}>
      <Box
        sx={{
          alignSelf: "flex-start",
          // backgroundColor: "#F5EDFF",
          border: "1px solid #F5EDFF",
          padding: 0.5,
          borderRadius: 0.5,
          aspectRatio: "1/1",
          height: "2em",
          textAlign: "center"
        }}>
        <Typography fontSize={14}>{count}</Typography>
      </Box>
      <Stack gap={0.5}>
        <Typography fontSize={14} fontWeight={400}>{label}</Typography>
      </Stack>
    </Stack>
  );
  
  return (
    <Stack gap={2} sx={{
      padding: 2,
      borderRadius: 1,
      overflow: "hidden",
      backgroundColor: "white"
    }}>

      <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
        <Stack sx={{flexGrow: 1}}>
          <Typography sx={{fontSize: 16}}>{orderContent.entries.dateDay.formLabel}</Typography>
          <Typography sx={{fontSize: 18}}>{orderContent.movingDateFormatted}</Typography>
        </Stack>
        {allowEdit ? <Button onClick={onClickChangeDate}>{labels['change']}</Button> : null}
      </Stack>
      
      <Divider sx={{
        margin: 0,
      }}/>
      
      <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
        <Stack sx={{flexGrow: 1}}>
          <Typography sx={{fontSize: 16}}>{labels['from-address']}</Typography>
          <Typography sx={{fontSize: 18}}>{orderContent.strDepartAddress}</Typography>
        </Stack>
        {allowEdit ? <Button onClick={onClickChangeFromAddress}>{labels['change']}</Button> : null}
      </Stack>

      <Stack gap={0.5}>
        {entry(orderContent.entries.departFloor.label, orderContent.entries.departFloor.toSummaryDisplay())}
        {entry(orderContent.entries.departRooms.label, orderContent.entries.departRooms.toSummaryDisplay())}
        {entry(orderContent.entries.departParking.label, orderContent.has('departParking') ? orderContent.entries.departParking.toSummaryDisplay() : null)}
        {['no', 'maybe'].includes(orderContent.entries.departParking.value) && entry(orderContent.entries.departParkingComments.label,  orderContent.entries.departParkingComments.toSummaryDisplay())}
        {entry(orderContent.entries.departLift.label, orderContent.has('departLift') ? orderContent.entries.departLift.toSummaryDisplay() : null)}
        {entry(orderContent.entries.departCrane.label, orderContent.has('departCrane') ? orderContent.entries.departCrane.toSummaryDisplay() : null)}
        {['yes', 'maybe'].includes(orderContent.entries.departCrane.value) && entry(orderContent.entries.departCraneComments.label, orderContent.entries.departCraneComments.toSummaryDisplay())}
        {orderContent.hasDepartExternalSpace ? entry(orderContent.entries.departExternalSpace.label, orderContent.entries.departExternalSpace.toSummaryDisplay()) : null}
        {entry(orderContent.entries.departHouseFloorsNum.label, orderContent.entries.departHouseFloorsNum.toSummaryDisplay())}
      </Stack>

      <Divider sx={{
        margin: 0,
      }}/>

      <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
        <Stack sx={{flexGrow: 1}}>
          <Typography sx={{fontSize: 16}}>{labels['to-address']}</Typography>
          <Typography sx={{fontSize: 22}}>{orderContent.strArriveAddress}</Typography>
        </Stack>
        {allowEdit ? <Button onClick={onClickChangeToAddress}>{labels['change']}</Button> : null}
      </Stack>

      <Stack gap={0.5}>
        {entry(orderContent.entries.arriveFloor.label, orderContent.entries.arriveFloor.toSummaryDisplay())}
        {entry(orderContent.entries.arriveParking.label, orderContent.has('arriveParking') ? orderContent.entries.arriveParking.toSummaryDisplay() : null)}
        {['no', 'maybe'].includes(orderContent.entries.arriveParking.value) && entry(orderContent.entries.arriveParkingComments.label,  orderContent.entries.arriveParkingComments.toSummaryDisplay())}
        {entry(orderContent.entries.arriveLift.label, orderContent.has('arriveLift') ? orderContent.entries.arriveLift.toSummaryDisplay() : null)}
        {entry(orderContent.entries.arriveCrane.label, orderContent.has('arriveCrane') ? orderContent.entries.arriveCrane.toSummaryDisplay() : null)}
        {['yes', 'maybe'].includes(orderContent.entries.arriveCrane.value) && entry(orderContent.entries.arriveCraneComments.label, orderContent.entries.arriveCraneComments.toSummaryDisplay())}
        {entry(orderContent.entries.arriveHouseFloorsNum.label, orderContent.entries.arriveHouseFloorsNum.toSummaryDisplay())}
      </Stack>

      <Divider sx={{
        margin: 0,
      }}/>
      
      <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
        <Stack sx={{flexGrow: 1}}>
          <Typography sx={{fontSize: 16}}>{labels['items']}</Typography>
        </Stack>
        {allowEdit ? <Button onClick={onClickChangeItems}>{labels['change']}</Button> : null}
      </Stack>

      {orderContent.sortedItems.map(item => <Box key={item.id}>{itemRow(item)}</Box>)}

      <Divider />

      <Stack gap={2}>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Stack sx={{flexGrow: 1}}>
            <Typography sx={{fontSize: 16}}>{labels['packages']}</Typography>
          </Stack>
          {allowEdit ? <Button onClick={onClickChangePackages}>{labels['change']}</Button> : null}
        </Stack>
        {entry(orderContent.entries.orderComments.label, orderContent.has('orderComments') ? orderContent.entries.orderComments.toSummaryDisplay() : null)}
        {boxesItemRow(orderContent.entries.bagsCount.label, orderContent.entries.bagsCount.toSummaryDisplay())}
        {boxesItemRow(orderContent.entries.suitcasesCount.label, orderContent.entries.suitcasesCount.toSummaryDisplay())}
        {boxesItemRow(orderContent.entries.sacksCount.label, orderContent.entries.sacksCount.toSummaryDisplay())}
        {boxesItemRow(orderContent.entries.boxesCount.label, orderContent.entries.boxesCount.toSummaryDisplay())}
      </Stack>
      
    </Stack>
  )

}
